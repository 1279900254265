import * as FontFaces from '../../fonts/bunionplasty/FontFaces';
import * as colors from './colors';
import * as styles from './styles';
import * as buttons from './variants/buttons';
import * as forms from './variants/forms';
import * as layout from './variants/layouts';
import * as links from './variants/links';
import * as modals from './variants/modals';
import * as text from './variants/text';

export default {
  useLocalStorage: false,
  useColorSchemeMediaQuery: false,

  colors: {
    background: colors.WHITE,
    text: colors.BLACK_100,
    buttonBg: colors.PINK,
    buttonHoverBG: colors.RED,
    buttonColor: colors.WHITE,

    primary: colors.PINK,
    secondary: colors.RED,
    accent: colors.WINE,
    accentSecondary: colors.BLUE,
    muted: colors.GREY_200,
    error: colors.RED,
    link: colors.BLACK_100,
    nav: colors.BLACK_100,
    navSticky: colors.BLACK_100,
    logo: colors.PINK,
    stickyLogo: colors.PINK,
    social: colors.PINK,

    white: colors.WHITE,

    beige: colors.BEIGE,
    tan: colors.TAN,
    grey: colors.GREY_300,

    grey100: colors.GREY_100,
    grey200: colors.GREY_200,
    grey300: colors.GREY_300,
    grey400: colors.GREY_400,
    grey500: colors.GREY_500,

    black: colors.BLACK_100,
    black100: colors.BLACK_100,

    pink: colors.PINK,
    red: colors.RED,
    wine: colors.WINE,
    blue: colors.BLUE,

    homeHeroMobileGradient: colors.HOME_HERO_MOBILE_GRADIENT,

    modalOverlay: colors.MODAL_OVERLAY,

    fadeGradient: colors.FADE_GRADIENT,

    // used for table border
    pinkOpacity: colors.PINK_OPACITY,

    wineGradient: colors.WINE_GRADIENT,
    doctorSearchHeroGradient: colors.DOCTOR_SEARCH_HERO_GRADIENT,
    bunionplasty: colors.PINK,
    certifiedBg: colors.GREY_200,
  },

  fonts: {
    heading: String(FontFaces.ttMedium),
    body: String(FontFaces.ttRegular),
    button: String(FontFaces.ttBold),
    link: String(FontFaces.ttBold),
    cursive: String(FontFaces.signPainter),
  },
  fontSizes: {
    copyright: 12,
    xs: 14,
    sm: 16,
    md: 18,
    lg: 21,
    xl: 24,
    '2xl': 30,
    '3xl': 32,
    '4xl': 36,
    '5xl': 48,
    '6xl': 60,
    '7xl': 72,
  },

  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    semibold: 600,
    bold: 700,

    body: 400,
    heading: 500,
  },

  lineHeights: {
    body: 1.4,
    bodyNarrow: 1.44,
    heading: 1.125,
    headingWide: 1.25,
    none: 1,
  },

  letterSpacings: {
    tightest: -0.5,
    tighter: -0.25,
    snug: -0.1,
    normal: 0,
    loose: 1,
    relaxed: 1.5,
  },
  space: {
    0: 0,
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
    8: 88,
    9: 104,
    10: 128,
  },

  sizes: {
    wideContainer: 2400,
    container: 2000,
    narrowContainer: 1400,
    full: '100%',
    screenWidth: '100vw',
    screenHeight: '100vh',
    navHeight: 105,
    stickyNavHeight: 70,

    // bunionplasty specific
    formContainer: 950,
    doctorContainer: 1200,
  },

  breakpoints: ['480px', '768px', '1024px', '1600px'],

  radii: {
    square: 0,
    radius: 5,
    corner: 10,
    rounded: 30,
    circle: '100%',
  },

  zIndices: {
    background: -1,
    content: 0,
    sticky: 1,
    overlay: 2,
    nav: 5,
  },

  shadows: {
    cardShadow: '0 0 12px 0 rgba(0,0,0,0.12)',
    iconShadow: '0 0 8px 0 rgba(2,10,52,0.4)',
    buttonShadow: '0 5px 10px 0 rgba(0,0,0,0.4)',
    buttonShadowHover: '0 8px 20px 1px rgba(0,0,0,0.4)',
    videoCardShadow: '0px 8px 20px rgba(0, 0, 0, 0.25)',
    bunionplastyTableShadow: '0px 14px 10px -7px rgba(0, 0, 0, 0.09)',
    formInputShadow: '0 0 6px 0 rgba(0,0,0,0.2)',
    timelineIcon: '0 0 10px 0 rgba(2,10,52,0.4)',
    doctorResultsShadow: '0px 0px 14px rgba(0, 0, 0, 0.1)',
    doctorResultsActiveShadow: '0px 10px 20px rgba(4, 0, 13, 0.4)',
    certifiedBadgeShadow: '0px 5px 10px rgba(4, 0, 13, 0.3)',
    navShadow: '0 15px 15px -15px rgba(0, 0, 0, 0.10)',
    formSelectShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.2)',
  },

  styles,
  buttons,
  forms,
  layout,
  links,
  text,
  modals,
} as const;
