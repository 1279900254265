import { motion } from 'framer-motion';
import { Fragment } from 'react';

import { Box } from 'voom-gatsby';

import { ReactComponent as CarouselDotIcon } from '~svg/icons/carousel-dot.svg';

export const HomeHeroTitleAnimation = () => {
  return (
    <Fragment>
      <Box
        sx={{
          position: 'relative',
          height: [100, 150, 190],
          width: [278, 340, 504],
          mb: [5, 6],
        }}
      >
        <motion.div
          initial={{
            opacity: 0,
            transform: 'scaleX(0)',
          }}
          animate={{ opacity: 1, transform: 'scaleX(1)' }}
          sx={{
            width: '50%',
            height: 2,
            bg: 'primary',
            position: 'absolute',
            top: '0',
            left: '0',
            transformOrigin: 'left',
          }}
        />
        <motion.div
          initial={{
            opacity: 0,
            transform: 'scaleX(0)',
          }}
          animate={{ opacity: 1, transform: 'scaleX(1)' }}
          sx={{
            width: '50%',
            height: 2,
            bg: 'primary',
            position: 'absolute',
            top: '0',
            left: '50%',
            transformOrigin: 'right',
          }}
        />
        <motion.div
          initial={{ opacity: 0, transform: 'scaleY(0) translateX(-50%)' }}
          animate={{
            opacity: 1,
            transform: 'scaleY(1) translateX(-50%)',
          }}
          transition={{ delay: 0.35 }}
          sx={{
            transformOrigin: 'top',
            width: 2,
            height: 'full',
            bg: 'primary',
            position: 'absolute',
            top: '0',
            left: '50%',
          }}
        />
        <motion.div
          initial={{ opacity: 0, transform: 'scale(0) translate(-50%, 50%)' }}
          animate={{
            opacity: 1,
            transform: 'scale(1) translate(-50%, 50%)',
          }}
          transition={{ delay: 0.65 }}
          sx={{
            p: 2,
            fontSize: '0px',
            borderRadius: 'rounded',
            position: 'absolute',
            transformOrigin: 'left',
            bottom: 0,
            left: '50%',
            border: (theme) => `2px solid ${theme?.colors?.primary}`,
          }}
        >
          <CarouselDotIcon sx={{ color: 'primary', width: 7 }} />
        </motion.div>
      </Box>
    </Fragment>
  );
};
