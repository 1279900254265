import { motion } from 'framer-motion';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  Box,
  Container,
  Flex,
  Heading,
  Section,
  StyledSpan,
  StyledSup,
  Text,
  ThemeUIStyleObject,
} from 'voom-gatsby';

export interface SideBySideProps {
  title: string;
  caption: string[];
  list?: string[];
  image: {
    src: ImageDataLike;
    alt: string;
  };
  circle: {
    width: number[];
    top: number[];
    right: number[];
  };
  sx?: ThemeUIStyleObject;
  className?: string;
}

export const SideBySide = ({
  title,
  caption,
  list,
  image,
  circle,
  sx,
  className,
}: SideBySideProps) => {
  const { ref, entry } = useInView();

  const [showCircle, setShowCircle] = useState(false);

  useEffect(() => {
    const reveal =
      (entry && entry.intersectionRatio > 0.4) ||
      (entry && entry.boundingClientRect.top < 0);
    if (showCircle !== reveal) {
      setTimeout(() => {
        setShowCircle(reveal ?? false);
      }, 500);
    }
  }, [entry?.boundingClientRect, entry?.intersectionRatio, setShowCircle]);

  const imageData = getImage(image.src);

  return (
    <Section
      theme={'base'}
      sx={{
        pt: [4, null, null, 10],
        pb: [0, null, null, 10],
        ...sx,
      }}
      className={className}
    >
      <Container
        sx={{
          transform: [
            'none',
            null,
            null,
            null,
            'translateX(4%)',
            'translateX(8%)',
          ],
        }}
      >
        <Flex sx={{ flexDirection: ['column', null, null, 'row'] }}>
          <Box
            sx={{
              flex: ['1 1 auto', null, null, '0 0 50%'],
              my: [4, null, 5, 9],
              position: 'relative',
              zIndex: 'sticky',
              pr: [0, 4, 6, 8],
              pl: [0, 4, 6, 0],
            }}
          >
            <Heading variant="h2" sx={{ mb: [4, 5] }}>
              {title}
            </Heading>
            {caption.map((c) => {
              return (
                <Text as="p" key={c} variant="largeP">
                  <StyledSpan searchWords={['That’s right.', '®', 'Voom™']}>
                    {c}
                  </StyledSpan>
                </Text>
              );
            })}
            {list && (
              <Box as="ul" sx={{ listStyle: 'initial', pl: 3 }}>
                {list?.map((item) => {
                  return (
                    <Text as="li" key={item} variant="largeP">
                      <StyledSup>{item}</StyledSup>
                    </Text>
                  );
                })}
              </Box>
            )}
          </Box>
          <Flex sx={{ mx: [-3, -4], position: 'relative' }}>
            {imageData && (
              <GatsbyImage
                image={imageData}
                alt={image.alt}
                sx={{ width: 'full' }}
              />
            )}
            <motion.div
              ref={ref}
              variants={{
                default: {
                  transform: 'scale(0)',
                  opacity: 0,
                },
                active: {
                  transform: 'scale(1)',
                  opacity: 1,
                },
              }}
              animate={showCircle ? 'active' : 'default'}
              sx={{
                position: 'absolute',
                top: [`${circle.top[0]}%`, null, null, `${circle.top[1]}%`],
                right: [
                  `${circle.right[0]}%`,
                  null,
                  null,
                  `${circle.right[1]}%`,
                ],
                height: [`${circle.width[0]}vw`, null, null, circle.width[1]],
                width: [`${circle.width[0]}vw`, null, null, circle.width[1]],
                borderRadius: 'circle',
                border: (theme) => `3px solid ${theme.colors?.primary}`,
                zIndex: 'overlay',
              }}
            />
          </Flex>
        </Flex>
      </Container>
    </Section>
  );
};
