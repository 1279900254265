import { graphql } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Link,
  Section,
  Spacer,
  StyledSpan,
  Text,
} from 'voom-gatsby';

import { TrailingIcon } from '~components';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';
import { ReactComponent as PeriodIcon } from '~svg/icons/period.svg';
import { ReactComponent as BunionplastyLogo } from '~svg/logo/bunionplasty.svg';
import { ReactComponent as RevconLogo } from '~svg/logo/revcon-with-caption.svg';

interface HeroSideBySideProps {
  title: string;
  links: {
    label: string;
    url: string;
  }[];
  image: {
    src: ImageDataLike;
    alt: string;
  };
  icon: 'REVCON' | 'BUNIONPLASTY';
  caption: string[];
}

export const heroFields = graphql`
  fragment heroFields on ContentJsonSections {
    hero {
      title
      caption
      links {
        label
        url
      }
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        alt
      }
      icon
    }
  }
`;

export const HeroSideBySide = ({
  title,
  caption,
  icon,
  image,
  links,
}: HeroSideBySideProps): JSX.Element => {
  const imageData = getImage(image.src);

  return (
    <Section sx={{ py: [2, 7] }}>
      <Container variant="narrowContainer">
        <Grid
          sx={{
            gridTemplateColumns: ['1fr', null, null, '5fr 3fr'],
            alignItems: 'center',
            gridTemplateAreas: [
              "'image image' 'content content'",
              null,
              null,
              "'content image' 'content image'",
            ],
            rowGap: 0,
            columnGap: [0, null, null, 6],
          }}
        >
          <Box sx={{ gridArea: 'content' }}>
            <Box sx={{ position: 'relative' }}>
              <CrossIcon
                sx={{
                  width: 35,
                  height: 35,
                  mr: 3,
                  position: 'absolute',
                  right: ['unset', null, null, null, '100%'],
                  top: ['-105%', null, null, null, '10%'],
                  fontSize: '0px',
                }}
              />
              {icon === 'REVCON' ? (
                <RevconLogo
                  sx={{
                    width: ['full', 350],
                    height: 'auto',
                    color: 'primary',
                    mb: 2,
                  }}
                />
              ) : (
                <BunionplastyLogo
                  sx={{
                    width: [150, 214],
                    height: 'auto',
                    mb: 0,
                    color: 'bunionplasty',
                  }}
                />
              )}
            </Box>
            <Heading variant="h1" as="h1">
              {title.split('. ').map((t) => {
                return (
                  <TrailingIcon
                    key={t}
                    as="span"
                    Icon={PeriodIcon}
                    sx={{
                      lineHeight: 'heading',
                      display: 'inline-block',
                      span: {
                        mr: 3,
                        lineHeight: 'heading',
                        fontWeight: 'bold',
                        svg: {
                          width: [6, 12],
                          height: [6, 12],
                          bottom: '20%',
                          color: 'secondary',
                          transform: 'translateX(-12px)',
                        },
                      },
                    }}
                  >
                    {t.replace('.', '')}
                  </TrailingIcon>
                );
              })}
            </Heading>
            <Spacer space={[4, 7]} />
            <Box>
              {caption.map((text) => (
                <Text as="p" key={text} variant="mediumP">
                  <StyledSpan
                    searchWords={['Revcon™ screw system', '®']}
                    fontStyle={'semiBold'}
                  >
                    {text}
                  </StyledSpan>
                </Text>
              ))}
            </Box>
            <Flex
              sx={{
                flexDirection: ['column', 'row'],
                alignItems: 'center',
                my: 5,
              }}
            >
              {links.map(({ url, label }, index: number) => {
                return (
                  <Link
                    key={label}
                    href={url}
                    variant={index === 0 ? 'button' : 'underline'}
                    sx={{
                      mr: index === 0 ? [0, 4] : null,
                      mb: index === 0 ? [4, 0] : null,
                      width: index === 0 ? ['full', 'unset'] : null,
                    }}
                  >
                    {label}
                  </Link>
                );
              })}
            </Flex>
          </Box>
          <Box sx={{ gridArea: 'image' }}>
            {imageData && (
              <GatsbyImage
                image={imageData}
                alt={image.alt}
                loading="eager"
                sx={{
                  maxWidth: ['70%', 500],
                  mx: 'auto',
                  mb: [8, null, 0],
                  display: 'block',
                }}
              />
            )}
          </Box>
        </Grid>
      </Container>
    </Section>
  );
};
