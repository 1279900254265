import { ReactNode } from 'react';
import ReactSlickSlider, { Settings } from 'react-slick';

import {
  Box,
  Button,
  ButtonProps,
  Flex,
  ThemeUIStyleObject,
  useThemeUI,
} from '../components';
import { ArrowCircleSvg, ArrowSvg, CarouselDotSvg } from '../svg/icons';

interface SliderArrowProps {
  onClick?: () => void;
  variant?: ButtonProps['variant'];
  sx?: ThemeUIStyleObject;
  className?: string;
}

const isVoomTheme = process.env.THEME === 'voom';

export const SliderNextArrow = ({
  onClick,
  variant = 'slickArrow',
  sx,
  className,
}: SliderArrowProps) => {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      sx={{ ...(isVoomTheme ? { left: 45 } : { right: 0 }), ...sx }}
      className={className}
      aria-label="Next Slide"
    >
      {isVoomTheme ? <ArrowCircleSvg /> : <ArrowSvg />}
    </Button>
  );
};

export const SliderPrevArrow = ({
  onClick,
  variant = 'slickArrow',
  sx,
  className,
}: SliderArrowProps) => {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      sx={{ ...(isVoomTheme ? {} : { left: 0 }), ...sx }}
      className={className}
      aria-label="Previous Slide"
    >
      {isVoomTheme ? (
        <ArrowCircleSvg sx={{ transform: 'rotate(180deg)' }} />
      ) : (
        <ArrowSvg
          sx={{
            transform: 'rotate(180deg)',
          }}
        />
      )}
    </Button>
  );
};

export const Slider = ({
  children,
  settings,
  mobileGradient = true,
  appendDots = false,
  sx,
  className,
}: {
  children: ReactNode;
  settings?: Settings;
  appendDots?: boolean;
  mobileGradient?: boolean;
  sx?: ThemeUIStyleObject;
  className?: string;
}) => {
  const { theme } = useThemeUI();
  const defaultSettings: Settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    ...(appendDots
      ? {
          appendDots: (dots) => {
            return (
              <Flex
                as="ul"
                sx={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  mt: 4,
                  listStyle: 'none',
                  alignItems: 'center',
                  transform: [
                    `translateX(-${theme.space?.[1]}px)`,
                    `translateX(-${theme.space?.[2]}px)`,
                  ],
                }}
              >
                {dots}
              </Flex>
            );
          },
          customPaging: () => {
            return (
              <Box
                sx={{
                  m: 1,
                  p: 2,
                  fontSize: '0px',
                  borderRadius: 'rounded',
                  cursor: 'pointer',
                  '.slick-active > &': {
                    border: `2px solid`,
                    borderColor: 'primary',
                  },
                  svg: {
                    color: 'primary',
                  },

                  '&:hover': {
                    transition: 'border-color 0.3s ease-in-out',
                    '.slick-active > &': {
                      borderColor: 'blue3',
                    },
                    svg: {
                      transition: 'color 0.3s ease-in-out',
                      color: 'blue3',
                    },
                  },
                }}
              >
                <CarouselDotSvg sx={{ color: 'primary', width: 7 }} />
              </Box>
            );
          },
        }
      : {}),

    responsive: [
      {
        breakpoint: parseInt(theme.breakpoints?.[2] ?? ''),
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: parseInt(theme.breakpoints?.[1] ?? ''),
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: parseInt(theme.breakpoints?.[0] ?? ''),
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: `${theme.space?.[3] ?? 16}px`,
        },
      },
    ],
    ...settings,
  };
  return (
    <ReactSlickSlider
      {...defaultSettings}
      className={className}
      sx={{
        position: 'relative',
        // adding space for arrows
        '.slick-list': {
          padding: '0 !important',
        },
        mb: 7,
        // Mobile gradient
        ...(mobileGradient
          ? {
              ':after': {
                content: '""',
                position: 'absolute',
                display: ['block', 'none'],
                right: 0,
                top: 0,
                height: 'full',
                width: '20%',
                backgroundImage: (theme) =>
                  `${theme?.colors?.leftFadeGradient}`,
              },
            }
          : {}),
        ...sx,
      }}
    >
      {children}
    </ReactSlickSlider>
  );
};
