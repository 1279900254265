import useSize from '@react-hook/size';
import { RefObject, useLayoutEffect, useMemo, useState } from 'react';

export const useNavDropdown = (
  parentRef: RefObject<HTMLElement>,
  childRef: RefObject<HTMLElement>,
) => {
  const [parentWidth] = useSize(parentRef);
  const [childWidth] = useSize(childRef);

  const [minWidthAvailable, setMinWidthAvailable] = useState<number>(0);

  useLayoutEffect(() => {
    if (childWidth > 0 && minWidthAvailable === 0) {
      setMinWidthAvailable(childWidth);
    }
  }, [childWidth, minWidthAvailable]);

  const isRendered = useMemo<boolean>(
    () => minWidthAvailable > 0,
    [minWidthAvailable],
  );

  const isDropdown = useMemo<boolean>(
    () => parentWidth < minWidthAvailable,
    [parentWidth, minWidthAvailable],
  );

  return { isRendered, isDropdown };
};
