import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import { ImageDataLike, getImage } from 'gatsby-plugin-image';
import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  Box,
  Container,
  Flex,
  Heading,
  IntrinsicBox,
  IntrinsicImage,
  Section,
  StyledSpan,
  Text,
} from 'voom-gatsby';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';

export const valuesAndMissionFields = graphql`
  fragment valuesAndMissionFields on ContentJsonSections {
    valuesAndMission {
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        alt
      }
      eyebrow
      title
      caption
      callouts {
        title
        caption
      }
    }
  }
`;

interface AboutValuesAndMissionProps {
  image: {
    src: ImageDataLike;
    alt: string;
  };
  eyebrow: string;
  title: string;
  caption: string;
  callouts: {
    title: string;
    caption: string;
  }[];
}
export const AboutValuesAndMission = ({
  image,
  eyebrow,
  title,
  caption,
  callouts,
}: AboutValuesAndMissionProps) => {
  const valuesAndMissionImage = getImage(image.src);

  const { ref, entry } = useInView();

  const [showCircle, setShowCircle] = useState(false);

  useEffect(() => {
    const reveal =
      (entry && entry.intersectionRatio > 0.4) ||
      (entry && entry.boundingClientRect.top < 0);
    if (showCircle !== reveal) {
      setTimeout(() => {
        setShowCircle(reveal ?? false);
      }, 500);
    }
  }, [entry, setShowCircle, showCircle]);

  return (
    <Section theme="base">
      <IntrinsicBox
        ratio={[375 / 400, 1440 / 600]}
        sx={{ position: 'relative', mb: 8, overflow: 'hidden' }}
      >
        {valuesAndMissionImage && (
          <IntrinsicImage
            image={valuesAndMissionImage}
            ratio={[375 / 400, 1440 / 600]}
            alt={image.alt}
            sx={{
              img: { objectPosition: ['50% 20%', '50% 65%'] },
            }}
          />
        )}
        <motion.div
          ref={ref}
          variants={{
            default: {
              transform: 'scale(0)',
              opacity: 0,
            },
            active: {
              transform: 'scale(1)',
              opacity: 1,
            },
          }}
          animate={showCircle ? 'active' : 'default'}
          sx={{
            position: 'absolute',
            top: ['42%', '45%'],
            left: ['35%', '45%'],
            height: ['55vw', '21vw'],
            width: ['55vw', '21vw'],
            borderRadius: 'circle',
            border: (theme) => `3px solid ${theme.colors?.primary}`,
            zIndex: 'overlay',
          }}
        />
      </IntrinsicBox>
      <Container sx={{ my: 8 }}>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 500,
            mx: 'auto',
            textAlign: 'center',
          }}
        >
          <CrossIcon sx={{ width: 32, height: 32, my: 3 }} />
          <Text variant="largeP" sx={{ my: 2, fontWeight: 'semiBold' }}>
            {eyebrow}
          </Text>
          <Heading variant="h2" sx={{ my: 2 }}>
            {title}
          </Heading>
          <Text variant="largeP" sx={{ my: 3 }}>
            <StyledSpan searchWords={['Voom™']} fontStyle="semiBold">
              {caption}
            </StyledSpan>
          </Text>
        </Flex>
        <Flex
          sx={{
            bg: 'blue2',
            maxWidth: 1000,
            mx: 'auto',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexDirection: ['column', null, 'row'],
            my: 7,
            boxShadow: 'dropShadow',
          }}
        >
          {callouts.map(
            (callout: { title: string; caption: string }, index: number) => {
              return (
                <Fragment key={callout.title}>
                  <Flex
                    sx={{
                      textAlign: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      pb: 5,
                      maxWidth: 250,
                      position: 'relative',
                    }}
                  >
                    <Flex
                      sx={{
                        bg: 'primary',
                        fontFamily: 'body',
                        color: 'white',
                        borderRadius: 'rounded',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 40,
                        height: 40,
                        fontWeight: 'bold',
                        fontSize: 'md',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      {index + 1}
                    </Flex>
                    <Heading
                      variant="h5"
                      sx={{ color: 'white', fontWeight: 'semiBold', mb: 3 }}
                    >
                      {callout.title}
                    </Heading>
                    <Text variant="largeP" sx={{ color: 'white' }}>
                      {callout.caption}
                    </Text>
                  </Flex>
                  {index < callouts.length - 1 && (
                    <Box
                      sx={{
                        display: ['none', null, 'block'],
                        height: '170px',
                        width: 1,
                        bg: 'muted',
                        opacity: 0.4,
                      }}
                    />
                  )}
                </Fragment>
              );
            },
          )}
        </Flex>
      </Container>
    </Section>
  );
};
