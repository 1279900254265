import {
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Section,
  StyledSpan,
  Text,
  Theme,
  ThemeUIStyleObject,
} from 'voom-gatsby';

import { TrailingIcon } from '~components';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';
import { ReactComponent as PeriodIcon } from '~svg/icons/period.svg';

interface HeroProps {
  title: string;
  caption: string[];
  links: {
    label: string;
    url: string;
  }[];
}

export const Hero = ({ title, caption, links }: HeroProps): JSX.Element => {
  return (
    <Section sx={{ py: 7 }}>
      <Container>
        <Box sx={{ maxWidth: 900, ml: [0, null, 8] }}>
          <Heading
            variant="h1"
            as="h1"
            sx={{ mb: [4, 8], position: 'relative' }}
          >
            <CrossIcon
              sx={{
                width: [26, 35],
                height: [26, 35],
                mr: [0, 3],
                mb: [3, 0],
                position: 'absolute',
                left: [0, null, 'unset'],
                bottom: ['100%', null, 'unset'],
                right: (theme: Theme) => [
                  'unset',
                  null,
                  `calc(100% + ${theme.space?.[2]}px)`,
                ],
                top: ['unset', null, 4],
                fontSize: '0px',
              }}
            />
            {title.split('. ').map((t) => {
              return (
                <TrailingIcon
                  key={t}
                  as="span"
                  Icon={PeriodIcon}
                  sx={
                    {
                      lineHeight: 'heading',
                      span: {
                        variant: 'styles.h1',
                        lineHeight: 'heading',
                        mr: 3,
                        fontWeight: 'bold',
                        whiteSpace: 'pre-wrap',
                        svg: {
                          width: [6, 12],
                          height: [6, 12],
                          color: 'secondary',
                          bottom: '20%',
                          transform: 'translateX(-12px)',
                        },
                      },
                    } as ThemeUIStyleObject
                  }
                >
                  {t.replace('.', '')}
                </TrailingIcon>
              );
            })}
          </Heading>
          <Box sx={{ width: ['full', '80%'], my: 6 }}>
            {caption.map((text) => (
              <Text as="p" key={text} variant="mediumP">
                <StyledSpan searchWords={['Voom™']} fontStyle="semiBold">
                  {text}
                </StyledSpan>
              </Text>
            ))}
          </Box>
          <Flex
            sx={{
              flexDirection: ['column', 'row'],
              alignItems: 'center',
              my: 5,
            }}
          >
            {links.map(({ url, label }, index: number) => {
              return (
                <Link
                  key={label}
                  href={url}
                  variant={index === 0 ? 'button' : 'underline'}
                  sx={{
                    ':first-of-type': {
                      mr: [0, 4],
                      mb: [4, 0],
                      width: ['full', 'unset'],
                    },
                  }}
                >
                  {label}
                </Link>
              );
            })}
          </Flex>
        </Box>
      </Container>
    </Section>
  );
};
