import { motion } from 'framer-motion';
import { useState } from 'react';
import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  useWatch,
} from 'react-hook-form';

import {
  Box,
  Input,
  Label,
  Text,
  Textarea as TextareaElement,
  ThemeUIStyleObject,
  ThemeUITheme,
  useThemeUI,
} from '../components';

interface FormInputProps {
  label: string;
  name: string;
  type?: string;
  register: object;
  control: Control<FieldValues>;
  errors?: DeepMap<FieldValues, FieldError>;
  Textarea?: boolean;
  sx?: ThemeUIStyleObject;
  className?: string;
}

export const FormInput = ({
  label,
  name,
  type = 'text',
  register,
  control,
  errors,
  Textarea = false,
  sx,
  className,
}: FormInputProps) => {
  const isVoomTheme = process.env.THEME === 'voom';

  const [inputActive, setInputActive] = useState(false);

  const { theme } = useThemeUI();

  const inputValue = useWatch({
    control: control,
    name: name,
  });

  const active = inputValue || inputActive;

  const defaultInputStyles = {
    width: 'full',
    fontFamily: 'body',
    bg: 'white',
    color: 'black',
    borderRadius: isVoomTheme ? 'square' : 'radius',
    border: 'none',
    outline: (theme: ThemeUITheme) =>
      errors
        ? `${theme.colors?.red} solid 2px`
        : inputActive
          ? `${theme.colors?.primary} solid 2px`
          : isVoomTheme
            ? `${theme.colors?.lightGrey} solid 2px`
            : 'none',
  };

  return (
    <Box sx={{ position: 'relative', mb: 3, ...sx }} className={className}>
      <Label sx={{ display: 'block' }}>
        <motion.span
          animate={active ? 'active' : 'default'}
          initial={false}
          variants={{
            active: {
              x: theme.space?.[3],
              y: isVoomTheme ? theme.space?.[1] : theme.space?.[2],
              color: isVoomTheme
                ? (theme?.colors?.grey as string)
                : (theme?.colors?.grey400 as string),
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              fontSize: `${theme?.fontSizes?.copyright}px`,
            },
            default: {
              x: 20,
              y: 19,
              color: isVoomTheme
                ? (theme?.colors?.darkGrey as string)
                : (theme?.colors?.black as string),
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              fontSize: isVoomTheme
                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  `${theme?.fontSizes?.xs}px`
                : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  `${theme?.fontSizes?.sm}px`,
            },
          }}
          transition={{ duration: 0.15 }}
          sx={{
            display: 'inline-block',
            position: 'absolute',
            top: 0,
            left: 0,
            pointerEvents: 'none',
            lineHeight: 1,
            fontFamily: 'body',
            fontWeight: 'medium',
          }}
        >
          {label}
        </motion.span>
        {Textarea ? (
          <TextareaElement
            {...register}
            name={name}
            onFocus={() => setInputActive(true)}
            onBlur={() => setInputActive(false)}
            sx={{
              ...defaultInputStyles,
              minHeight: 220,
              height: (theme) => `calc(100% - ${theme.space?.[3]}px)`,
              resize: 'vertical',
            }}
          />
        ) : (
          <Input
            {...register}
            name={name}
            type={type}
            onFocus={() => setInputActive(true)}
            onBlur={() => setInputActive(false)}
            sx={{
              ...defaultInputStyles,
            }}
          />
        )}
      </Label>
      {errors && (
        <Box sx={{ position: 'absolute', top: 'calc(100% - 3px)', left: 0 }}>
          {errors.type === 'required' ? (
            <Text sx={{ color: 'red', fontSize: 'copyright' }}>Required</Text>
          ) : errors.type === 'email' ? (
            <Text sx={{ color: 'red', fontSize: 'copyright' }}>Email</Text>
          ) : (
            <Text sx={{ color: 'red', fontSize: 'copyright' }}>Error</Text>
          )}
        </Box>
      )}
    </Box>
  );
};
