import { graphql, useStaticQuery } from 'gatsby';

import { SEO } from 'voom-gatsby';

import { LegalTemplate } from '~sections/LegalTemplate';

export const COMPLIANCE_QUERY = graphql`
  query Compliance {
    sanityVoomLegal(title: { eq: "Compliance" }) {
      id
      title
      effectiveDate(formatString: "MMMM DD, YYYY")
      _rawFirstSection
      _rawContent
    }
  }
`;

const Compliance = () => {
  const data = useStaticQuery(COMPLIANCE_QUERY);

  return <LegalTemplate {...data.sanityVoomLegal} />;
};

export const Head = () => {
  return (
    <SEO
      title="Compliance"
      description="At Voom Medical Devices, Dr. Neal Blitz is revolutionizing foot and ankle surgery, including Bunionplasty™ and bunion repair, with innovative medical devices and products."
    />
  );
};

export default Compliance;
