import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Section,
  StyledSpan,
  Text,
} from 'voom-gatsby';

interface HomeSideBySideProps {
  title: string;
  caption: string[];
  link: {
    label: string;
    url: string;
  };
  image: {
    src: ImageDataLike;
    alt: string;
  };
  mobileImage: {
    src: ImageDataLike;
    alt: string;
  };
  circle: {
    width: number[];
    bottom: number[];
    right: number[];
  };
}

export const homeSideBySideFields = graphql`
  fragment homeSideBySideFields on ContentJsonSections {
    homeSideBySide {
      title
      caption
      link {
        label
        url
      }
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        alt
      }
      mobileImage {
        src {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        alt
      }
      circle {
        width
        bottom
        right
      }
    }
  }
`;

export const HomeSideBySide = ({
  title,
  caption,
  link,
  image,
  mobileImage,
  circle,
}: HomeSideBySideProps) => {
  const imageData = getImage(image.src);
  const mobileImageData = getImage(mobileImage.src);
  const { ref, entry } = useInView();

  const [showCircle, setShowCircle] = useState(false);

  useEffect(() => {
    const reveal =
      (entry && entry.intersectionRatio > 0.4) ||
      (entry && entry.boundingClientRect.top < 0);
    if (showCircle !== reveal) {
      setTimeout(() => {
        setShowCircle(reveal ?? false);
      }, 500);
    }
  }, [entry?.boundingClientRect, entry?.intersectionRatio, setShowCircle]);

  return (
    <Section theme="base" sx={{ width: 'full', position: 'relative' }}>
      <Flex sx={{ flexDirection: ['column', null, null, 'row'] }}>
        <Container>
          <Box
            sx={{
              flex: ['1 1 auto', null, null, '0 0 50%'],
              mt: [7, null, null, 250],
              mb: [0, null, null, 250],
              position: 'relative',
              zIndex: 'sticky',
              maxWidth: 550,
            }}
          >
            <Heading variant="h2" sx={{ mb: [4, 5] }}>
              {title}
            </Heading>
            {caption.map((c) => {
              return (
                <Text as="p" key={c} variant="largeP">
                  <StyledSpan searchWords={['Voom™']} fontStyle={'bold'}>
                    {c}
                  </StyledSpan>
                </Text>
              );
            })}
            <Link
              href={link.url}
              variant="buttonOutline"
              sx={{ mt: [4, 5], display: ['block', 'inline-block'] }}
            >
              {link.label}
            </Link>
          </Box>
        </Container>
        <Box
          sx={{
            position: ['relative', null, null, 'static'],
            zIndex: 'content',
            mt: [-3, 0],
          }}
        >
          {imageData && (
            <GatsbyImage
              image={imageData}
              alt={image.alt}
              style={{ position: 'absolute' }}
              sx={{
                height: 'full',
                display: ['none !important', null, null, 'block !important'],
                top: 0,
                right: 0,
                zIndex: 'content',
                img: {
                  objectPosition: 'right top !important',
                  left: 'auto',
                  width: 'auto',
                },
                ':before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: 'full',
                  width: '50%',
                  minWidth: 650,
                  zIndex: 'sticky',
                  backgroundImage: (theme) =>
                    `${theme?.colors?.homeSideBySideGradient}`,
                },
              }}
            />
          )}
          {mobileImageData && (
            <GatsbyImage
              image={mobileImageData}
              alt={mobileImage.alt}
              sx={{
                display: ['block !important', null, null, 'none !important'],
                ':before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '15%',
                  width: 'full',
                  zIndex: 'sticky',
                  backgroundImage: (theme) =>
                    `${theme?.colors?.homeSideBySideMobileGradient}`,
                },
              }}
            />
          )}
          <motion.div
            ref={ref}
            variants={{
              default: {
                transform: 'scale(0)',
                opacity: 0,
              },
              active: {
                transform: 'scale(1)',
                opacity: 1,
              },
            }}
            animate={showCircle ? 'active' : 'default'}
            sx={{
              position: 'absolute',
              bottom: [`${circle.bottom[0]}%`, null, null, circle.bottom[1]],
              right: [`${circle.right[0]}%`, null, null, circle.right[1]],
              height: [`${circle.width[0]}vw`, null, null, circle.width[1]],
              width: [`${circle.width[0]}vw`, null, null, circle.width[1]],
              borderRadius: 'circle',
              border: (theme) => `3px solid ${theme.colors?.primary}`,
              zIndex: 'overlay',
            }}
          />
        </Box>
      </Flex>
    </Section>
  );
};
