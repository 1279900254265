export const container = {
  py: 0,
  px: [4, 5, null, 6],
  mx: 'auto',
  maxWidth: 'container',
};

export const wideContainer = {
  variant: 'layout.container',
  maxWidth: 'wideContainer',
};

export const narrowContainer = {
  variant: 'layout.container',
  maxWidth: 'narrowContainer',
  px: [3, 4, 5],
};

export const noGutterContainer = {
  variant: 'layout.container',
  px: [0, 0, 0, 0],
};

/**
 * An overlay container covers the viewport with a dark,
 * semi-transparent color, and blocks pointer events to that content,
 * while displaying its content on top of the background and
 * allowing its content to be interactive.
 */
export const overlay = {
  position: 'fixed',
  display: 'grid',
  placeItems: 'center center',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  bg: 'backgroundOverlay',
  zIndex: 'overlay',
  '> *': {
    // Allow user selection and pointer events on overlay contents.
    // This is often needed because most overlays will prevent these
    // interactions on the whole document. The assumption here is that
    // anything 'inside' the overlay is what should be interactive, and
    // anything 'outside' the overlay _may_ be non-interactive.
    userSelect: 'text',
    pointerEvents: 'auto',
  },
};

export const formContainer = {
  variant: 'layout.container',
  maxWidth: 'formContainer',
  px: [3, 4],
};

export const doctorContainer = {
  variant: 'layout.container',
  maxWidth: 'doctorContainer',
};

export const homeHeroContainer = {
  variant: 'layout.container',
  px: [4, 5, 6, 8],
};

// reduce mobile spacing since image animates out
export const videGridContainer = {
  variant: 'layout.container',
  px: [3, 4, null, 6],
};

export const navContainer = {
  variant: 'layout.container',
  px: [3, 5, null, 6],
};
