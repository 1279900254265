import { forwardRef } from 'react';
import type { Ref } from 'react';

import { Box } from '../components';
import type { BoxProps, ResponsiveStyleValue } from '../components';

const toPct = (ratio: number): number => Math.round((1 / ratio) * 10000) / 100;

/**
 * Given a `ratio` value or array of values,
 * generates a padding value for maintaining that aspect ratio
 * by sandwiching content between floated `::before` and
 * cleared `::after` pseudo elements.
 *
 * @see https://css-tricks.com/aspect-ratio-boxes/#article-header-id-6
 */
const intrinsicPadding = (
  ratio: ResponsiveStyleValue<number> = 1,
): ResponsiveStyleValue<string> => {
  if (typeof ratio === 'number') {
    return `${toPct(ratio)}%`;
  } else if (Array.isArray(ratio)) {
    return ratio.map((r) => (r == null ? r : `${toPct(r !== false ? r : 0)}%`));
  } else {
    return ratio;
  }
};

export interface IntrinsicBoxProps extends BoxProps {
  /**
   * The intrinsic ratio of width to height for the content.
   *
   * Defaults to `1` (square).
   *
   * May also be defined as a responsive value.
   *
   * Some example values:
   *   - widescreen (16:9): `1.78`
   *   - golden ratio: `1.618`
   *   - square on small screens, wide otherwise: `[1, 16 / 9]`
   */
  ratio?: ResponsiveStyleValue<number>;
  /**
   * The minimum width for the box.
   *
   * If defined, the box will not get narrower than this value,
   * and it will also maintain a proportional height as defined by `ratio`.
   *
   * May also be defined as a responsive value.
   */
  minWidth?: ResponsiveStyleValue<number | string>;
  /**
   * The maximum width for the box.
   *
   * If defined, the box will not get wider than this value,
   * and it will also maintain a proportional height as defined by `ratio`.
   *
   * May also be defined as a responsive value.
   */
  maxWidth?: ResponsiveStyleValue<number | string>;
}

export const IntrinsicBox = forwardRef(
  (
    { ratio = 1, minWidth, maxWidth, sx, ...props }: IntrinsicBoxProps,
    forwardedRef: Ref<unknown>,
  ): JSX.Element => {
    return (
      <Box
        {...props}
        ref={forwardedRef}
        sx={{
          ...sx,
          minWidth,
          maxWidth,
          '::before': {
            content: "''",
            width: '1px',
            marginLeft: '-1px',
            float: 'left',
            height: '0px',
            paddingTop: intrinsicPadding(ratio),
          },
          '::after': {
            content: "''",
            display: 'table',
            clear: 'both',
          },
        }}
      />
    );
  },
);
IntrinsicBox.displayName = 'IntrisicBox';
