import { graphql } from 'gatsby';

import { Container, Section, SectionProps } from 'voom-gatsby';

import { TopFeatureRow } from '~components';

export interface TopFeaturesProps {
  theme?: SectionProps['theme'];
  title: string;
  caption: string[];
}

export const topFeatureFields = graphql`
  fragment topFeatureFields on ContentJsonSections {
    topFeature {
      title
      caption
    }
  }
`;

export const TopFeature = ({ title, caption, theme }: TopFeaturesProps) => {
  const isBlueTheme = theme === 'blue';
  return (
    <Section
      theme={theme ?? 'blue'}
      sx={{
        bg: isBlueTheme ? 'teal3' : 'white', // TODO: make a prop? train-with-us needed teal3 below section
        py: 3,
        position: 'relative',
        '::after': {
          content: "''",
          position: 'absolute',
          top: 0,
          left: 0,
          width: 'full',
          height: '50%',
          bg: isBlueTheme ? 'blue2' : 'teal3',
          zIndex: 'content',
        },
      }}
    >
      <Container
        variant="narrowContainer"
        sx={{ position: 'relative', zIndex: 'sticky' }}
      >
        <TopFeatureRow title={title} caption={caption} theme={theme} />
      </Container>
    </Section>
  );
};
