import { useBreakpointIndex } from '@theme-ui/match-media';
import { Dispatch, FC, SVGAttributes, SetStateAction, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  Spacer,
  Text,
  ThemeUIStyleObject,
} from '../components';
import { CircleSvg, RingSvg } from '../svg/icons';

export interface DropdownButtonProps<T> {
  /** Label used when selected Value is undefined */
  label: string;
  selectedValue?: T;
  onClick: Dispatch<SetStateAction<T>>;
  items: { label: string; value: T }[];
  sx?: ThemeUIStyleObject;
  className?: string;
  mobileModal?: boolean;
  MobileIcon?: FC<SVGAttributes<SVGElement>>;
}

export const DropdownButton = <T,>({
  sx,
  className,
  label,
  selectedValue,
  items,
  onClick,
  /** Used to apply IconButton on mobile */
  MobileIcon,
  mobileModal = false,
}: DropdownButtonProps<T>) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const breakpointIndex = useBreakpointIndex();

  const selectedLabel = items.find(
    (item) => item.value && item.value === selectedValue,
  )?.label;
  return (
    <Box
      sx={{ display: 'inline-block', position: 'relative', zIndex: 1, ...sx }}
      className={className}
    >
      {MobileIcon && (
        <IconButton
          data-id="mobile-icon-button" // needed to apply styles to this button
          onClick={() => setShowDropdown(!showDropdown)}
          sx={{ display: ['flex', null, 'none'] }}
        >
          <MobileIcon />
        </IconButton>
      )}
      <Button
        data-id="dropdown-button" // needed to apply styles to this button
        onClick={() => setShowDropdown(!showDropdown)}
        sx={{
          display: [MobileIcon ? 'none' : 'flex', null, 'flex'],
          span: {
            fontSize: 'xs',
            fontWeight: 'semibold',
          },
        }}
      >
        <Text>{selectedLabel ?? label}</Text>
      </Button>

      {mobileModal && (
        <Modal
          close={() => setShowDropdown(false)}
          open={() => setShowDropdown(true)}
          isOpen={breakpointIndex < 1 && showDropdown}
          showCloseButton={false}
          sx={{
            '&[data-reach-dialog-content]': {
              width: ['70vw'],
              bg: 'white',
            },
          }}
        >
          <Flex
            sx={{
              bg: 'white',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              py: 3,
              px: 4,
              zIndex: 2,
            }}
          >
            <Text
              sx={{
                alignSelf: 'flex-start',
                textAlign: 'left',
                mb: 2,
              }}
            >
              {label}
            </Text>
            {items.map((item) => {
              return (
                <Button
                  key={`${item.value}`}
                  variant="filterItem"
                  onClick={() => {
                    onClick(item.value);
                    setShowDropdown(false);
                  }}
                >
                  {selectedValue == item.value ? <CircleSvg /> : <RingSvg />}
                  <Spacer direction="horizontal" space={1} />
                  <Text>{item.label}</Text>
                </Button>
              );
            })}
          </Flex>
        </Modal>
      )}
      {showDropdown && (
        <Flex
          sx={{
            display: mobileModal ? ['none', 'flex'] : 'flex',
            position: 'absolute',
            top: '100%',
            left: 0,
            bg: 'white',
            boxShadow: 'buttonShadowHover',
            borderRadius: 'corner',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            p: 4,
            mt: 2,
            zIndex: 2,
          }}
          onMouseLeave={() => setShowDropdown(false)}
        >
          {items.map((item) => {
            return (
              <Button
                key={`${item.value}`}
                variant="filterItem"
                onClick={() => {
                  onClick(item.value);
                  setShowDropdown(false);
                }}
              >
                {selectedValue == item.value ? <CircleSvg /> : <RingSvg />}
                <Spacer direction="horizontal" space={1} />
                <Text>{item.label}</Text>
              </Button>
            );
          })}
        </Flex>
      )}
    </Box>
  );
};
