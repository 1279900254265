import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { atom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const navStateAtom = atom({
  isSticky: false,
  isNavVisible: true,
  isDropdownOpen: false,
  preventPeekaBoo: false,
  secondaryNav: false,
});

export const useStickyHeader = () => {
  const setNavState = useSetAtom(navStateAtom);

  const handleScroll = useDebouncedCallback(() => {
    const position = window.scrollY;
    setNavState((state) => {
      // Sticky offset when secondary nav is present must be larger to trigger when at top of page
      const stickyOffset = state.secondaryNav === true ? 300 : 30;
      return {
        ...state,
        isSticky: position > stickyOffset,
      };
    });
  }, 1);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y > prevPos.y) {
      setNavState((state) => ({
        ...state,
        isNavVisible:
          state.secondaryNav === true && state.isSticky == true ? false : true,
      }));
    } else if (currPos.y < 0) {
      setNavState((state) => ({ ...state, isNavVisible: false }));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
};
