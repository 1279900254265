import { graphql } from 'gatsby';

import { SideBySide, SideBySideProps } from '~components';

export const bunionplastySideBySideTopFields = graphql`
  fragment bunionplastySideBySideTopFields on ContentJsonSections {
    bunionplastySideBySideTop {
      title
      caption
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        alt
      }
      circle {
        width
        top
        right
      }
    }
  }
`;

export const BunionplastySideBySideTop = (props: SideBySideProps) => {
  return <SideBySide {...props} />;
};
