import { Box, Flex, Link } from 'voom-gatsby';

export const FooterInfoColumn = ({
  siteSettings,
}: {
  siteSettings: {
    phoneNumber: {
      display: string;
      link: string;
    };
    faxNumber: {
      display: string;
      link: string;
    };
    email: {
      display: string;
      link: string;
    };
    socials: {
      icon: {
        svg: string;
      };
      title: string;
      URL: string;
    }[];
  };
}): JSX.Element => {
  return (
    <Box
      sx={{
        gridRow: [2, 1],
        '> a': { mb: 2, fontWeight: 200, whiteSpace: 'nowrap' },
      }}
    >
      <Link href={`tel:${siteSettings.phoneNumber.link}`} variant="inline">
        {siteSettings.phoneNumber.display}
      </Link>
      <Link href={`tel:${siteSettings.faxNumber.link}`} variant="inline">
        {siteSettings.faxNumber.display}
      </Link>
      <Link
        href={`mailto:${siteSettings.email.link}?subject=Voom™ forward with us`}
        variant="inline"
      >
        {siteSettings.email.display}
      </Link>
      <Flex sx={{ ml: -2, my: 4 }}>
        {siteSettings.socials.map((social) => {
          return (
            <Link
              variant="icon"
              sx={{
                mr: 2,
                svg: { color: 'white' },
                ':hover': { svg: { color: 'primary' } },
              }}
              key={social.title}
              href={social.URL}
              aria-label={social.title}
            >
              <Box
                dangerouslySetInnerHTML={{ __html: social.icon.svg }}
                sx={{
                  '&, svg': {
                    color: 'white',
                    height: 20,
                    width: 20,
                    mx: 'auto',
                  },
                }}
              />
            </Link>
          );
        })}
      </Flex>
    </Box>
  );
};
