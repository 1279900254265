import useSize from '@react-hook/size';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';
import { Fragment, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  Box,
  Container,
  Flex,
  IntrinsicBox,
  Paragraph,
  Section,
  ThemeUIStyleObject,
} from 'voom-gatsby';

import { ReactComponent as CarouselDotIcon } from '~svg/icons/carousel-dot.svg';

interface HotSpotCardProps {
  image: {
    src: ImageDataLike;
    alt: string;
  };
  title: string;
  caption: string;
  imageWidth: number;
  position: {
    x: number;
    y: number;
  };
}

interface HostSpotsProps {
  cards: HotSpotCardProps[];
  sx?: ThemeUIStyleObject;
  className?: string;
}

export const hotSpotsFields = graphql`
  fragment hotSpotsFields on ContentJsonSections {
    hotSpots {
      cards {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            }
          }
          alt
        }
        title
        caption
        imageWidth
        position {
          x
          y
        }
      }
    }
  }
`;

export const HotSpotCard = ({
  image,
  imageWidth,
  title,
  caption,
  position,
}: HotSpotCardProps) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  const titleRef = useRef(null);
  const [, titleHeight] = useSize(titleRef);

  const imageData = getImage(image.src);

  return (
    <IntrinsicBox
      ref={ref}
      ratio={410 / 380}
      sx={{
        width: 'full',
        height: 'full',
        position: 'relative',
        maxWidth: 410,
        mx: 'auto',
      }}
    >
      {imageData && (
        <GatsbyImage
          image={imageData}
          alt={image.alt}
          style={{ position: 'absolute', pointerEvents: 'none' }}
          sx={{
            width: `${imageWidth}%`,
            right: 0,
            bottom: 0,
            maxWidth: ['full', 'none'],
          }}
        />
      )}
      <Box
        ref={titleRef}
        sx={{
          maxWidth: 220,
          ml: [0, 3],
          position: 'relative',
          zIndex: 'sticky',
        }}
      >
        <Paragraph
          variant="smallP"
          sx={{
            fontWeight: 'semibold',
            mb: 0,
          }}
        >
          {title}
        </Paragraph>
        <Paragraph variant="smallP" sx={{ mb: 0 }}>
          {caption}
        </Paragraph>
      </Box>
      {titleHeight > 0 && (
        <Fragment>
          <motion.div
            variants={{
              default: {
                opacity: 0,
                transform: 'scaleX(0)',
              },
              active: {
                opacity: 1,
                transform: 'scaleX(1)',
              },
            }}
            initial="default"
            animate={inView ? 'active' : 'default'}
            sx={{
              width: 100,
              height: 2,
              bg: 'primary',
              position: 'absolute',
              left: [0, 3],
              top: (theme) => `${Number(theme.space?.[2]) + titleHeight}px`,
              transformOrigin: 'right',
            }}
          />
          <motion.div
            variants={{
              default: {
                opacity: 0,
                transform: 'scaleY(0)',
              },
              active: {
                opacity: 1,
                transform: 'scaleY(1)',
              },
            }}
            transition={{ delay: 0.3 }}
            initial="default"
            animate={inView ? 'active' : 'default'}
            sx={{
              width: 2,
              bg: 'primary',
              position: 'absolute',
              left: [0, 3],
              top: (theme) => `${Number(theme.space?.[2]) + titleHeight}px`,
              bottom: `${100 - position.y}%`,
              transformOrigin: 'top',
            }}
          />
          <motion.div
            variants={{
              default: {
                opacity: 0,
                transform: 'scaleX(0)',
              },
              active: {
                opacity: 1,
                transform: 'scaleX(1)',
              },
            }}
            transition={{ delay: 0.6 }}
            initial="default"
            animate={inView ? 'active' : 'default'}
            sx={{
              height: 2,
              bg: 'primary',
              position: 'absolute',
              left: [0, 3],
              top: `${position.y}%`,
              right: `${100 - position.x}%`,
              transformOrigin: 'left',
            }}
          />
          <motion.div
            variants={{
              default: {
                opacity: 0,
                transform: 'scale(0) translate(-50%, calc(-50% + 1px))',
              },
              active: {
                opacity: 1,
                transform: 'scale(1) translate(-50%, calc(-50% + 1px))',
              },
            }}
            transition={{ delay: 0.8 }}
            initial="default"
            animate={inView ? 'active' : 'default'}
            sx={{
              p: 2,
              fontSize: '0px',
              borderRadius: 'rounded',
              position: 'absolute',
              transformOrigin: 'left',
              top: `${position.y}%`,
              left: `${position.x}%`,
              border: (theme) => `2px solid ${theme?.colors?.primary}`,
            }}
          >
            <CarouselDotIcon
              sx={{
                color: 'primary',
                width: 5,
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </motion.div>
        </Fragment>
      )}
    </IntrinsicBox>
  );
};

export const HotSpots = ({ cards, sx, className }: HostSpotsProps) => {
  return (
    <Section theme="base" className={className} sx={{ py: 8, ...sx }}>
      <Container>
        <Flex
          sx={{
            justifyContent: 'space-evenly',
            flexDirection: ['column', null, 'row'],
          }}
        >
          {cards.map((card) => {
            return <HotSpotCard key={card.caption} {...card} />;
          })}
        </Flex>
      </Container>
    </Section>
  );
};
