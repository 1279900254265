import type { LinkProps as ReachLinkProps } from '@reach/router';
import { Link as GatsbyLink } from 'gatsby';
import { Link as ReactScrollLink } from 'react-scroll';
import { ThemeUIStyleObject, Link as ThemeUiLink } from 'theme-ui';

import { StyledSup } from '../components';
import type { ThemeVariantName } from '../themes';

interface LinkPropsBase<TState>
  extends Omit<ReachLinkProps<TState>, 'ref' | 'innerRef' | 'to'> {
  to?: string;
  href?: string;
  /**
   * name of the variant style using theme-UI
   */
  variant?: ThemeVariantName<'links'>;
  /**
   * offset to be used on anchor links
   */
  offset?: number;
  sx?: ThemeUIStyleObject;
}

export interface LinkPropsWithTo<TState>
  extends Omit<LinkPropsBase<TState>, 'href'> {
  /**
   * the url string to navigate to.
   * Can be internal or external
   */
  to: string;
}

export interface LinkPropsWithHref<TState>
  extends Omit<LinkPropsBase<TState>, 'to'> {
  /**
   * the url string to navigate to.
   * Can be internal or external
   */
  href: string;
}

export type LinkProps<TState> =
  | LinkPropsWithTo<TState>
  | LinkPropsWithHref<TState>;

// export function Link<TState>(props: LinkPropsWithHref<TState>): JSX.Element;
export function Link<TState>({
  children,
  replace,
  getProps,
  state,
  to,
  href = to,
  sx,
  className, // theme-ui jsx passes className with styling
  variant,
  ...other
}: LinkPropsBase<TState>): JSX.Element {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  // Also need to make sure the href does not start with /files/ that href is used
  // for PDFs and gatsby link will not render those links correctly
  const internal = /^\/(?!\/|files\/)/.test(href ?? '');
  // Use Gatsby/Reach Link for internal links.(?!static)
  if (internal) {
    return (
      <GatsbyLink
        sx={variant ? { ...sx, variant: `links.${variant}` } : sx}
        to={href ?? ''}
        replace={replace}
        getProps={getProps}
        state={state}
        className={className}
        {...other}
      >
        <StyledSup>{children}</StyledSup>
      </GatsbyLink>
    );
  }
  // Second option is href starts with a hash # which means it should
  // be used as an anchor link and will scroll to anchor location
  const anchor = href?.startsWith('#');
  if (anchor) {
    return (
      <ReactScrollLink
        href={href} // dummy href to make it focusable
        to={href?.replace('#', '') ?? ''}
        smooth={'easeInOutQuad'}
        duration={500}
        className={className}
        sx={variant ? { ...sx, variant: `links.${variant}` } : sx}
        // add active styling when section is active
        // add '&.active' styling to theme or inline
        spy={true}
        activeClass="active"
        {...(other as unknown as object)}
      >
        <StyledSup>{children}</StyledSup>
      </ReactScrollLink>
    );
  }
  //  else use external link
  return (
    <ThemeUiLink
      // All external links will open in new tab as requested from grooming
      // This may be controversial.
      target="_blank"
      rel="noopener noreferrer"
      variant={variant}
      href={href}
      sx={sx}
      {...other}
      className={className}
    >
      <StyledSup>{children}</StyledSup>
    </ThemeUiLink>
  );
}
