import { graphql, useStaticQuery } from 'gatsby';

import {
  Box,
  BreadCrumbs,
  Container,
  Flex,
  Grid,
  Heading,
  SEO,
  Section,
  Spacer,
  StyledSpan,
  Text,
} from 'voom-gatsby';

import { MemberCard, MemberCardProps, TrailingIcon } from '~components';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';
import { ReactComponent as PeriodIcon } from '~svg/icons/period.svg';

const LeadershipPage = () => {
  const {
    contentJson: { sections },
    allSanityVoomExecutiveMember,
    allSanityVoomCreativeMember,
    allSanityVoomEditorialMember,
  } = useStaticQuery(LEADERSHIP_PAGE_QUERY);

  return (
    <Box>
      {sections.leadershipHero && (
        <Container variant="narrowContainer" sx={{ py: 8 }}>
          <BreadCrumbs />
          <Heading as="h1" variant="h1" sx={{ maxWidth: 730, my: 5 }}>
            <TrailingIcon
              as="span"
              Icon={PeriodIcon}
              sx={{
                lineHeight: 'heading',
                span: {
                  variant: 'styles.h1',
                  lineHeight: 'heading',
                  mr: 3,
                  fontWeight: 'bold',
                  svg: {
                    width: [6, 12],
                    height: [6, 12],
                    color: 'secondary',
                    bottom: '20%',
                    transform: 'translateX(-12px)',
                  },
                },
              }}
            >
              {sections.leadershipHero.title.replace('.', '')}
            </TrailingIcon>
          </Heading>
          <Text variant="largeP" sx={{ maxWidth: 615, display: 'block' }}>
            <StyledSpan fontStyle="semiBold" searchWords={['Voom™']}>
              {sections.leadershipHero.caption}
            </StyledSpan>
          </Text>
        </Container>
      )}

      <Section
        variant="fullWidthSection"
        sx={{
          py: [5, 7, 10],
          background: (theme) => `${theme.colors?.tealGradient}`,
        }}
      >
        {allSanityVoomExecutiveMember.nodes.length > 0 && (
          <Container>
            <Flex sx={{ alignItems: 'center' }}>
              <CrossIcon sx={{ height: 35 }} />
              <Heading variant="h3">Executive Team</Heading>
            </Flex>
            <Spacer space={6} />

            <Grid columns={[1, 2, null, 3]} sx={{ gridGap: 5 }}>
              {allSanityVoomExecutiveMember.nodes.map(
                (member: MemberCardProps) => {
                  return <MemberCard key={member.name} {...member} />;
                },
              )}
            </Grid>
          </Container>
        )}
        <Spacer space={150} />
        {allSanityVoomEditorialMember.nodes.length > 0 && (
          <Container>
            <Flex sx={{ alignItems: 'center' }}>
              <CrossIcon sx={{ height: 35 }} />
              <Heading variant="h3">Advisors + Editorial Team JMIBS</Heading>
            </Flex>
            <Spacer space={6} />
            <Grid columns={[1, 2, null, 3]} sx={{ gridGap: 5 }}>
              {allSanityVoomEditorialMember.nodes.map(
                (member: MemberCardProps) => {
                  return <MemberCard key={member.name} {...member} />;
                },
              )}
            </Grid>
          </Container>
        )}
        <Spacer space={150} />
        {allSanityVoomCreativeMember.nodes.length > 0 && (
          <Container>
            <Flex sx={{ alignItems: 'center' }}>
              <CrossIcon sx={{ height: 35 }} />
              <Heading variant="h3">Brand Leadership Team</Heading>
            </Flex>
            <Spacer space={6} />
            <Grid columns={[1, 2, null, 3]} sx={{ gridGap: 5 }}>
              {allSanityVoomCreativeMember.nodes.map(
                (member: MemberCardProps) => {
                  return <MemberCard key={member.name} {...member} />;
                },
              )}
            </Grid>
          </Container>
        )}
      </Section>
    </Box>
  );
};

export const Head = () => {
  return (
    <SEO
      title="Meet the Team | Voom™ Medical Devices | Founded by Surgeon Innovators + MIBS Pioneers"
      description="Founded by Dr. Neal Blitz, Voom™ is led by world-renowned surgeons, industry innovators, and clinical pioneers who are leading a new era in minimally invasive bunion surgery (MIBS)."
    />
  );
};

export const LEADERSHIP_PAGE_QUERY = graphql`
  query LeadershipPage {
    contentJson(jsonId: { eq: "leadership" }) {
      sections {
        leadershipHero {
          title
          caption
        }
      }
    }
    allSanityVoomExecutiveMember(sort: { order: ASC }) {
      nodes {
        image {
          asset {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          }
        }
        position
        name
        facebookLink
        instagramLink
        youtubeLink
        linkedInLink
      }
    }
    allSanityVoomCreativeMember(sort: { order: ASC }) {
      nodes {
        image {
          asset {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          }
        }
        position
        name
        facebookLink
        instagramLink
        youtubeLink
        linkedInLink
      }
    }
    allSanityVoomEditorialMember(sort: { order: ASC }) {
      nodes {
        image {
          asset {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          }
        }
        position
        name
        facebookLink
        instagramLink
        youtubeLink
        linkedInLink
      }
    }
  }
`;

export default LeadershipPage;
