import { graphql, useStaticQuery } from 'gatsby';

import { Box, SEO } from 'voom-gatsby';

import { AboutMeetTheTeam } from '~sections/AboutMeetTheTeam';
import { AboutValuesAndMission } from '~sections/AboutValuesAndMission';
import { Hero } from '~sections/Hero';
import { NewsPressList } from '~sections/NewsPressList';

const AboutUsPage = () => {
  const {
    contentJson: { sections },
  } = useStaticQuery(ABOUT_US_PAGE_QUERY);

  return (
    <Box>
      {sections.hero && <Hero {...sections.hero} />}
      {sections.valuesAndMission && (
        <AboutValuesAndMission {...sections.valuesAndMission} />
      )}
      {sections.meetTheTeam && <AboutMeetTheTeam {...sections.meetTheTeam} />}
      <NewsPressList featured={true} />
    </Box>
  );
};

export const Head = () => {
  return (
    <SEO
      title="About Voom | Voom™ Medical Devices | Founded by Surgeons, For Surgeons"
      description="Voom™ Medical Devices is committed to advancing fusion-free minimally invasive bunion solutions, fostering surgical innovation, and ensuring the highest standards of patient care."
    />
  );
};

export const ABOUT_US_PAGE_QUERY = graphql`
  query AboutUsPage {
    contentJson(jsonId: { eq: "about-us" }) {
      sections {
        ...heroFields
        ...valuesAndMissionFields
        ...meetTheTeamFields
      }
    }
  }
`;

export default AboutUsPage;
