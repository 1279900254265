import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Box, Flex, Paragraph } from 'voom-gatsby';

export interface TrainDoctorProps {
  doctor: 'BLITZ' | 'DIDOMENICO' | 'BASKIN';
}

export const TrainDoctor = ({ doctor }: TrainDoctorProps) => {
  const doctors = useStaticQuery(TRAIN_DOCTOR_QUERY);
  const doctorData = doctors[doctor];
  if (!doctorData) return null;
  return (
    <Flex sx={{ alignItems: 'center' }}>
      <GatsbyImage
        image={doctorData.image.asset.gatsbyImageData}
        alt=""
        sx={{
          flex: '0 0 70px',
          border: '5px solid white',
          boxShadow: 'trainShadow',
          mr: 2,
          borderRadius: 'circle',
          img: { borderRadius: 'circle' },
        }}
      />
      <Box>
        <Paragraph variant="largeP" sx={{ fontWeight: 'semibold', mb: 0 }}>
          {doctorData.name}
        </Paragraph>
        <Paragraph variant="smallP" sx={{ mb: 0 }}>
          {doctorData.position}
        </Paragraph>
      </Box>
    </Flex>
  );
};

const TRAIN_DOCTOR_QUERY = graphql`
  query MyQuery {
    BLITZ: sanityVoomExecutiveMember(
      _id: { eq: "bff88aba-7071-4029-b8a4-ea9af23455ea" }
    ) {
      name
      position
      image {
        asset {
          gatsbyImageData(layout: FIXED, width: 70)
        }
      }
    }
    DIDOMENICO: sanityVoomExecutiveMember(
      _id: { eq: "f2994c75-224c-40ee-96ca-0308603671a1" }
    ) {
      name
      position
      image {
        asset {
          gatsbyImageData(layout: FIXED, width: 70)
        }
      }
    }
    BASKIN: sanityVoomExecutiveMember(
      _id: { eq: "5877ba74-1fc8-4740-8263-cacaf5d053fa" }
    ) {
      name
      position
      image {
        asset {
          gatsbyImageData(layout: FIXED, width: 70)
        }
      }
    }
  }
`;
