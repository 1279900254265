import { atom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

export const ipGeoAtom = atom({
  city: '',
  subdivisionCode: '',
  subdivisionName: '',
  countryCode: '',
  countryName: '',
  timezone: '',
  lat: '',
  lng: '',
  ip: '',
});

export const useIpGeo = () => {
  const setIpGeo = useSetAtom(ipGeoAtom);

  useEffect(() => {
    const fetchIpGeo = async () => {
      if (process.env.NETLIFY === 'true') {
        const response = await fetch(`/geolocation`);
        const data = await response.json();
        setIpGeo({
          city: data.geo.city,
          subdivisionCode: data.geo.subdivision?.code,
          subdivisionName: data.geo.subdivision?.name,
          countryCode: data.geo.country?.code,
          countryName: data.geo.country?.name,
          timezone: data.geo.timezone,
          lat: data.geo.latitude,
          lng: data.geo.longitude,
          ip: data.geo.ip,
        });
      }
    };
    fetchIpGeo();
  }, [setIpGeo]);
};
