import type { Theme as StyledSystemTheme } from 'styled-system';
import type { Theme as ThemeUITheme } from 'theme-ui';

import bunionplastyBase from './bunionplasty/base';
import * as bunionplastyColors from './bunionplasty/colors';
import voomBase from './voom/base';
import * as voomColors from './voom/colors';

const isVoomTheme = process.env.THEME === 'voom';

type ThemeColors = {
  -readonly [K in keyof Theme['colors']]:
    | (typeof voomColors)[keyof typeof voomColors]
    | (typeof bunionplastyColors)[keyof typeof bunionplastyColors];
};

interface ThemeConfig {
  colors?: ThemeColors;
  fonts?: {
    -readonly [K in keyof Theme['fonts']]?: string;
  };
}

/** Creates a theme from a config. */
const createTheme = (config: ThemeConfig): ThemeUITheme => {
  // Note: This is currently an identity function because ThemeUI's
  // `ThemeUIProvider` does the theme composition and extension work for us.
  // It exists to enforce the `ThemeConfig` contract, and as a namespace
  // for potential future validation, sanitation, or transformation, etc.
  return config as ThemeUITheme;
};

export const themes = {
  ...(isVoomTheme
    ? {
        base: createTheme({
          /**
           * Note: This is the default theme
           * so it has everything in common with the base.
           * Update ./base.ts if anything is wrong
           */
          colors: {
            ...voomBase.colors,
          },
          fonts: {
            ...voomBase.fonts,
          },
        }),
        blue: createTheme({
          colors: {
            ...voomBase.colors,
            background: voomColors.BLUE_2,
            text: voomColors.WHITE,
            link: voomColors.WHITE,
            nav: voomColors.WHITE,
            navSticky: voomColors.BLACK,
            buttonBg: voomColors.WHITE,
            buttonColor: voomColors.PINK,
            logo: voomColors.WHITE,
            bunionplasty: voomColors.WHITE,
          },
          fonts: {
            ...voomBase.fonts,
          },
        }),

        teal: createTheme({
          colors: {
            ...voomBase.colors,
            background: voomColors.TEAL_3,
          },
          fonts: {
            ...voomBase.fonts,
          },
        }),
      }
    : {
        base: createTheme({
          /**
           * Note: This is the default theme
           * so it has everything in common with the base.
           * Update ./base.ts if anything is wrong
           */
          colors: {
            ...bunionplastyBase.colors,
          },
          fonts: {
            ...bunionplastyBase.fonts,
          },
        }),
        // Duplicate the base theme to create variants for typescript for voom components
        blue: createTheme({
          colors: {
            ...bunionplastyBase.colors,
          },
          fonts: {
            ...bunionplastyBase.fonts,
          },
        }),
        // Duplicate the base theme to create variants for typescript for voom components
        teal: createTheme({
          colors: {
            ...bunionplastyBase.colors,
          },
          fonts: {
            ...bunionplastyBase.fonts,
          },
        }),
      }),
} as const;

export const getThemeByName = (name: ThemeName = 'base'): ThemeUITheme =>
  name && name in themes ? themes[name] : {};

export type Theme = typeof voomBase | typeof bunionplastyBase;
/** The name of a theme to use. */
export type ThemeName = keyof typeof themes;
export type ThemeVariant = Omit<
  Theme,
  'useLocalStorage' | 'styles' | keyof Omit<StyledSystemTheme, 'buttons'>
>;
/** The name of a theme variant to use. */
export type ThemeVariantName<K extends string> = K extends keyof ThemeVariant
  ? keyof ThemeVariant[K]
  : string;
