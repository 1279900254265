import { graphql } from 'gatsby';
import {
  GatsbyImage,
  ImageDataLike,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image';
import invariant from 'invariant';

import {
  Container,
  Flex,
  Heading,
  Link,
  Section,
  StyledSpan,
  StyledSup,
  Text,
  useThemeUI,
} from 'voom-gatsby';

import { HomeHeroTitleAnimation, TrailingIcon } from '~components';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';
import { ReactComponent as PeriodIcon } from '~svg/icons/period.svg';

interface HomeHeroProps {
  title: string;
  caption: string[];
  links: {
    label: string;
    url: string;
  }[];
  mobileImage: ImageDataLike;
  desktopImage: ImageDataLike;
}

export const homeHeroFields = graphql`
  fragment homeHeroFields on ContentJsonSections {
    homeHero {
      title
      caption
      links {
        label
        url
      }
      desktopImage {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      }
      mobileImage {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      }
    }
  }
`;

export const HomeHero = ({
  title,
  caption,
  links,
  mobileImage,
  desktopImage,
}: HomeHeroProps) => {
  const { theme } = useThemeUI();
  const mobileGatsbyImage = getImage(mobileImage);
  invariant(mobileGatsbyImage, 'Expected valid mobile image data');
  const sources = withArtDirection(mobileGatsbyImage, [
    {
      media: `(min-width: ${theme.breakpoints?.[0]})`,
      // We assume image is added, typescript doesn't like it
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      image: getImage(desktopImage) ?? desktopImage,
    },
  ]);

  return (
    <Section theme="blue" sx={{ position: 'relative', overflow: 'hidden' }}>
      {sources && (
        <GatsbyImage
          image={sources}
          alt={title}
          loading="eager"
          style={{
            top: 0,
            left: 0,
            width: '100%',
            position: 'absolute',
            zIndex: 0,
          }}
          sx={{
            ':before': {
              content: '""',
              width: 'full',
              height: 'full',
              position: 'absolute',
              zIndex: 'sticky',
              top: 0,
              left: 0,
              background:
                'linear-gradient(180deg, rgba(24,31,61,0.85) 0%, rgba(24,31,61,0.95) 70%, rgba(24,31,61,1) 100%)',
              opacity: '0.9',
              filter: 'contrast(1.2) brightness(1.4)',
            },
          }}
        />
      )}

      <Container
        sx={{
          position: 'relative',
          zIndex: 'sticky',
          pt: [280, 350],
          pb: [140, 200],
        }}
      >
        <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <CrossIcon sx={{ width: [25, 35], height: [25, 35], mb: [2, 4] }} />
          <Heading
            as="h1"
            variant="h1"
            sx={{
              fontSize: ['3xl', '4xl', '5xl'],
              maxWidth: 700,
              textAlign: 'center',
              pb: 2,
              mx: [-3, 'auto'],
              position: 'relative',
            }}
          >
            {title.split('. ').map((t) => {
              return (
                <TrailingIcon
                  key={t}
                  as="span"
                  Icon={PeriodIcon}
                  sx={{
                    lineHeight: 'heading',
                    // force break on mobile
                    display: ['inline-block', 'inline'],
                    span: {
                      lineHeight: 'heading',
                      fontWeight: 'bold',
                      svg: {
                        width: [6, 12],
                        height: [6, 12],
                        bottom: '20%',
                        ml: [2, 3],
                        color: 'primary',
                      },
                    },
                  }}
                >
                  <StyledSup>{t.replace('.', '')}</StyledSup>
                </TrailingIcon>
              );
            })}
          </Heading>
          <HomeHeroTitleAnimation />
          {caption.map((c, index) => {
            return (
              <Text
                key={c}
                as="p"
                variant="homeHeroCaption"
                sx={{
                  maxWidth: 850,
                  textAlign: 'center',
                  mb: 4,
                }}
              >
                <StyledSpan
                  searchWords={[
                    ...(index === 0
                      ? ['Get patients back on their feet fast.']
                      : []),
                    'What kind of humans?',
                    'Voom™',
                    'We’re being humans, too.',
                  ]}
                >
                  {c}
                </StyledSpan>
              </Text>
            );
          })}
          {links.map(({ label, url }, index) => {
            return (
              <Link
                key={label}
                href={url}
                variant={index === 0 ? 'button' : 'underline'}
                sx={{
                  mt: 4,
                  ...(index === 0 ? { width: ['full', 'auto'] } : {}),
                }}
              >
                {label}
              </Link>
            );
          })}
        </Flex>
      </Container>
    </Section>
  );
};
