import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Fragment } from 'theme-ui/jsx-runtime';

import {
  Box,
  CertifiedBadge,
  Divider,
  Flex,
  Heading,
  Paragraph,
  Section,
  Spacer,
} from 'voom-gatsby';

export interface TrainSideBySideProps {
  image: {
    src: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    alt: string;
  };
  title: string;
  badge: string;
  bullets: string[];
}

export const trainSideBySideFields = graphql`
  fragment trainSideBySideFields on ContentJsonSections {
    trainSideBySide {
      title
      bullets
      badge
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        alt
      }
    }
  }
`;

export const TrainSideBySide = ({
  image,
  title,
  bullets,
  badge,
}: TrainSideBySideProps) => {
  return (
    <Section
      theme="base"
      sx={{
        py: [6, 8],
        bg: 'transparent',
      }}
    >
      <Flex
        sx={{
          flexDirection: ['column', null, null, 'row'],
          px: [4, null, null, 0],
        }}
      >
        <Box sx={{ flex: '0 0 42%', position: 'relative' }}>
          <GatsbyImage
            image={image.src.childImageSharp.gatsbyImageData}
            alt={image.alt}
            sx={{ width: 'full' }}
          />
          <CertifiedBadge
            text={badge}
            sx={{ top: 'unset', bottom: 0, maxWidth: 'none' }}
          />
        </Box>
        <Flex
          sx={{
            flexDirection: 'column',
            alignSelf: 'center',
            pl: [null, null, null, 8],
            maxWidth: 650,
            mt: [6, null, null, 0],
          }}
        >
          <Heading variant="h2">{title}</Heading>
          <Spacer space={6} />
          {bullets.map((bullet) => {
            return (
              <Fragment key={bullet}>
                <Paragraph variant="trainSideBySideBullet">{bullet}</Paragraph>
                <Divider sx={{ borderColor: 'grey', my: 3 }} />
              </Fragment>
            );
          })}
        </Flex>
      </Flex>
    </Section>
  );
};
