import { ThemeUITheme } from '../../../components/ThemeUi';

export const overlay = {
  '&[data-reach-dialog-overlay]': {
    background: (theme: ThemeUITheme) => `${theme.colors?.modalOverlay}`,
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowX: 'hidden',
    height: '-webkit-fill-available',
    width: '100vw',
  },
};

export const content = {
  '&[data-reach-dialog-content]': {
    m: 0,
    bg: 'beige',
    p: 0,
    fontFamily: 'body',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: ['95vw', null, '80vw'],
    maxWidth: 1000,
    height: 'auto',
    maxHeight: ['80vh'],
    overflow: 'hidden',
    my: 0,
    borderRadius: 'rounded',
  },
};
