import { graphql } from 'gatsby';

import { Container, Heading, Paragraph, Section, Slider } from 'voom-gatsby';

import {
  CircleImageCarouselCard,
  CircleImageCarouselCardProps,
} from '~components';

interface RevconSystemFeaturesProps {
  title: string;
  disclaimer: string;
  cards: CircleImageCarouselCardProps[];
}

export const revconSystemFeaturesFields = graphql`
  fragment revconSystemFeaturesFields on ContentJsonSections {
    revconSystemFeatures {
      title
      disclaimer
      cards {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FIXED, placeholder: NONE, width: 175)
            }
          }
          alt
        }
        title
        caption
      }
    }
  }
`;

export const RevconSystemFeatures = ({
  title,
  disclaimer,
  cards,
}: RevconSystemFeaturesProps) => {
  return (
    <Section theme="base" sx={{ py: 10 }}>
      {/* have carousel go off page on mobile */}
      <Container pr={0}>
        <Heading
          variant="h4"
          sx={{
            textAlign: ['left', 'center'],
            fontWeight: 'medium',
            mb: [5, 6],
          }}
        >
          {title}
        </Heading>
        <Slider>
          {cards.map((card) => {
            return <CircleImageCarouselCard key={card.title} {...card} />;
          })}
        </Slider>
        <Paragraph variant="copyright" sx={{ color: 'darkGrey', pt: 4 }}>
          {disclaimer}
        </Paragraph>
      </Container>
    </Section>
  );
};
