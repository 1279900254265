import { ElementType, FC } from 'react';

import { Box, ThemeUIStyleObject } from '../components';

interface SpacerProps {
  direction?: 'horizontal' | 'vertical' | Array<'horizontal' | 'vertical'>;
  space?: number | string | Array<number | string | null>;
  as?: ElementType;
  sx?: ThemeUIStyleObject;
  className?: string;
}

export const Spacer: FC<SpacerProps> = ({
  space = 4,
  direction = 'vertical',
  as = 'div',
  sx,
  className,
}) => {
  return (
    <Box
      mt={direction === 'vertical' ? space : 0}
      mr={direction === 'horizontal' ? space : 0}
      as={as}
      sx={sx}
      className={className}
    />
  );
};
