import { PortableTextProps } from '@portabletext/react';
import { graphql } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import {
  Box,
  BreadCrumbs,
  Container,
  Flex,
  Grid,
  Heading,
  SEO,
  Section,
  Text,
  TextSpacer,
} from 'voom-gatsby';

import { ContentBlock, TrailingIcon } from '~components';

import { ReactComponent as PeriodIcon } from '~svg/icons/period.svg';

interface ArticleTemplateProps {
  data: {
    sanityVoomArticle: {
      Date: string;
      description: string;
      publication: string;
      title: string;
      heroImage?: {
        asset: {
          gatsbyImageData: ImageDataLike;
        };
        alt: string;
      };
      _rawContent?: PortableTextProps['value'];
    };
  };
}
const ArticleTemplate = ({ data }: ArticleTemplateProps) => {
  const article = data.sanityVoomArticle;
  const articleImage = getImage(article.heroImage?.asset ?? null);
  return (
    <>
      <Container
        sx={{
          ...(articleImage ? { pr: [0, 0, 0, 0, 0], pl: [0, 0, 0, 0, 6] } : {}),
        }}
      >
        <Grid columns={[1, null, null, null, 2]}>
          <Container variant="narrowContainer" sx={{ py: 8 }}>
            <Box>
              <BreadCrumbs />
              <Heading as="h1" variant="h2" sx={{ my: 5 }}>
                <TrailingIcon
                  as="span"
                  Icon={PeriodIcon}
                  sx={{
                    lineHeight: 'heading',
                    span: {
                      variant: 'styles.h2',
                      lineHeight: 'heading',
                      mr: 3,
                      fontWeight: 'bold',
                      svg: {
                        width: [6, 12],
                        height: [6, 12],
                        color: 'secondary',
                        bottom: '20%',
                        transform: 'translateX(-12px)',
                      },
                    },
                  }}
                >
                  {article.title}
                </TrailingIcon>
              </Heading>
              <Flex
                as="p"
                sx={{
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  mb: 3,
                  span: {
                    color: 'darkGrey',
                    fontSize: 'lg',
                    fontWeight: 'medium',
                  },
                }}
              >
                <Text>{article.Date}</Text>
                <TextSpacer sx={{ ':not(:last-child)::after': { mx: 3 } }} />
                <Text>{article.publication}</Text>
              </Flex>
            </Box>
          </Container>
          {articleImage && (
            <GatsbyImage
              image={articleImage}
              alt={article.heroImage?.alt ?? ''}
            />
          )}
        </Grid>
      </Container>
      <Section
        theme="base"
        sx={{
          width: 'full',
          background: (theme) => `${theme.colors?.tealGradient}`,
        }}
      >
        <Container variant="narrowContainer" sx={{ py: 8 }}>
          <Box sx={{ maxWidth: 750, mt: [5, null, 7] }}>
            {article._rawContent && (
              <ContentBlock content={article._rawContent} />
            )}
          </Box>
        </Container>
      </Section>
    </>
  );
};

export const Head = ({ data }: ArticleTemplateProps) => {
  return (
    <SEO
      title={data.sanityVoomArticle.title}
      description={data.sanityVoomArticle.description}
    />
  );
};

export const ARTICLE_TEMPLATE_QUERY = graphql`
  query ArticleTemplateQuery($sanityArticleId: String) {
    sanityVoomArticle(id: { eq: $sanityArticleId }) {
      Date(formatString: "MMMM DD, YYYY")
      publication
      title
      id
      description
      _rawContent
      heroImage {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: NONE
            aspectRatio: 0.76
          )
        }
        alt
      }
    }
  }
`;

export default ArticleTemplate;
