export const primary = {};

export const input = {
  boxShadow: 'formInputShadow',
  borderRadius: 'radius',
  p: 3,
  pt: 4,
  pb: 2,
};

export const textarea = {
  variant: 'forms.input',
};

export const autocompleteInput = {
  borderRadius: 60,
  width: 'full',
  height: 'full',
  pr: [6, null, 6],
  pl: [3, null, 4],
  bg: 'white',
  borderColor: 'transparent',
  boxShadow: 'formInputShadow',
};
