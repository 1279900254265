import { graphql, useStaticQuery } from 'gatsby';

import { Container, Grid, Paragraph, Section } from 'voom-gatsby';

import { FooterFormColumn } from '~sections/Footer/FooterFormColumn';
import { FooterInfoColumn } from '~sections/Footer/FooterInfoColumn';
import { FooterLegal } from '~sections/Footer/FooterLegal';
import { FooterLinksColumn } from '~sections/Footer/FooterLinksColumn';

import { ReactComponent as Logo } from '~svg/logo/voom-logo-with-tagline.svg';

export const Footer = (): JSX.Element => {
  const {
    siteSettings,
    contentJson: {
      sections: { footer },
    },
  } = useStaticQuery(FOOTER_QUERY);

  return (
    <Section as="footer" theme="blue" sx={{ bg: 'blue', width: 'full', pt: 8 }}>
      <Container>
        <Logo
          sx={{
            display: 'block',
            width: 230,
            height: 65,
            mx: ['auto', 0],
            color: 'logo',
            mb: 7,
          }}
        />
        <Grid
          sx={{
            gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)', 'auto'],
            gridRowGap: [5, null, null, 6],
            gridColumnGap: 0,
            alignItems: 'start',
          }}
        >
          <FooterLinksColumn links={footer.links} />
          <FooterInfoColumn siteSettings={siteSettings} />
          <FooterFormColumn emailForm={footer.emailForm} />
          <Paragraph
            sx={{
              color: 'grey',
              mb: [5, null, null, 8],
              fontSize: 'copyright',
              gridColumn: [1, '1 /span 4'],
              gridRow: [3, 2],
            }}
          >
            {siteSettings.footerDisclaimer}
          </Paragraph>
        </Grid>
      </Container>
      <FooterLegal legal={footer.bottomLinks} />
    </Section>
  );
};

export const FOOTER_QUERY = graphql`
  query Footer {
    siteSettings: sanitySiteSettings(
      _id: { eq: "c4b03ae9-68ee-4852-9ee5-010c514d00ba" }
    ) {
      email {
        display
        link
      }
      phoneNumber {
        display
        link
      }
      faxNumber {
        display
        link
      }
      socials {
        icon {
          svg
        }
        title
        URL
      }
      footerDisclaimer
    }
    contentJson(jsonId: { eq: "footer" }) {
      sections {
        footer {
          links {
            label
            url
          }
          contact {
            contactLinks {
              type
              label
              url
            }
          }
          social {
            links {
              label
              url
            }
          }
          emailForm {
            title
            caption
          }
          bottomLinks {
            title
            links {
              label
              url
            }
          }
        }
      }
    }
  }
`;
