import { graphql, useStaticQuery } from 'gatsby';

import {
  Box,
  BreadCrumbs,
  Container,
  Heading,
  SEO,
  Section,
  StyledSpan,
  Text,
} from 'voom-gatsby';

import { NewsPressList } from '~sections/NewsPressList';

import { TrailingIcon } from '~components';

import { ReactComponent as PeriodIcon } from '~svg/icons/period.svg';

const NewsEventsPage = () => {
  const {
    contentJson: { sections },
  } = useStaticQuery(NEWS_EVENTS_PAGE_QUERY);

  return (
    <Box>
      {sections.newsEventsHero && (
        <Container variant="narrowContainer" sx={{ py: 8 }}>
          <BreadCrumbs />
          <Heading as="h1" variant="h1" sx={{ maxWidth: 730, my: 5 }}>
            <TrailingIcon
              as="span"
              Icon={PeriodIcon}
              sx={{
                lineHeight: 'heading',
                span: {
                  variant: 'styles.h1',
                  lineHeight: 'heading',
                  mr: 3,
                  fontWeight: 'bold',
                  svg: {
                    width: [6, 12],
                    height: [6, 12],
                    color: 'secondary',
                    bottom: '20%',
                    transform: 'translateX(-12px)',
                  },
                },
              }}
            >
              {sections.newsEventsHero.title.replace('.', '')}
            </TrailingIcon>
          </Heading>
          <Text variant="largeP">
            <StyledSpan fontStyle="semiBold" searchWords={['Voom™']}>
              {sections.newsEventsHero.caption}
            </StyledSpan>
          </Text>
        </Container>
      )}
      <Section
        theme="base"
        sx={{
          width: 'full',
          background: (theme) => `${theme.colors?.tealGradient}`,
        }}
      >
        <NewsPressList sx={{ backgroundColor: 'transparent', py: 9 }} />
      </Section>
    </Box>
  );
};

export const Head = () => {
  return (
    <SEO
      title="News + Events | Voom™ Medical Devices | Leading Innovations in Minimally Invasive Bunion Surgery (MIBS)"
      description="Learn how Voom™ is raising industry standards, advancing professional discourse and expanding access to clinical innovations in minimally invasive bunion surgery (MIBS)."
    />
  );
};

export const NEWS_EVENTS_PAGE_QUERY = graphql`
  query NewsEventsPage {
    contentJson(jsonId: { eq: "news-events" }) {
      sections {
        newsEventsHero {
          title
          caption
        }
      }
    }
  }
`;

export default NewsEventsPage;
