import { graphql, useStaticQuery } from 'gatsby';

import { Box, SEO } from 'voom-gatsby';

import { HomeCarousel } from '~sections/HomeCarousel';
import { HomeHero } from '~sections/HomeHero';
import { HomeSideBySide } from '~sections/HomeSideBySide';
import { PatternCallout } from '~sections/PatternCallout';

const HomePage = () => {
  const {
    contentJson: { sections },
  } = useStaticQuery(HOME_PAGE_QUERY);
  return (
    <Box sx={{ bg: 'background' }}>
      {sections.homeHero && <HomeHero {...sections.homeHero} />}
      {sections.homeCarousel && <HomeCarousel {...sections.homeCarousel} />}
      {sections.patternCallout && (
        <PatternCallout {...sections.patternCallout} />
      )}
      {sections.homeSideBySide && (
        <HomeSideBySide {...sections.homeSideBySide} />
      )}
    </Box>
  );
};

export const Head = () => {
  return (
    <SEO
      title="Home | Voom™ Medical Devices | Minimally Invasive Bunion Surgery (MIBS)"
      description="Voom™ is a revolutionary medical device company dedicated to advancing fusion-free, minimally invasive bunion surgery (MIBS) solutions for surgeons and their patients."
    />
  );
};

export const HOME_PAGE_QUERY = graphql`
  query HomePage {
    contentJson(jsonId: { eq: "home" }) {
      sections {
        ...homeHeroFields
        ...homeCarouselFields
        ...patternCalloutFields
        ...homeSideBySideFields
      }
    }
  }
`;

export default HomePage;
