export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

export const PINK = '#C624FF';
export const PURPLE = '#9124FF';
export const RED = '#E42A61';
export const BUNIONPLASTY_PINK = '#DA1B85';

export const TEAL = '#30CDFF';
export const TEAL_2 = '#80D3FF';
export const TEAL_3 = '#DFEFF8';

export const BLUE = '#0C122C';
export const BLUE_2 = '#181F3D';
export const BLUE_3 = '#232C51';

export const LIGHT_GREY = '#DBDDE5';
export const GREY = '#B8B9BC';
export const DARK_GREY = '#707488';

export const TEAL_GRADIENT = `linear-gradient(0deg, 
    rgba(232,241,246,0) 0%, 
    #DFEFF8 100%)`;

export const LEFT_FADE_GRADIENT = `linear-gradient(to left, 
    rgba(245, 246, 252, 0.8),  
    rgba(245, 246, 252, 0.00))`;

export const RIGHT_FADE_GRADIENT = `linear-gradient(to right, 
    rgba(245, 246, 252, 0.8),  
    rgba(245, 246, 252, 0.00))`;

export const HOME_SIDE_BY_SIDE_FADE_GRADIENT = `linear-gradient(to right, 
    rgba(245, 246, 252, 1),  
    rgba(245, 246, 252, 0.75) 80%,   
    rgba(245, 246, 252, 0.00) 100%)`;

export const HOME_SIDE_BY_SIDE_FADE_MOBILE_GRADIENT = `linear-gradient(to bottom, 
    rgba(255, 255, 255, 1), 
    rgba(255, 255, 255, 1) 20%,  
    rgba(255, 255, 255, 0.8) 30%,
    rgba(255, 255, 255, 0.3) 50%, 
    rgba(245, 246, 252, 0.0) 100%)`;

export const TRAIN_CAROUSEL_GRADIENT =
  'linear-gradient(90deg, #1A1A49 37.2%, rgba(26, 26, 73, 0.00) 71.13%), linear-gradient(287deg, rgba(145, 36, 255, 0.20) -1%, rgba(145, 36, 255, 0.00) 45.21%), linear-gradient(0deg, rgba(24, 31, 61, 0.50) 0%, rgba(24, 31, 61, 0.50) 100%)';

export const TRAIN_CAROUSEL_GRADIENT_MOBILE =
  'linear-gradient(180deg, #1A1A49 50%, rgba(26, 26, 73, 0.00) 74.02%), linear-gradient(287deg, rgba(145, 36, 255, 0.20) -1%, rgba(145, 36, 255, 0.00) 45.21%), linear-gradient(0deg, rgba(24, 31, 61, 0.50) 0%, rgba(24, 31, 61, 0.50) 100%)';

export const DROP_SHADOW = 'rgba(24,30,60,0.25)';

export const BOX_SHADOW = 'rgba(0, 0, 0, 0.10)';

export const BLUE_GRADIENT = `linear-gradient(to bottom, rgba(24,31,61, 1), 
    rgba(24,31,61, 0.75) 25%,  
    rgba(24,31,61, 0.50) 50%,
    rgba(24,31,61, 0.25) 75%, 
    rgba(24,31,61, 0.0) 100%)`;

export const TEAL_GRADIENT_DIAGONAL = `linear-gradient(226deg, #E8F4FA 0%, #CFE6F3 99.97%)`;
