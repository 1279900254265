import { createFontFace } from '../createFontFace';
import MontserratBoldWoffSrc from './Montserrat-Bold.woff';
import MontserratBoldWoff2Src from './Montserrat-Bold.woff2';
import MontserratMediumWoffSrc from './Montserrat-Medium.woff';
import MontserratMediumWoff2Src from './Montserrat-Medium.woff2';
import MontserratSemiBoldWoffSrc from './Montserrat-SemiBold.woff';
import MontserratSemiBoldWoff2Src from './Montserrat-SemiBold.woff2';

export const MontserratMedium = createFontFace({
  fontFamily: 'Montserrat',
  fontWeight: 400,
  fontStyle: 'normal',
  fallback: 'sans-serif',
  fontUrls: [
    { src: MontserratMediumWoff2Src, format: 'woff2' },
    { src: MontserratMediumWoffSrc, format: 'woff' },
  ],
  preload: true,
});

export const MontserratSemiBold = createFontFace({
  fontFamily: 'Montserrat',
  fontWeight: 500,
  fontStyle: 'normal',
  fallback: 'sans-serif',
  fontUrls: [
    { src: MontserratSemiBoldWoff2Src, format: 'woff2' },
    { src: MontserratSemiBoldWoffSrc, format: 'woff' },
  ],
  preload: true,
});

export const MontserratBold = createFontFace({
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontStyle: 'normal',
  fallback: 'sans-serif',
  fontUrls: [
    { src: MontserratBoldWoff2Src, format: 'woff2' },
    { src: MontserratBoldWoffSrc, format: 'woff' },
  ],
  preload: true,
});
