import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import { Flex, Text, ThemeUIStyleObject } from 'voom-gatsby';

export interface CircleImageCarouselCardProps {
  image: {
    src: ImageDataLike;
    alt: string;
  };
  title: string;
  caption: string;
  sx?: ThemeUIStyleObject;
  className?: string;
}

export const CircleImageCarouselCard = ({
  image,
  title,
  caption,
  sx,
  className,
}: CircleImageCarouselCardProps) => {
  const imageData = getImage(image.src);

  return (
    <Flex
      className={className}
      sx={{
        pr: 4,
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: 'full',
        ':after': {
          content: '""',
          position: 'absolute',
          top: 0,
          mx: 3,
          right: (theme) => `${(theme.space?.[4] as number) / 2}px`,
          width: 2,
          height: 'full',
          bg: 'muted',
        },
        ...sx,
      }}
    >
      {imageData && (
        <GatsbyImage image={imageData} alt={image.alt} sx={{ mb: [3, 4] }} />
      )}
      <Text as="p" sx={{ maxWidth: 270, mr: 4 }}>
        <Text variant="largeP" sx={{ fontWeight: 'semiBold' }}>
          {title}
        </Text>{' '}
        <Text variant="largeP" sx={{}}>
          {caption}
        </Text>
      </Text>
    </Flex>
  );
};
