import { ReactNode } from 'react';
import Highlighter from 'react-highlight-words';

import { Text, ThemeUIStyleObject } from '../components';

interface StyledSpanProps {
  children: string;
  searchWords: string[];
  fontStyle?: 'semiBold' | 'secondary' | 'secondaryH1' | 'sup';
  sx?: ThemeUIStyleObject;
  className?: string;
}

const checkForSup = (text: string) => {
  return [...text].map((letter) => {
    if (letter === '™' || letter === '®') {
      return (
        <Text
          key={letter}
          as="sup"
          sx={{
            verticalAlign: 'super',
            fontSize: '65%',
          }}
        >
          {letter}
        </Text>
      );
    } else {
      return letter;
    }
  });
};

const SemiBold = ({
  children,
}: {
  children: string;
  highlightIndex: number;
}) => (
  <Text sx={{ fontWeight: 'semiBold', sup: { fontWeight: 'semiBold' } }}>
    {checkForSup(children)}
  </Text>
);

const Secondary = ({
  children,
}: {
  children: string;
  highlightIndex: number;
}) => (
  <Text
    sx={{
      color: 'secondary',
      fontWeight: 'semiBold',
      sup: { color: 'secondary', fontWeight: 'semiBold' },
    }}
  >
    {checkForSup(children)}
  </Text>
);

const SecondaryH1 = ({
  children,
}: {
  children: string;
  highlightIndex: number;
}) => {
  return (
    <Text
      sx={{
        variant: 'styles.h1',
        color: 'secondary',
        fontWeight: 'semiBold',
        sup: { color: 'secondary', fontWeight: 'semiBold' },
      }}
    >
      {checkForSup(children)}
    </Text>
  );
};

const Sup = ({ children }: { children: string; highlightIndex: number }) => (
  <Text
    as="sup"
    sx={{ color: 'inherit', verticalAlign: 'super', fontSize: '65%' }}
  >
    {children}
  </Text>
);

export const StyledSpan = ({
  children,
  searchWords,
  fontStyle = 'semiBold',
  sx,
  className,
}: StyledSpanProps): JSX.Element => {
  const highlightTag =
    fontStyle === 'secondary'
      ? Secondary
      : fontStyle === 'secondaryH1'
        ? SecondaryH1
        : fontStyle === 'sup'
          ? Sup
          : SemiBold;
  return (
    <Highlighter
      textToHighlight={children}
      searchWords={searchWords}
      highlightTag={highlightTag}
      autoEscape={true}
      sx={{ ...sx, font: 'inherit' }}
      className={className}
    />
  );
};

export const StyledSup = ({
  children,
  sx,
}: {
  children: ReactNode | string;
  sx?: ThemeUIStyleObject;
}) => {
  const isString = typeof children === 'string';

  return isString ? (
    <StyledSpan
      // Voom theme fonts need tm to also be superscript
      searchWords={['®', ...(process.env.THEME === 'voom' ? ['™'] : [])]}
      fontStyle="sup"
      sx={sx}
    >
      {String(children)}
    </StyledSpan>
  ) : (
    (children as JSX.Element)
  );
};
