export const primary = {};

export const input = {
  borderColor: 'lightGrey',
  borderRadius: 'square',
  p: 3,
  pt: 4,
  pb: 2,
};

export const textarea = {
  variant: 'forms.input',
};
