import {
  PortableText,
  PortableTextProps,
  PortableTextReactComponents,
} from '@portabletext/react';
import { ReactNode } from 'react';

import { Heading, Link, Text } from 'voom-gatsby/src/components';

interface ContentBlockProps {
  content: PortableTextProps['value'];
}

// TODO: Resolve ContentBlock duplication
export const marks = {
  em: ({ children }: { children: ReactNode }) => (
    <Text variant="legalEm" as="em">
      {children}
    </Text>
  ),
  sup: ({ children }: { children: ReactNode }) => (
    <Text as="sup">{children}</Text>
  ),
  strong: ({ children }: { children: ReactNode }) => (
    <Text as="strong" sx={{ fontWeight: 'semibold' }}>
      {children}
    </Text>
  ),
  link: ({
    children,
    value,
  }: {
    children: ReactNode;
    value?: { href: string };
  }) => (
    <Link to={value?.href ?? '#'} variant="legal">
      {children}
    </Link>
  ),
};

export const contentBlockComponents: Partial<PortableTextReactComponents> = {
  block: {
    normal: ({ children }) => (
      <Text as="p" variant="legalCaption">
        {children}
      </Text>
    ),
    h4: ({ children }) => <Heading variant="legalHeading">{children}</Heading>,
  },
  marks: marks,
  listItem: ({ children }) => (
    <Text as="li" variant="legalCaption">
      {children}
    </Text>
  ),
  list: ({ children }) => (
    <Text as="ul" variant="legalUl">
      {children}
    </Text>
  ),
};

export const ContentBlock = ({ content }: ContentBlockProps) => {
  return <PortableText value={content} components={contentBlockComponents} />;
};
