import * as FontFaces from '../../fonts/voom/FontFaces';
import * as colors from './colors';
import * as styles from './styles';
import * as forms from './variants/forms';
import * as layout from './variants/layouts';
import * as links from './variants/links';
import * as text from './variants/text';
import * as buttons from './variants/variants';

export default {
  useLocalStorage: false,
  useColorSchemeMediaQuery: false,

  colors: {
    background: colors.WHITE,
    text: colors.BLACK,
    link: colors.PINK,
    nav: colors.BLACK,
    navSticky: colors.BLACK,
    buttonBg: colors.PINK,
    buttonColor: colors.WHITE,
    logo: colors.BLUE_3,
    stickyLogo: colors.BLUE_3,
    primary: colors.PINK,
    secondary: colors.PURPLE,
    accent: colors.TEAL,
    accentSecondary: colors.BLUE,
    muted: colors.LIGHT_GREY,
    error: colors.RED,
    social: colors.BLACK,

    white: colors.WHITE,
    black: colors.BLACK,
    pink: colors.PINK,
    bunionplasty: colors.BUNIONPLASTY_PINK,
    purple: colors.PURPLE,
    red: colors.RED,
    teal: colors.TEAL,
    teal2: colors.TEAL_2,
    teal3: colors.TEAL_3,
    blue: colors.BLUE,
    blue2: colors.BLUE_2,
    blue3: colors.BLUE_3,
    lightGrey: colors.LIGHT_GREY,
    grey: colors.GREY,
    darkGrey: colors.DARK_GREY,
    tealGradient: colors.TEAL_GRADIENT,
    tealGradientDiagonal: colors.TEAL_GRADIENT_DIAGONAL,
    leftFadeGradient: colors.LEFT_FADE_GRADIENT,
    rightFadeGradient: colors.RIGHT_FADE_GRADIENT,
    homeSideBySideGradient: colors.HOME_SIDE_BY_SIDE_FADE_GRADIENT,
    homeSideBySideMobileGradient: colors.HOME_SIDE_BY_SIDE_FADE_MOBILE_GRADIENT,
    blueGradient: colors.BLUE_GRADIENT,
    certifiedBg: colors.LIGHT_GREY,
    trainCarouselGradient: colors.TRAIN_CAROUSEL_GRADIENT,
    trainCarouselGradientMobile: colors.TRAIN_CAROUSEL_GRADIENT_MOBILE,
  },

  fonts: {
    heading: String(FontFaces.MontserratBold),
    headingSecondary: String(FontFaces.MontserratSemiBold),
    body: String(FontFaces.MontserratMedium),
    button: String(FontFaces.MontserratSemiBold),
    link: String(FontFaces.MontserratSemiBold),
  },

  fontSizes: {
    copyright: 12,
    xs: 14,
    sm: 16,
    md: 18,
    lg: 21,
    xl: 24,
    '2xl': 32,
    '3xl': 40,
    '4xl': 48,
    '5xl': 70,
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    semibold: 600,

    body: 400,
    heading: 600,
  },

  lineHeights: {
    body: 1.5,
    bodyNarrow: 1.44,
    heading: 1.2,
    headingLarge: 1.25,
    headingXL: 1.33,
  },

  letterSpacings: {
    0: -2,
    1: -1,
    2: -0.5,
    3: -0.25,
    4: -0.1,
    5: 0,
    6: 1,
    7: 2,
    8: 3,
  },

  space: {
    0: 0,
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
    8: 88,
    9: 104,
    10: 128,
  },

  sizes: {
    wideContainer: 1800,
    container: 1350,
    narrowContainer: 1225,
    full: '100%',
    screenWidth: '100vw',
    screenHeight: '100vh',
    navHeight: 125,
    stickyNavHeight: 80,
  },

  breakpoints: ['480px', '768px', '1024px', '1600px'],

  radii: {
    square: 0,
    radius: 4,
    corner: 10,
    rounded: 50,
    circle: '100%',
  },

  zIndices: {
    background: -1,
    content: 0,
    sticky: 1,
    overlay: 2,
    nav: 5,
  },

  shadows: {
    navShadow: '0 15px 15px -15px rgba(0, 0, 0, 0.10)',
    dropShadow: '0 25px 45px 0 rgba(24,30,60,0.25)',
    featureRowShadow: '0 25px 45px 0 rgba(0, 0, 0, 0.10)',
    preFooterShadow: '0 30px 30px 0 #000000',
    tableShadow: '0px 0px 25px 0px rgba(0, 0, 0, 0.10)',
    twoScrewsShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.10)',
    trainShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.25)',
    certifiedBadgeShadow: '0px 5px 10px rgba(4, 0, 13, 0.3)',
    calendlyShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.25)',
    formSelectShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.2)',
  },

  styles,
  buttons,
  forms,
  layout,
  links,
  text,
} as const;
