import { graphql } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';
import { useForm } from 'react-hook-form';
import {
  Honeypot,
  NetlifyFormComponent,
  NetlifyFormProvider,
  useNetlifyForm,
} from 'react-netlify-forms';

import {
  Box,
  Button,
  Flex,
  FormInput,
  Grid,
  Heading,
  Paragraph,
  Section,
  Spacer,
  StyledSup,
  Text,
  useDefaultFormFields,
} from 'voom-gatsby';

interface BecomeASurgeonFormProps {
  title: string;
  caption: string;
}

export const BecomeASurgeonForm = ({
  title,
  caption,
}: BecomeASurgeonFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const netlify = useNetlifyForm({
    name: 'Voom Become A Surgeon form',
    action: '/thanks',
    honeypotName: 'bot-field',
    onSuccess: () => {
      if (window.gtag) {
        window.gtag('event', 'Form Submission - Become a Surgeon', {});
      }
      reset();
    },
  });

  const DefaultFormFields = useDefaultFormFields(register, reset);

  const onSubmit = (data: unknown) => {
    netlify.handleSubmit(null, data);
  };

  const stretchStyling = {
    gridColumn: ['span 2', 'span 1', null, 'span 2', 'span 1'],
  };

  return (
    <Box sx={{ py: [7, null, null, 0], px: [3, 5, 6, null, 9] }}>
      <NetlifyFormProvider {...netlify}>
        <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
          <Heading variant="h2">{title}</Heading>
          <Spacer space={5} />
          {netlify.success ? (
            <Paragraph variant="largeP" sx={{ fontSize: ['sm', null, 'md'] }}>
              {`Thank you for your interest in joining our team. We've received
              your information, and will contact you soon with next steps.`}
            </Paragraph>
          ) : (
            <>
              <Paragraph variant="largeP" sx={{ fontSize: ['sm', null, 'md'] }}>
                <StyledSup>{caption}</StyledSup>
              </Paragraph>
              <Honeypot />
              <Grid columns={2} sx={{ rowGap: 0 }}>
                <FormInput
                  label="First Name*"
                  name="firstName"
                  register={register('firstName', { required: true })}
                  errors={errors.firstName}
                  control={control}
                />
                <FormInput
                  label="Last Name*"
                  name="lastName"
                  register={register('lastName', { required: true })}
                  errors={errors.lastName}
                  control={control}
                />
                <FormInput
                  label="State*"
                  name="state"
                  register={register('state', { required: true })}
                  errors={errors.state}
                  control={control}
                  sx={{ ...stretchStyling }}
                />
                <FormInput
                  label="Practice Name*"
                  name="practiceName"
                  register={register('practiceName', { required: true })}
                  errors={errors.practiceName}
                  control={control}
                  sx={{ ...stretchStyling }}
                />
                <FormInput
                  label="Phone Number*"
                  name="phoneNumber"
                  type="tel"
                  register={register('phoneNumber', { required: true })}
                  errors={errors.phoneNumber}
                  control={control}
                  sx={{ ...stretchStyling }}
                />
                <FormInput
                  label="Email Address*"
                  name="email"
                  type="email"
                  register={register('email', { required: true })}
                  errors={errors.email}
                  control={control}
                  sx={{ ...stretchStyling }}
                />
              </Grid>
              <DefaultFormFields />
              <Flex
                sx={{
                  flexDirection: ['column', 'row'],
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  mt: 4,
                }}
              >
                <Button
                  type="submit"
                  variant="inverted"
                  sx={{
                    appearance: 'none',
                    opacity: 1,
                    width: ['full', 'auto'],
                    mb: [3, 0],
                  }}
                >
                  Submit
                </Button>
                <Spacer direction="horizontal" />
                <Text sx={{ variant: 'text.copyright', color: 'darkGrey' }}>
                  *Required Form Fields
                </Text>
              </Flex>
              {netlify.error && (
                <Paragraph
                  variant="largeP"
                  sx={{ fontSize: ['sm', null, 'md'], color: 'red', py: 4 }}
                >
                  Sorry, we could not reach servers. Please try again later. If
                  this problem continues, please contact us. Thanks
                </Paragraph>
              )}
            </>
          )}
        </NetlifyFormComponent>
      </NetlifyFormProvider>
    </Box>
  );
};

export const becomeASurgeonFields = graphql`
  fragment becomeASurgeonFields on ContentJsonSections {
    becomeASurgeon {
      title
      caption
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        alt
      }
      mobileImage {
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        alt
      }
    }
  }
`;

interface BecomeASurgeonProps {
  eyebrow: string;
  title: string;
  caption: string;
  image: {
    src: ImageDataLike;
    alt: string;
  };
  mobileImage: {
    src: ImageDataLike;
    alt: string;
  };
}
export const BecomeASurgeon = ({
  title,
  caption,
  image,
  mobileImage,
}: BecomeASurgeonProps) => {
  const imageData = getImage(image.src);
  const mobileImageData = getImage(mobileImage.src);

  return (
    <Section theme="blue">
      <Grid
        sx={{
          gridTemplateColumns: ['1fr', null, null, '1fr 1fr', '5fr 4fr'],
          alignItems: 'center',
          py: [0, null, null, 170],
          gridGap: 0,
        }}
      >
        {/* Force image to right on desktop */}
        <Box sx={{ order: [null, null, null, 2] }}>
          {imageData && (
            <GatsbyImage
              image={imageData}
              alt={image.alt}
              sx={{
                display: [
                  'none !important',
                  null,
                  null,
                  'inline-block !important',
                ],
                width: 'full',
              }}
            />
          )}
          {mobileImageData && (
            <GatsbyImage
              image={mobileImageData}
              alt={mobileImage.alt}
              sx={{
                display: [
                  'inline-block !important',
                  null,
                  null,
                  'none !important',
                ],
                width: 'full',
              }}
            />
          )}
        </Box>
        <BecomeASurgeonForm title={title} caption={caption} />
      </Grid>
    </Section>
  );
};
