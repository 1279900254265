export const root = {
  variant: 'text.body',
  fontSize: 'md',
};

export const Container = {
  variant: 'layout.container',
};

export const h1 = {
  variant: 'text.heading',
  fontSize: ['3xl', null, '4xl'],
};

export const h2 = {
  variant: 'text.heading',
  fontSize: ['2xl', null, '4xl'],
};

export const h3 = {
  variant: 'text.heading',
  fontSize: ['xl', null, '3xl'],
};

export const h4 = {
  variant: 'text.heading',
  fontSize: ['xl', null, '2xl'],
};

export const h5 = {
  variant: 'text.heading',
  fontSize: ['lg', null, 'xl'],
};

export const h6 = {
  variant: 'text.heading',
  fontSize: ['md', null, 'lg'],
};

export const p = {
  variant: 'text.body',
  fontSize: ['sm', null, 'md'],
};

export const a = {
  variant: 'links.text',
};

export const pre = {
  fontSize: ['md'],
};

export const img = {
  maxWidth: '100%',
};

export const hr = {
  borderBottom: '2px solid',
  borderColor: 'muted',
  opacity: 0.2,
};
