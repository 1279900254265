import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

/** Use Buy Box Out Of View
 *
 * This hook provides a boolean to determine if an Element is completely out of View
 *
 * @returns {RefObject} ref
 * @returns {boolean} isOutOfView
 */

export const useElementOutOfView = () => {
  const { ref, entry } = useInView();
  const [isOutOfView, setIsOutOfView] = useState(false);
  const rect = entry?.boundingClientRect;
  useEffect(() => {
    if (!rect) return;
    const elementOutOfView = rect.bottom < 0;
    setIsOutOfView(elementOutOfView);
  }, [rect, setIsOutOfView]);

  return { ref, isOutOfView };
};
