import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import {
  Container,
  Flex,
  Grid,
  Heading,
  Link,
  Section,
  Spacer,
  Text,
} from 'voom-gatsby';

import {
  TrainDoctor,
  TrainDoctorProps,
  TrainIcon,
  TrainIconProps,
} from '~components';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';

export interface TrainLocationsProps {
  eyebrow: string;
  title: string;
  cards: TrainCardProps[];
}

export const TrainLocations = ({
  eyebrow,
  title,
  cards,
}: TrainLocationsProps) => {
  return (
    <Section
      theme="base"
      sx={{
        py: [6, 8],
        bg: 'transparent',
      }}
    >
      <Container variant="narrowContainer">
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            mx: 'auto',
          }}
        >
          <CrossIcon sx={{ width: 32, height: 32, mb: 3 }} />
          <Text variant="h5" sx={{ my: 3, textAlign: 'center' }}>
            {eyebrow}
          </Text>
          <Heading
            variant="h2"
            sx={{
              textAlign: 'center',
            }}
          >
            {title}
          </Heading>
        </Flex>
        <Spacer space={7} />
        <Grid columns={[1, null, null, 3]} gap={40}>
          {cards.map((card) => {
            return <TrainCard key={card.doctor} {...card} />;
          })}
        </Grid>
      </Container>
    </Section>
  );
};

interface TrainCardProps {
  icon: TrainIconProps['icon'];
  image: {
    src: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    alt: string;
  };
  doctor: TrainDoctorProps['doctor'];
  link: {
    url: string;
    label: string;
  };
}

export const TrainCard = ({ image, icon, doctor, link }: TrainCardProps) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderBottom: (theme) => [
          `1px solid ${theme.colors?.lightGrey}`,
          null,
          null,
          'none',
        ],
        pb: [40, null, null, 0],
        borderRight: (theme) => [
          null,
          null,
          null,
          `1px solid ${theme.colors?.lightGrey}`,
        ],
        pr: [null, null, null, 40],
      }}
    >
      <TrainIcon
        icon={icon}
        sx={{ maxWidth: 'full', height: [55, 75, 80, 55], color: 'purple' }}
      />
      <Spacer space={3} />
      <GatsbyImage
        image={image.src.childImageSharp.gatsbyImageData}
        alt=""
        sx={{ width: 'full' }}
      />
      <Spacer />
      <TrainDoctor doctor={doctor} />
      <Spacer />
      <Link
        variant="buttonOutline"
        to={link.url}
        sx={{ width: ['full', 'unset'] }}
      >
        {link.label}
      </Link>
    </Flex>
  );
};

export const trainLocationsFields = graphql`
  fragment trainLocationsFields on ContentJsonSections {
    trainLocations {
      eyebrow
      title
      cards {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          alt
        }
        icon
        doctor
        link {
          label
          url
        }
      }
    }
  }
`;
