import {
  ElementType,
  FunctionComponent,
  ReactNode,
  SVGAttributes,
} from 'react';

import { Text, ThemeUIStyleObject } from 'voom-gatsby';

interface TrailingIconProps {
  children: ReactNode;
  as?: ElementType;
  Icon: FunctionComponent<SVGAttributes<SVGElement>>;
  sx?: ThemeUIStyleObject;
  className?: string;
}

export const TrailingIcon = ({
  children,
  as = 'p',
  Icon,
  sx,
  className,
}: TrailingIconProps) => {
  return (
    <Text as={as} className={className} sx={{ ...sx }}>
      <Text as="span">{children}</Text>
      <Text
        // force icon to align after last character of title
        sx={{ display: 'inline', whiteSpace: 'nowrap', position: 'relative' }}
      >
        &nbsp;
        <Icon sx={{ position: 'absolute', left: 0, bottom: 0, ml: 1 }} />
      </Text>
    </Text>
  );
};
