import { DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { FC, ReactNode } from 'react';

import { Button, ThemeUIStyleObject } from '../components';
import { CloseSvg } from '../svg/icons';

export const overlayVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const contentVariant = {
  initial: {
    scale: 1.1,
  },
  animate: {
    scale: 1,
    transition: { min: 0, max: 100, bounceDamping: 9 },
  },
  exit: {
    scale: 1.2,
    transition: { min: 0, max: 100, bounceDamping: 9 },
  },
};

export interface ModalProps {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  // used to override the default close behavior
  // like when the modal shouldn't closed on overlay click
  // learn more: https://reach.tech/dialog/#dialog-ondismiss
  sx?: ThemeUIStyleObject;
  overlayStyles?: ThemeUIStyleObject;
  className?: string;
  showCloseButton?: boolean;
  children: ReactNode;
}

const MotionDialogOverlay = motion(DialogOverlay);
const MotionDialogContent = motion(DialogContent);

export const Modal: FC<ModalProps> = ({
  sx = {},
  overlayStyles = {},
  className,
  isOpen,
  close,
  showCloseButton = true,
  children,
}) => {
  return (
    <LayoutGroup>
      <AnimatePresence>
        {isOpen && (
          <MotionDialogOverlay
            // TODO: solve a better way to do this. Needed to prevent scroll to top of page.
            dangerouslyBypassFocusLock={true}
            isOpen={isOpen}
            onDismiss={close}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={overlayVariants}
            sx={{ variant: 'modals.overlay', ...overlayStyles }}
            css={{
              // use fallback for mobile height
              '&[data-reach-dialog-overlay]': {
                height: ['100vh', '100dvh'],
              },
            }}
          >
            {showCloseButton && (
              <Button
                variant="icon"
                onClick={close}
                sx={{
                  position: 'fixed',
                  top: 2,
                  right: 2,
                  zIndex: 'nav',
                  bg: 'white',
                  svg: {
                    width: 16,
                    height: 16,
                  },
                }}
              >
                <CloseSvg />
              </Button>
            )}
            <MotionDialogContent
              aria-label="Gallery Modal"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={contentVariant}
              className={className}
              sx={{
                variant: 'modals.content',
                ...sx,
              }}
            >
              {children}
            </MotionDialogContent>
          </MotionDialogOverlay>
        )}
      </AnimatePresence>
    </LayoutGroup>
  );
};
