import { graphql, useStaticQuery } from 'gatsby';

import { Box, SEO } from 'voom-gatsby';

import { BeforeAfter } from '~sections/BeforeAfter';
import { BunionplastySideBySideBottom } from '~sections/BunionplastySideBySideBottom';
import { BunionplastySideBySideTop } from '~sections/BunionplastySideBySideTop';
import { BunionplastyTrio } from '~sections/BunionplastyTrio';
import { HeroSideBySide } from '~sections/HeroSideBySide';
import { PatternCallout } from '~sections/PatternCallout';
import { TopFeature } from '~sections/TopFeature';

const BunionplastyPage = () => {
  const {
    contentJson: { sections },
  } = useStaticQuery(BUNIONPLASTY_PAGE_QUERY);

  return (
    <Box>
      {sections.hero && <HeroSideBySide {...sections.hero} />}
      {sections.topFeature && <TopFeature {...sections.topFeature} />}
      {sections.bunionplastySideBySideTop && (
        <BunionplastySideBySideTop {...sections.bunionplastySideBySideTop} />
      )}
      {sections.bunionplastyTrio && (
        <BunionplastyTrio {...sections.bunionplastyTrio} />
      )}
      {sections.beforeAfter && <BeforeAfter {...sections.beforeAfter} />}
      {sections.bunionplastySideBySideBottom && (
        <BunionplastySideBySideBottom
          {...sections.bunionplastySideBySideBottom}
        />
      )}
      {sections.patternCallout && (
        <PatternCallout {...sections.patternCallout} />
      )}
    </Box>
  );
};

export const Head = () => {
  return (
    <SEO
      title="Bunionplasty® 360 Bunion Repair™ | Voom™ Medical Devices | Patent-Pending Minimally Invasive Surgery"
      description="The Bunionplasty® procedure is a breakthrough advancement in fusion-free minimally invasive bunion surgery (MIBS), improving OR efficiency and allowing for same-day walking recovery."
    />
  );
};

export const BUNIONPLASTY_PAGE_QUERY = graphql`
  query BunionplastyPage {
    contentJson(jsonId: { eq: "bunionplasty" }) {
      sections {
        ...heroFields
        ...topFeatureFields
        ...bunionplastySideBySideTopFields
        ...bunionplastyTrioFields
        ...beforeAfterFields
        ...bunionplastySideBySideBottomFields
        ...patternCalloutFields
      }
    }
  }
`;

export default BunionplastyPage;
