import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
  Box,
  Container,
  Heading,
  IntrinsicBox,
  SEO,
  StyledSpan,
} from 'voom-gatsby';

import { ContactUsForm } from '~sections/ContactUsForm';
import { PreFooterSideBySide } from '~sections/PreFooterSideBySide';

const ContactUsPage = () => {
  const {
    contentJson: { sections },
  } = useStaticQuery(CONTACT_US_PAGE_QUERY);
  const imageData = getImage(sections.contactUsHero.image.src);

  return (
    <Box>
      {sections.contactUsHero.title && (
        <Container variant="narrowContainer">
          <Heading as="h1" variant="h1" sx={{ my: 7, maxWidth: [400, 700] }}>
            <StyledSpan searchWords={['Voom™', '?']} fontStyle="secondaryH1">
              {sections.contactUsHero.title}
            </StyledSpan>
          </Heading>
        </Container>
      )}
      <IntrinsicBox
        ratio={[375 / 400, null, 1441 / 600]}
        sx={{
          position: 'relative',
          overflow: 'hidden',
          width: 'full',
          height: 'full',
          mt: 8,
        }}
      >
        {imageData && (
          <GatsbyImage
            image={imageData}
            loading="eager"
            alt={sections.contactUsHero.title}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: 'full',
              height: 'full',
            }}
          />
        )}
      </IntrinsicBox>
      {sections.contactUsForm && <ContactUsForm {...sections.contactUsForm} />}
      {sections.preFooterSideBySide && (
        <PreFooterSideBySide {...sections.preFooterSideBySide} />
      )}
    </Box>
  );
};

export const Head = () => {
  return (
    <SEO
      title="Contact Us | Voom™ Medical Devices | Media + Investor Inquiries"
      description="See what it means to Voom™ forward with a groundbreaking orthopedic medical device company specializing in innovative solutions for fusion-free minimally invasive bunion surgery (MIBS)."
    />
  );
};

export const CONTACT_US_PAGE_QUERY = graphql`
  query ContactUsPage {
    contentJson(jsonId: { eq: "contact-us" }) {
      sections {
        contactUsHero {
          title
          image {
            src {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
              }
            }
            alt
          }
        }
        ...contactUsFormFields
        ...preFooterSideBySideFields
      }
    }
  }
`;

export default ContactUsPage;
