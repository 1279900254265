import { useEffect, useState } from 'react';

export const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const isBrowser = typeof window !== 'undefined';
  useEffect(() => {
    if (
      isBrowser &&
      ('ontouchstart' in window || navigator.maxTouchPoints > 0)
    ) {
      setIsTouchDevice(true);
    }
  }, [isBrowser]);

  return isTouchDevice;
};
