export const root = {
  variant: 'text.body',
  fontSize: 'sm',
};

export const Container = {
  variant: 'layout.container',
};

export const h1 = {
  variant: 'text.heading',
  fontSize: ['3xl', '5xl'],
  letterSpacing: 0,
};

export const h2 = {
  variant: 'text.heading',
  fontWeight: 'semiBold',
  fontSize: ['2xl', '4xl'],
  letterSpacing: 1,
};

export const h3 = {
  variant: 'text.heading',
  fontWeight: 'semiBold',
  fontSize: ['xl', '3xl'],
  letterSpacing: 1,
  lineHeight: 'headingLarge',
};

export const h4 = {
  variant: 'text.heading',
  fontWeight: 'medium',
  fontSize: ['xl', '2xl'],
  letterSpacing: 1,
  lineHeight: 'headingLarge',
};

export const h5 = {
  variant: 'text.heading',
  fontWeight: 'semiBold',
  fontSize: ['xl', 'xl'],
  letterSpacing: 3,
  lineHeight: 'headingXL',
};

export const h6 = {
  variant: 'text.heading',
  fontWeight: 'regular',
  fontSize: ['lg', 'lg'],
  letterSpacing: 4,
  lineHeight: 'body',
};

export const p = {
  variant: 'text.body',
  fontSize: 'sm',
};

export const a = {
  variant: 'links.text',
};

export const pre = {
  fontSize: 'sm',
};

export const img = {
  maxWidth: 'full',
};

export const hr = {
  borderBottom: '2px solid',
  borderColor: 'muted',
  opacity: 0.2,
};
