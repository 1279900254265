import { graphql } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import {
  Box,
  Container,
  Heading,
  IntrinsicBox,
  Link,
  Paragraph,
  Section,
  StyledSpan,
} from 'voom-gatsby';

export const meetTheTeamFields = graphql`
  fragment meetTheTeamFields on ContentJsonSections {
    meetTheTeam {
      title
      caption
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        alt
      }
      mobileImage {
        src {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        alt
      }
      link {
        label
        url
      }
    }
  }
`;

interface AboutMeetTheTeamProps {
  title: string;
  caption: string;
  image: {
    src: ImageDataLike;
    alt: string;
  };
  mobileImage: {
    src: ImageDataLike;
    alt: string;
  };
  link: {
    label: string;
    url: string;
  };
}
export const AboutMeetTheTeam = ({
  image,
  mobileImage,
  title,
  caption,
  link,
}: AboutMeetTheTeamProps) => {
  const meetTheTeamImage = getImage(image.src);
  const meetTheTeamImageMobile = getImage(mobileImage.src);

  return (
    <Section theme="blue">
      <IntrinsicBox
        ratio={1440 / 1000}
        sx={{
          position: 'relative',
          overflow: 'hidden',
          fontSize: '0px',
          minHeight: 910,
        }}
      >
        <Box
          sx={{
            position: ['static', null, 'absolute'],
            bottom: [null, null, 6, null, '10%'],
            left: 0,
            width: 'full',
            zIndex: 'overlay',
          }}
        >
          <Container>
            <Box sx={{ maxWidth: ['none', null, 500], mt: [7, null, 0] }}>
              <Heading variant="h2" sx={{ mb: 5 }}>
                {title}
              </Heading>
              <Paragraph
                variant="largeP"
                sx={{ my: 5, fontSize: ['sm', null, 'md'] }}
              >
                <StyledSpan searchWords={['Voom™']} fontStyle="semiBold">
                  {caption}
                </StyledSpan>
              </Paragraph>
              <Link
                variant="button"
                to={link.url}
                sx={{ display: 'inline-block', mt: [1, 2], mb: [6, null, 0] }}
              >
                {link.label}
              </Link>
            </Box>
          </Container>
        </Box>
        {meetTheTeamImage && (
          <GatsbyImage
            image={meetTheTeamImage}
            alt={image.alt}
            style={{
              top: 0,
              left: 0,
              width: '100%',
              position: 'absolute',
              height: '100%',
            }}
            sx={{
              display: ['none !important', null, 'inline-block !important'],
            }}
          />
        )}
        {meetTheTeamImageMobile && (
          <GatsbyImage
            image={meetTheTeamImageMobile}
            alt={mobileImage.alt}
            sx={{
              display: ['inline-block !important', null, 'none !important'],
              position: 'relative',
              ':before': {
                content: '""',
                width: 'full',
                height: 100,
                position: 'absolute',
                zIndex: 'sticky',
                top: 0,
                left: 0,
                backgroundImage: (theme) => `${theme?.colors?.blueGradient}`,
              },
            }}
          />
        )}
      </IntrinsicBox>
    </Section>
  );
};
