import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Honeypot,
  NetlifyFormComponent,
  NetlifyFormProvider,
  useNetlifyForm,
} from 'react-netlify-forms';

import {
  Box,
  Button,
  Input,
  Label,
  StyledSup,
  Text,
  useDefaultFormFields,
} from 'voom-gatsby';

import { ReactComponent as ArrowIcon } from '~svg/icons/arrow-circle.svg';

export const FooterFormColumn = ({
  emailForm,
}: {
  emailForm: { title: string; caption: string };
}): JSX.Element => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const netlify = useNetlifyForm({
    name: 'Footer email form',
    action: '/thanks',
    honeypotName: 'bot-field',
    onSuccess: () => {
      if (window.gtag) {
        window.gtag('event', 'Form Submission -  Footer Email', {});
      }
      reset();
    },
  });

  const DefaultFormFields = useDefaultFormFields(register, reset);

  const onSubmit = (data: unknown) => netlify.handleSubmit(null, data);

  const footerInputValue = watch('email');
  const [inputActive, setInputActive] = useState(false);

  return (
    <Box
      sx={{
        mb: 4,
        gridColumn: [1, '1 / span 2', null, 4],
        pl: [0, null, null, 6],
        borderLeft: (theme) => [
          null,
          null,
          null,
          `1px solid ${theme.colors?.darkGrey}`,
        ],
        py: [7, null, null, 0],
        ml: [null, null, null, 'auto'],
        maxWidth: 400,
        borderTop: (theme) => [
          `1px solid ${theme.colors?.darkGrey}`,
          null,
          null,
          'none',
        ],
      }}
    >
      <Text
        as="p"
        variant="largeP"
        sx={{ fontWeight: 'semiBold', sup: { fontWeight: 'semiBold' } }}
      >
        <StyledSup>{emailForm.title}</StyledSup>
      </Text>
      <Text as="p" variant="smallP" sx={{ fontWeight: 'medium' }}>
        {netlify.success
          ? 'Thanks for contacting us!'
          : netlify.error
            ? 'Sorry, we could not reach servers. Please try again later.'
            : emailForm.caption}
      </Text>

      <NetlifyFormProvider {...netlify}>
        <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)} noValidate>
          <Honeypot />

          <Box
            sx={{
              position: 'relative',
              height: (theme) => theme.space?.[6],
              maxWidth: ['full', 340],
            }}
          >
            <Label
              htmlFor="footer-email-input"
              sx={{
                position: 'absolute',
                left: 3,
                top: '50%',
                display: 'inline-block',
                width: 'auto',
                color: 'darkGrey',
                fontFamily: 'body',
                pointerEvents: 'none',
                letterSpacing: 6,
                fontSize: 'xs',
                fontWeight: 'semiBold',
                textTransform: 'uppercase',
                transition: 'transform 0.1s ease-in-out',
                transformOrigin: 'top left',
                transform:
                  footerInputValue || inputActive
                    ? 'translate(0, -140%) scale(.75)'
                    : 'translate(0, -50%) scale(1)',
              }}
            >
              Your Email
            </Label>
            <Input
              {...register('email', {
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                },
              })}
              onFocus={() => setInputActive(true)}
              onBlur={() => setInputActive(false)}
              name="email"
              type="email"
              id="footer-email-input"
              sx={{
                borderRadius: 'rounded',
                px: 3,
                py: 4,
                pr: 6,
                height: 'full',
                bg: 'white',
                color: 'black',
              }}
            />
            <DefaultFormFields />
            <Button
              type="submit"
              variant="icon"
              aria-label="submit email form"
              sx={{
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                height: (theme) => theme.space?.[6],
                width: (theme) => theme.space?.[6],
              }}
            >
              <ArrowIcon />
            </Button>
          </Box>
          {errors.email &&
            (errors.email.type === 'required' ? (
              <Text as="p" sx={{ color: 'red', fontSize: 'xs', mt: 2, ml: 3 }}>
                Required
              </Text>
            ) : errors.email.type === 'pattern' ? (
              <Text as="p" sx={{ color: 'red', fontSize: 'xs', mt: 2, ml: 3 }}>
                {errors.email.message as ReactNode}
              </Text>
            ) : (
              <Text as="p" sx={{ color: 'red', fontSize: 'xs', mt: 2, ml: 3 }}>
                Error
              </Text>
            ))}
        </NetlifyFormComponent>
      </NetlifyFormProvider>
    </Box>
  );
};
