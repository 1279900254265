import { graphql, useStaticQuery } from 'gatsby';

import { Box, SEO } from 'voom-gatsby';

import { BecomeASurgeon } from '~sections/BecomeASurgeon';
import { Hero } from '~sections/Hero';
import { TopFeature } from '~sections/TopFeature';
import { TrainCalendar } from '~sections/TrainCalendar';
import { TrainCarousel } from '~sections/TrainCarousel';
import { TrainLocations } from '~sections/TrainLocations';
import { TrainSideBySide } from '~sections/TrainSideBySide';

const TrainWithUs = () => {
  const { contentJson } = useStaticQuery(TRAIN_WITH_US_PAGE_QUERY);

  return (
    <Box>
      {contentJson.sections.hero && <Hero {...contentJson.sections.hero} />}
      {contentJson.sections.topFeature && (
        <TopFeature {...contentJson.sections.topFeature} theme="blue" />
      )}
      <Box
        sx={{
          background: (theme) => `${theme.colors?.tealGradient}`,
          pt: 1,
        }}
      >
        {contentJson.sections.trainLocations && (
          <TrainLocations {...contentJson.sections.trainLocations} />
        )}
        {contentJson.sections.trainSideBySide && (
          <TrainSideBySide {...contentJson.sections.trainSideBySide} />
        )}
        {contentJson.sections.trainCalendar && (
          <TrainCalendar {...contentJson.sections.trainCalendar} />
        )}
      </Box>
      {contentJson.sections.trainCarousel && (
        <TrainCarousel {...contentJson.sections.trainCarousel} />
      )}
      {contentJson.sections.becomeASurgeon && (
        <BecomeASurgeon {...contentJson.sections.becomeASurgeon} />
      )}
    </Box>
  );
};

export const Head = () => {
  return (
    <SEO
      title="Train With Us | Voom™ Medical Devices Inc. | One-on-One, Surgeon-to-Surgeon Bunionplasty® Procedure Trainin"
      description="Voom™ has set the new industry gold standard for minimally invasive bunion surgery (MIBS) adoption, with robust didactic education; one-on-one, surgeon-to-surgeon cadaveric training; and more."
    />
  );
};

export const TRAIN_WITH_US_PAGE_QUERY = graphql`
  query TrainWithUsPage {
    contentJson(jsonId: { eq: "train-with-us" }) {
      sections {
        ...heroFields
        ...topFeatureFields
        ...trainLocationsFields
        ...trainCalendarFields
        ...trainCarouselFields
        ...trainSideBySideFields
        ...becomeASurgeonFields
      }
    }
  }
`;

export default TrainWithUs;
