import { graphql } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import {
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Section,
  StyledSup,
  Text,
} from 'voom-gatsby';

import { ReactComponent as BunionplastyLogo } from '~svg/logo/bunionplasty.svg';

export const preFooterSideBySideFields = graphql`
  fragment preFooterSideBySideFields on ContentJsonSections {
    preFooterSideBySide {
      icon
      title
      caption
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        alt
      }
      links {
        label
        url
      }
      disclaimer
    }
  }
`;

interface PreFooterSideBySideProps {
  icon: string;
  title: string;
  caption: string;
  image: {
    src: ImageDataLike;
    alt: string;
  };
  links: {
    url: string;
    label: string;
  }[];
  disclaimer?: string;
}

export const PreFooterSideBySide = ({
  title,
  caption,
  image,
  links,
  disclaimer,
}: PreFooterSideBySideProps) => {
  const imageData = getImage(image.src);

  return (
    <Section
      theme="blue"
      sx={{
        bg: 'text', // equals white for blue theme
        position: 'relative',
        py: 4,
        '::after': {
          content: "''",
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: 'full',
          height: '50%',
          bg: 'blue',
          zIndex: 'content',
        },
      }}
    >
      <Container
        sx={{
          position: 'relative',
          zIndex: 'sticky',
        }}
      >
        <Flex
          sx={{
            bg: 'background',
            '> div': {
              flex: '1 1 50%',
              boxShadow: 'preFooterShadow',
            },
          }}
        >
          <Box sx={{ py: [6, null, 7], px: [5, 6] }}>
            <BunionplastyLogo
              sx={{ width: 200, height: 60, color: 'bunionplasty' }}
            />

            <Heading variant="h2" sx={{ my: 3 }}>
              {title}
            </Heading>
            <Text variant="mediumP" as="p" sx={{ my: 5 }}>
              <StyledSup>{caption}</StyledSup>
            </Text>
            <Flex sx={{ mt: 5 }}>
              {links.map(({ url, label }) => {
                return (
                  <Link
                    variant="button"
                    key={label}
                    href={url}
                    sx={{ width: ['full', 'auto'] }}
                  >
                    {label}
                  </Link>
                );
              })}
            </Flex>
            <Text as="p" variant="copyright" sx={{ mt: 5, color: 'darkGrey' }}>
              {disclaimer}
            </Text>
          </Box>
          <Box
            sx={{
              position: 'relative',
              display: ['none', null, null, 'block'],
            }}
          >
            {imageData && (
              <GatsbyImage
                image={imageData}
                alt={image.alt}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
          </Box>
        </Flex>
      </Container>
    </Section>
  );
};
