import {
  Box,
  Container,
  Flex,
  Link,
  StyledSup,
  Text,
  TextSpacer,
} from 'voom-gatsby';

export const FooterLegal = ({
  legal,
}: {
  legal: { title: string; links: { url: string; label: string }[] };
}): JSX.Element => {
  const currentYear = new Date().getFullYear().toString();
  return (
    <Box sx={{ bg: 'blue2', py: 4 }}>
      <Container>
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: 'copyright',
            flexDirection: ['column', null, 'row'],
          }}
        >
          <Text
            sx={{
              color: 'darkGrey',
              mb: [2, null, 0],
              sup: { color: 'darkGrey' },
            }}
          >
            <StyledSup>{legal.title.replace('<YEAR>', currentYear)}</StyledSup>
          </Text>
          <Flex sx={{ flexWrap: 'wrap' }}>
            {legal.links.map(({ url, label }) => {
              return (
                <TextSpacer
                  key={label}
                  sx={{
                    ':not(:last-child)::after': {
                      content: "'|'",
                      mx: [3, 4],
                      color: 'darkGrey',
                    },
                  }}
                >
                  <Link
                    key={label}
                    href={url}
                    variant="inline"
                    sx={{
                      display: 'inline-block',
                      fontSize: 'copyright',
                      color: 'darkGrey',
                    }}
                  >
                    {label}
                  </Link>
                </TextSpacer>
              );
            })}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
