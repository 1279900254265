import { graphql } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';
import { Settings } from 'react-slick';

import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Link,
  Paragraph,
  Section,
  Slider,
  SliderNextArrow,
  SliderPrevArrow,
  Spacer,
  ThemeUIProvider,
  getThemeByName,
  useThemeUI,
} from 'voom-gatsby';

import { TopFeatureRow } from '~components';

import { ReactComponent as CarouselBg } from '~svg/icons/carousel-bg.svg';
import { ReactComponent as BunionplastyLogo } from '~svg/logo/bunionplasty.svg';
import { ReactComponent as RevconLogo } from '~svg/logo/revcon-with-caption.svg';

interface HomeCarouselCardProps {
  icon: 'REVCON' | 'BUNIONPLASTY';
  image: {
    src: ImageDataLike;
    alt: string;
  };
  title: string;
  caption?: string;
  link: {
    url: string;
    label: string;
  };
  topFeature: {
    title: string;
    caption: string[];
  };
}

interface HomeCarouselProps {
  cards: HomeCarouselCardProps[];
}

export const homeCarouselFields = graphql`
  fragment homeCarouselFields on ContentJsonSections {
    homeCarousel {
      cards {
        icon
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            }
          }
          alt
        }
        title
        caption
        link {
          url
          label
        }
        topFeature {
          title
          caption
        }
      }
    }
  }
`;
export const HomeCarousel = ({ cards }: HomeCarouselProps) => {
  const { theme } = useThemeUI();

  const settings: Settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SliderNextArrow sx={{ left: 'unset', right: [30, 70, 75] }} />,
    prevArrow: (
      <SliderPrevArrow sx={{ left: 'unset', right: [75, 115, 125] }} />
    ),
    responsive: [
      // Hack to style two cards on large desktop
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Section theme="base" sx={{ pb: [5, 10], mt: -8 }}>
      <Container
        variant="wideContainer"
        sx={{
          overflow: 'hidden',
          px: [3, 6, 7],
          mr: 0,
          // Hack to style two cards on large desktop
          '@media screen and (min-width: 1700px)': {
            mr: 'auto',
            maxWidth: 'none',
          },
        }}
      >
        <ThemeUIProvider theme={getThemeByName('teal')}>
          <Slider
            settings={settings}
            appendDots={true}
            mobileGradient={false}
            sx={{
              mb: 7,
              mr: [-5, -4, -4],
              '.slick-list': {
                // this allows drop shadow to be visible
                overflow: 'visible',
              },
              '.slick-slide': {
                transform: ['none', `translateX(-${theme.space?.[2]}px)`],
              },
              // Hack to style two cards on large desktop
              '@media screen and (min-width: 1700px)': {
                '.slick-slide': {
                  transform: 'translateX(20px)',
                },
              },
            }}
          >
            {cards.map((card) => {
              const imageData = getImage(card.image.src);
              return (
                <Box
                  sx={{
                    pr: [5, 7],
                  }}
                  key={card.title}
                >
                  <Box
                    sx={{
                      '.slick-active &': {
                        transition: 'box-shadow 0.8s ease-in-out',
                        boxShadow: 'dropShadow',
                      },
                    }}
                  >
                    <Grid
                      sx={{
                        bg: 'background',
                        height: [660, 780, 600],
                        gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
                        gridGap: 0,
                        gridTemplateAreas: [
                          "'svg' 'text' 'image'",
                          null,
                          "'svg image' 'text image'",
                        ],
                      }}
                    >
                      <Box
                        sx={{
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          pt: [3, null, 7],
                          pb: [7, null, 4],
                          pl: [4, null, 7],
                          pr: [4, null, 0],
                          m: ['auto', null, 0],
                          height: [30, null, 'auto'],
                          width: 'full',
                          gridArea: 'svg',
                        }}
                      >
                        {card.icon === 'REVCON' ? (
                          <RevconLogo
                            sx={{
                              maxWidth: ['full', 390],
                              height: 'auto',
                              color: 'primary',
                            }}
                          />
                        ) : (
                          <BunionplastyLogo
                            sx={{
                              maxWidth: [200, 260],
                              height: 'auto',
                              color: 'bunionplasty',
                              transform: ['translateY(-10px)', null, 'none'],
                            }}
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          gridArea: 'text',
                          pl: [4, null, 7],
                          pr: [4, null, 0],
                          mx: ['auto', null, 0],
                          minWidth: [0, null, 415],
                        }}
                      >
                        <Heading
                          variant="h3"
                          sx={{
                            fontSize: ['xl', '3xl'],
                          }}
                        >
                          {card.title}
                        </Heading>
                        <Spacer space={4} />
                        {card.caption && (
                          <>
                            <Paragraph variant="homeHeroCaption">
                              {card.caption}
                            </Paragraph>
                            <Spacer space={4} />
                          </>
                        )}
                        <Link variant="buttonOutline" href={card.link.url}>
                          {card.link.label}
                        </Link>
                        <Spacer space={3} />
                      </Box>
                      <Box
                        sx={{
                          p: [0, null, 7],
                          pl: 0,
                          gridArea: 'image',
                        }}
                      >
                        <Flex
                          sx={{
                            width: 'full',
                            height: [230, 300, 'full'],
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              mx: 'auto',
                              width: 'full',
                              height: 'full',
                              maxWidth: 477,
                              position: 'relative',
                            }}
                          >
                            {imageData && (
                              <GatsbyImage
                                image={imageData}
                                alt={card.image.alt}
                                style={{
                                  zIndex: 1,
                                  width: '100%',
                                  height: '100%',
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                  maxWidth: 477,
                                  maxHeight: 426,
                                }}
                                imgStyle={{ objectFit: 'contain' }}
                              />
                            )}
                            <CarouselBg
                              sx={{
                                zIndex: 0,
                                width: 'full',
                                height: 'full',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                maxWidth: 477,
                                maxHeight: 426,
                              }}
                            />
                          </Box>
                        </Flex>
                      </Box>
                    </Grid>
                    <ThemeUIProvider theme={getThemeByName('blue')}>
                      <TopFeatureRow
                        {...card.topFeature}
                        showBoxShadow={false}
                        sx={{
                          display: ['none', 'grid'],
                          maxWidth: ['none', 'none'],
                          '> div': {
                            flex: ['0 0 60px', '0 0 100px', '0 0 22%'],
                          },
                        }}
                      />
                    </ThemeUIProvider>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </ThemeUIProvider>
      </Container>
    </Section>
  );
};
