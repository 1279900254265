import {
  Box,
  Flex,
  Paragraph,
  Spacer,
  StyledSup,
  Text,
  ThemeUIStyleObject,
} from '../components';
import { ReactComponent as CertifiedBadgeIcon } from '../svg/icons/certified-badge-icon.svg';

interface CertifiedBadgeProps {
  text?: string;
  sx?: ThemeUIStyleObject;
  className?: string;
}

export const CertifiedBadge = ({
  text,
  sx,
  className,
}: CertifiedBadgeProps) => {
  return (
    <Flex
      className={className}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bg: 'certifiedBg',
        borderRadius: 'corner',
        zIndex: 1,
        p: 2,
        m: [3, 4],
        alignItems: 'center',
        boxShadow: 'certifiedBadgeShadow',
        maxWidth: 360,
        ...sx,
      }}
    >
      <Box
        sx={{
          flex: ['0 0 32px', null, '0 0 50px'],
          width: [32, null, 50],
          height: [27, null, 55],
          position: 'relative',
          '&::after': {
            content: "''",
            position: 'absolute',
            left: '10%',
            right: '12%',
            top: '11%',
            bottom: '-13%',
            background: '#0A3737',
            opacity: '0.8',
            filter: 'blur(22px)',
            borderRadius: 'circle',
            zIndex: -1,
          },
        }}
      >
        <CertifiedBadgeIcon sx={{ width: 'full', height: 'full' }} />
      </Box>
      <Spacer direction="horizontal" space={2} />
      <Paragraph sx={{ fontSize: ['copyright', 'xs'] }}>
        {text ?? (
          <>
            <Text sx={{ fontWeight: 'semiBold' }}>{`Look for the mark `}</Text>
            <StyledSup>
              Only our doctors can perform the Bunionplasty® procedure.
            </StyledSup>
          </>
        )}
      </Paragraph>
    </Flex>
  );
};
