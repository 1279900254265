import { useLocation } from '@reach/router';
import { useAtom } from 'jotai';
import { FC, SVGProps } from 'react';

import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Link,
  NavDropdown,
  StyledSup,
  Text,
  TextSpacer,
} from '../../components';
import { navStateAtom } from '../../hooks/use-sticky-header';

export interface MenuProps {
  links: {
    url: string;
    label: string;
    type?: string;
    subLinks?: {
      url: string;
      label: string;
      description?: string;
    }[];
  }[];
  phoneNumber?: {
    display: string;
    link: string;
  };
  faxNumber?: {
    display: string;
    link: string;
  };
  email?: {
    display: string;
    link: string;
  };
  socials: {
    icon: {
      svg: string;
    };
    title: string;
    URL: string;
  }[];
  MenuIcon: FC<SVGProps<SVGElement>>;
  CloseIcon: FC<SVGProps<SVGElement>>;
  ButtonLinkIcon?: FC<SVGProps<SVGElement>>;
}

export const Menu = ({
  links,
  phoneNumber,
  faxNumber,
  email,
  socials,
  MenuIcon,
  CloseIcon,
  ButtonLinkIcon,
}: MenuProps) => {
  const { pathname } = useLocation();

  const [{ isSticky, preventPeekaBoo, isDropdownOpen }, setNavState] =
    useAtom(navStateAtom);
  const buttonLink = links.find((link) => link.type === 'button');

  return (
    <Flex
      sx={{
        flex: '1 1 auto',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: [null, null, null, 'full'],
      }}
    >
      <Flex
        sx={{
          display: [isDropdownOpen ? 'flex' : 'none', null, null, 'flex'],
          flexDirection: isDropdownOpen ? ['column', null, null, 'row'] : 'row',
          position: isDropdownOpen ? ['fixed', null, null, 'static'] : 'static',
          justifyContent: [null, null, null, 'flex-end'],
          alignItems: [null, null, null, 'center'],
          height: [null, null, null, 'full'],
          ...(isDropdownOpen
            ? {
                top: 'calc(100% - 2px)',
                left: 0,
                right: 0,
                height: 'screenHeight',
                bg: ['background', null, null, 'initial'],
                zIndex: 'background',
                pt: [3, null, null, 0],
                px: [4, null, null, 0],
                '> span': {
                  mb: [4, null, null, 0],

                  // button wrapped by div
                  '> a, button': {
                    fontSize: ['md', null, null, 'xs'],
                  },
                },
              }
            : {}),
        }}
      >
        {links
          .filter((cta) =>
            isDropdownOpen
              ? cta
              : cta.type === 'boldLink' || cta.type === undefined,
          )
          .map((link) => (
            <TextSpacer
              key={link.label}
              sx={{
                height: [null, null, null, 'full'],
                '::after': {
                  display: ['none', null, null, 'inline-block'],
                  color: isSticky || preventPeekaBoo ? 'navSticky' : 'nav',
                },
                // Remove '·' from bunionplasty nav
                ':not(:last-child)::after': {
                  content: process.env.THEME === 'voom' ? '"·"' : '""',
                },
              }}
            >
              {link.subLinks && link.subLinks.length > 0 ? (
                <NavDropdown link={link} subLinks={link.subLinks} />
              ) : (
                <Link
                  variant="nav"
                  className={pathname.includes(link.url) ? 'active' : ''}
                  href={link.url}
                  onClick={() =>
                    setNavState((state) => ({
                      ...state,
                      isDropdownOpen: false,
                    }))
                  }
                  sx={{
                    span: {
                      color: isSticky || preventPeekaBoo ? 'navSticky' : 'nav',
                      ...(link.type === 'link' && isDropdownOpen
                        ? { fontWeight: 'regular' }
                        : {}),
                    },
                  }}
                >
                  <Text>
                    <StyledSup>{link.label}</StyledSup>
                  </Text>
                </Link>
              )}
            </TextSpacer>
          ))}
        {/* Special button link in desktop nav */}
        {buttonLink && !isDropdownOpen && (
          <Link
            variant="button"
            sx={{
              boxShadow: 'none !important',
              whiteSpace: 'nowrap',
            }}
            href={buttonLink.url}
            onClick={() =>
              setNavState((state) => ({
                ...state,
                isDropdownOpen: false,
              }))
            }
          >
            {buttonLink.label}
          </Link>
        )}
        {isDropdownOpen && (
          <Box sx={{ mb: 3, display: ['block', null, null, 'none'] }}>
            <Divider
              sx={{
                borderBottomWidth: '3px',
                borderColor: 'lightGrey',
                my: 3,
                opacity: 0.8,
              }}
            />
            <Heading sx={{ mt: 6, mb: 3 }}>Contact Us</Heading>
            {phoneNumber && (
              <Link href={`tel:${phoneNumber.link}`} variant="inline">
                {phoneNumber.display}
              </Link>
            )}
            {faxNumber && (
              <Link href={`tel:${faxNumber.link}`} variant="inline">
                {faxNumber.display}
              </Link>
            )}
            {email && (
              <Link
                href={`mailto:${email.link}?subject=Voom™ forward with us`}
                variant="inline"
              >
                {email.display}
              </Link>
            )}

            <Heading sx={{ mt: 6, mb: 3 }}>Follow Us</Heading>
            <Flex sx={{ ml: -2 }}>
              {socials.map((social) => {
                return (
                  <Link
                    variant="iconNav"
                    sx={{
                      mr: 2,
                      p: 2,
                      color: 'social',
                      ':hover': { svg: { color: 'primary' } },
                      width: 'auto',
                      height: 'auto',
                    }}
                    key={social.title}
                    href={social.URL}
                    aria-label={social.title}
                  >
                    <Box
                      dangerouslySetInnerHTML={{ __html: social.icon.svg }}
                      sx={{
                        '&, svg': {
                          color: 'social',
                          height: 20,
                          width: 20,
                          mx: 'auto',
                        },
                      }}
                    />
                  </Link>
                );
              })}
            </Flex>
          </Box>
        )}
      </Flex>
      <Button
        aria-label="Toggle mobile menu"
        variant="iconMenu"
        sx={{ display: ['block', null, null, 'none'], color: 'primary' }}
        onClick={() =>
          setNavState((state) => ({
            ...state,
            isDropdownOpen: !state.isDropdownOpen,
          }))
        }
      >
        {isDropdownOpen ? <CloseIcon /> : <MenuIcon />}
      </Button>
      {buttonLink && ButtonLinkIcon && (
        <Link
          variant="iconNavWithIcon"
          sx={{
            display: ['flex', null, null, 'none'],
          }}
          href={buttonLink.url}
          aria-label={buttonLink.label}
          onClick={() =>
            setNavState((state) => ({
              ...state,
              isDropdownOpen: false,
            }))
          }
        >
          {buttonLink.label.includes('Find a Doctor') && <Text>Doctors</Text>}
          <ButtonLinkIcon />
        </Link>
      )}
    </Flex>
  );
};
