import { graphql, useStaticQuery } from 'gatsby';
import type { ReactElement } from 'react';

import {
  Box,
  Flex,
  NavDataProps,
  Navigation,
  ThemeName,
  ThemeUIProvider,
  getThemeByName,
} from 'voom-gatsby';

import { Footer } from '~sections/Footer/Footer';

import { ReactComponent as MenuCloseIcon } from '~svg/icons/menu-close.svg';
import { ReactComponent as MenuIcon } from '~svg/icons/menu.svg';
import { ReactComponent as Logo } from '~svg/logo/voom-logo-with-tagline.svg';

export interface PageLayoutProps {
  children: ReactElement;
  pageContext: { theme?: ThemeName } & Record<string, unknown>;
}
export const NAVIGATION_QUERY_NEW = graphql`
  query NavigationNew {
    navSettings: sanitySiteSettings(
      _id: { eq: "c4b03ae9-68ee-4852-9ee5-010c514d00ba" }
    ) {
      email {
        display
        link
      }
      phoneNumber {
        display
        link
      }
      faxNumber {
        display
        link
      }
      socials {
        icon {
          svg
        }
        title
        URL
      }
    }
    contentJson(jsonId: { eq: "navigation" }) {
      sections {
        navigation {
          links {
            url
            label
            subLinks {
              url
              label
              description
            }
          }
          contact {
            title
          }
          social {
            title
          }
        }
      }
    }
  }
`;

export const PageLayout = ({
  children: page,
  pageContext,
}: PageLayoutProps): JSX.Element => {
  const pageTheme = getThemeByName(pageContext.theme);

  const {
    navSettings,
    contentJson: {
      sections: { navigation },
    },
  } = useStaticQuery(NAVIGATION_QUERY_NEW);

  const navData: NavDataProps = {
    Logo: Logo,
    CloseIcon: MenuCloseIcon,
    MenuIcon: MenuIcon,
    links: navigation.links,
    ...navSettings,
  };
  const pageLayoutElement = (
    <Flex
      sx={{
        fontFamily: 'body',
        '*:focus, *:active': {
          outlineColor: (theme) => `${theme?.colors?.primary}`,
          outlineOffset: 0,
        },
        flexDirection: 'column',
        alignItems: 'stretch',
        minHeight: 'full',
        main: {
          flexGrow: 1,
          flexShrink: 0,
        },
        'header, footer': {
          flexShrink: 0,
        },
      }}
    >
      <Navigation pageTheme={pageContext.theme} data={navData} />
      <Box as="main">{page}</Box>
      <Footer />
    </Flex>
  );

  // only add ThemeUIProvider if theme when needed
  return ['base', undefined].includes(pageContext.theme) ? (
    pageLayoutElement
  ) : (
    <ThemeUIProvider theme={pageTheme}>{pageLayoutElement}</ThemeUIProvider>
  );
};
