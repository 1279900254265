import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { FieldValues, UseFormRegister, UseFormReset } from 'react-hook-form';

import { ipGeoAtom } from '../hooks/use-ip-geo';

/**
 * Default Form Fields
 *
 * this component must be added to the form component that uses the useDefaultFormFields hook
 *
 * its the HTML input fields that are hidden and used to send data
 *
 * @param register
 * @returns
 */
const DefaultFormFieldsElement = ({
  register,
}: {
  register: UseFormRegister<FieldValues>;
}) => {
  const isVoomTheme = process.env.THEME === 'voom';

  return (
    <>
      {isVoomTheme ? (
        <input type="hidden" value="1044" {...register('viewId')} />
      ) : (
        <input type="hidden" value="1755" {...register('viewId')} />
      )}

      <input type="hidden" {...register('ipCity')} />
      <input type="hidden" {...register('ipSubdivisionCode')} />
      <input type="hidden" {...register('ipSubdivisionName')} />
      <input type="hidden" {...register('ipCountryCode')} />
      <input type="hidden" {...register('ipCountryName')} />
      <input type="hidden" {...register('ipTimezone')} />
      <input type="hidden" {...register('ipLat')} />
      <input type="hidden" {...register('ipLng')} />
      <input type="hidden" {...register('ip')} />
    </>
  );
};

/**
 * Use Default Form Fields
 *
 * this hook must be added to the form component that uses the DefaultFormFields component
 *
 * if added to DoctorModalForm, it will cause an infinite loop
 *
 * @param reset
 */
export const useDefaultFormFields = (
  register: UseFormRegister<FieldValues>,
  reset: UseFormReset<FieldValues>,
) => {
  const ipGeo = useAtomValue(ipGeoAtom);

  useEffect(() => {
    if (ipGeo.ip !== '') {
      reset({
        ipCity: ipGeo.city,
        ipSubdivisionCode: ipGeo.subdivisionCode,
        ipSubdivisionName: ipGeo.subdivisionName,
        ipCountryCode: ipGeo.countryCode,
        ipCountryName: ipGeo.countryName,
        ipTimezone: ipGeo.timezone,
        ipLat: ipGeo.lat,
        ipLng: ipGeo.lng,
        ip: ipGeo.ip,
      });
    }
  }, [ipGeo, reset]);

  const DefaultFormFields = () => (
    <DefaultFormFieldsElement register={register} />
  );

  return DefaultFormFields;
};
