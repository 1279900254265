import { createFontFace } from '../createFontFace';
import signPainterWoffSrc from './signpainter-housescript.woff';
import signPainterWoff2Src from './signpainter-housescript.woff2';
import ttBoldWoffSrc from './tt-norms-bold.woff';
import ttBoldWoff2Src from './tt-norms-bold.woff2';
import ttMediumWoffSrc from './tt-norms-medium.woff';
import ttMediumWoff2Src from './tt-norms-medium.woff2';
import ttRegularWoffSrc from './tt-norms-regular.woff';
import ttRegularWoff2Src from './tt-norms-regular.woff2';

export const ttBold = createFontFace({
  fontFamily: 'TT Norms',
  fontWeight: 600,
  fontStyle: 'normal',
  fallback: 'sans-serif',
  fontUrls: [
    { src: ttBoldWoff2Src, format: 'woff2' },
    { src: ttBoldWoffSrc, format: 'woff' },
  ],
  preload: true,
});

export const ttMedium = createFontFace({
  fontFamily: 'TT Norms',
  fontWeight: 500,
  fontStyle: 'normal',
  fallback: 'sans-serif',
  fontUrls: [
    { src: ttMediumWoff2Src, format: 'woff2' },
    { src: ttMediumWoffSrc, format: 'woff' },
  ],
  preload: true,
});

export const ttRegular = createFontFace({
  fontFamily: 'TT Norms',
  fontWeight: 400,
  fontStyle: 'normal',
  fallback: 'sans-serif',
  fontUrls: [
    { src: ttRegularWoff2Src, format: 'woff2' },
    { src: ttRegularWoffSrc, format: 'woff' },
  ],
  preload: true,
});

export const signPainter = createFontFace({
  fontFamily: 'SignPainter HouseScript',
  fontWeight: 500,
  fontStyle: 'normal',
  fallback: 'serif',
  fontUrls: [
    { src: signPainterWoff2Src, format: 'woff2' },
    { src: signPainterWoffSrc, format: 'woff' },
  ],
  preload: true,
});
