import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import { Flex, Heading, Link, Paragraph } from 'voom-gatsby';

import { ReactComponent as FacebookIcon } from '~svg/logo/facebook.svg';
import { ReactComponent as InstagramIcon } from '~svg/logo/instagram.svg';
import { ReactComponent as LinkedInIcon } from '~svg/logo/linked-in.svg';
import { ReactComponent as YoutubeIcon } from '~svg/logo/youtube.svg';

export interface MemberCardProps {
  name: string;
  position: string;
  image: {
    asset: {
      gatsbyImageData: ImageDataLike;
    };
  };
  facebookLink?: string;
  instagramLink?: string;
  youtubeLink?: string;
  linkedInLink?: string;
}
export const MemberCard = ({
  name,
  position,
  image,
  facebookLink,
  instagramLink,
  youtubeLink,
  linkedInLink,
}: MemberCardProps) => {
  const memberImage = getImage(image.asset);

  return (
    <Flex
      key={name}
      sx={{
        flexDirection: 'column',
        width: ['full', 'auto'],
        borderRight: (theme) => ['none', `1px solid ${theme.colors?.grey}`],
        borderBottom: (theme) => [`1px solid ${theme.colors?.grey}`, 'none'],

        pl: ['auto', 0],
        pr: ['auto', 3],
        mt: [5, 0],
      }}
    >
      {memberImage && (
        <GatsbyImage
          image={memberImage}
          alt={name}
          sx={{
            maxWidth: 250,
            mb: 4,
            borderRadius: 'circle',
            img: { borderRadius: 'circle' },
          }}
        />
      )}
      <Paragraph variant="eyeBrow" sx={{ color: 'primary', mb: [2, 3] }}>
        {position}
      </Paragraph>
      <Heading variant="h4" sx={{ mb: [2, 3] }}>
        {name}
      </Heading>
      <Flex sx={{ mb: 4 }}>
        {facebookLink && (
          <Link
            variant="icon"
            sx={{
              mr: 2,
            }}
            key={facebookLink}
            href={facebookLink}
            aria-label="Facebook Link"
          >
            <FacebookIcon
              sx={{
                height: 16,
                width: 16,
              }}
            />
          </Link>
        )}
        {instagramLink && (
          <Link
            variant="icon"
            sx={{
              mr: 2,
            }}
            key={instagramLink}
            href={instagramLink}
            aria-label="Instagram Link"
          >
            <InstagramIcon
              sx={{
                height: 16,
                width: 16,
              }}
            />
          </Link>
        )}
        {youtubeLink && (
          <Link
            variant="icon"
            sx={{
              mr: 2,
            }}
            key={youtubeLink}
            href={youtubeLink}
            aria-label="YouTube Link"
          >
            <YoutubeIcon
              sx={{
                height: 16,
                width: 23,
              }}
            />
          </Link>
        )}
        {linkedInLink && (
          <Link
            variant="icon"
            sx={{
              mr: 2,
            }}
            key={linkedInLink}
            href={linkedInLink}
            aria-label="LinkedIn Link"
          >
            <LinkedInIcon
              sx={{
                height: 16,
                width: 18,
              }}
            />
          </Link>
        )}
      </Flex>
    </Flex>
  );
};
