import { forwardRef } from 'react';
import type { Ref } from 'react';

import { Box, ThemeUIProvider } from '../components';
import type { BoxProps } from '../components';
import { getThemeByName } from '../themes';
import type { ThemeName } from '../themes';

export interface SectionProps extends BoxProps {
  /** The name of the theme to be used for this section. */
  theme?: ThemeName;
  /**
   * Sets ID on section to be used for anchor links
   */
  id?: string;
  children?: React.ReactNode;
  narrowPaddingTop?: boolean;
  narrowPaddingBottom?: boolean;
}

export const Section = forwardRef(function Section(
  {
    bg = 'background',
    color = 'text',
    theme,
    narrowPaddingBottom,
    narrowPaddingTop,
    ...props
  }: SectionProps,
  forwardedRef: Ref<HTMLElement>,
) {
  const sectionElement = (
    <Box
      ref={forwardedRef}
      as="section"
      bg={bg}
      color={color}
      {...props}
      sx={{
        // Voom has a lot of overflow box shadows so overflow hidden and position relative is needed for only bunionplasty
        ...(process.env.THEME === 'bunionplasty'
          ? { py: [8, null, 10], overflow: 'hidden', position: 'relative' }
          : {}),
        ...(narrowPaddingTop ? { pt: [5, null, 7] } : {}),
        ...(narrowPaddingBottom ? { pb: [5, null, 7] } : {}),

        zIndex: 1,
        ...props.sx,
      }}
    />
  );
  return theme ? (
    <ThemeUIProvider theme={getThemeByName(theme)}>
      {sectionElement}
    </ThemeUIProvider>
  ) : (
    sectionElement
  );
});
