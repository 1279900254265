import { graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import {
  Honeypot,
  NetlifyFormComponent,
  NetlifyFormProvider,
  useNetlifyForm,
} from 'react-netlify-forms';

import {
  Box,
  Button,
  Divider,
  Flex,
  FormInput,
  Heading,
  Link,
  Section,
  Spacer,
  StyledSpan,
  Text,
  useDefaultFormFields,
} from 'voom-gatsby';

interface ContactUsFormProps {
  title: string;
  generalContact: {
    title: string;
    address: string[];
    contactLinks: {
      label: string;
      type: string;
      url: string;
    }[];
  };
  callouts: {
    title: string;
    caption: string;
  }[];
}

export const contactUsFormFields = graphql`
  fragment contactUsFormFields on ContentJsonSections {
    contactUsForm {
      title
      generalContact {
        title
        address
        contactLinks {
          label
          type
          url
        }
      }
      callouts {
        title
        caption
      }
    }
  }
`;

export const ContactUsForm = ({
  title,
  generalContact,
  callouts,
}: ContactUsFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const netlify = useNetlifyForm({
    name: 'Contact Us form',
    action: '/thanks',
    honeypotName: 'bot-field',
    onSuccess: () => {
      if (window.gtag) {
        window.gtag('event', 'Form Submission - Contact Us', {});
      }
      reset();
    },
  });

  const DefaultFormFields = useDefaultFormFields(register, reset);

  const onSubmit = (data: unknown) => {
    netlify.handleSubmit(null, data);
  };

  return (
    <Section
      theme="base"
      sx={{
        width: 'full',
        overflow: 'visible',
        background: (theme) => `${theme.colors?.tealGradient}`,
      }}
    >
      <Flex sx={{ flexDirection: ['column', null, 'row'] }}>
        <Box
          sx={{
            bg: 'white',
            flex: ['1 1 auto', null, '1 1 60%'],
            pt: [7, null, null, 9],
            pb: 7,
            px: [3, 7],
            transform: (theme) => [
              'translateY(0)',
              null,
              `translateY(-${theme.space?.[9]}px)`,
              `translateY(-${theme.space?.[10]}px)`,
            ],
          }}
        >
          <Heading variant="h4" sx={{ maxWidth: [225, 325], mb: 5 }}>
            {title}
          </Heading>
          <NetlifyFormProvider {...netlify}>
            <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
              <Honeypot />
              {netlify.success && (
                <p sx={{ variant: 'alerts.success', p: 3 }}>
                  Thanks for contacting us!
                </p>
              )}
              {netlify.error && (
                <p sx={{ variant: 'alerts.muted', p: 3 }}>
                  Sorry, we could not reach servers. Please try again later. If
                  this problem continues, please contact us. Thanks/
                </p>
              )}
              <FormInput
                label="First Name*"
                name="firstName"
                register={register('firstName', { required: true })}
                errors={errors.firstName}
                control={control}
              />
              <FormInput
                label="Last Name*"
                name="lastName"
                register={register('lastName', { required: true })}
                errors={errors.lastName}
                control={control}
              />
              <FormInput
                label="Email Address*"
                name="email"
                type="email"
                register={register('email', { required: true })}
                errors={errors.email}
                control={control}
              />
              <FormInput
                label="Phone Number"
                name="phoneNumber"
                type="tel"
                register={register('phoneNumber')}
                errors={errors.phoneNumber}
                control={control}
              />
              <FormInput
                label="Your Message"
                name="message"
                register={register('message')}
                errors={errors.message}
                control={control}
                Textarea={true}
              />
              <DefaultFormFields />
              <Flex
                sx={{
                  flexDirection: ['column', 'row'],
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: 4,
                }}
              >
                <Button
                  type="submit"
                  sx={{
                    variant: 'buttons.primary',
                    appearance: 'none',
                    opacity: 1,
                    width: ['full', 'auto'],
                    mb: [3, 0],
                  }}
                >
                  Submit
                </Button>
                <Text sx={{ variant: 'text.copyright', color: 'darkGrey' }}>
                  *Required Form Fields
                </Text>
              </Flex>
            </NetlifyFormComponent>
          </NetlifyFormProvider>
        </Box>
        <Box
          sx={{ flex: ['1 1 auto', null, '1 1 40%'], py: 7, px: [3, 7, 4, 6] }}
        >
          <Heading variant="h5">{generalContact.title}</Heading>
          <Spacer space={2} />
          {generalContact.address.map((addressLine) => (
            <Text key={addressLine} as="p" sx={{ display: 'block' }}>
              <StyledSpan searchWords={['Voom™ Medical Devices, Inc.']}>
                {addressLine}
              </StyledSpan>
            </Text>
          ))}
          <Spacer space={2} />
          {generalContact.contactLinks.map((link) => {
            if (['PHONE', 'FAX'].includes(link.type)) {
              return (
                <Link
                  key={link.label}
                  href={`tel:${link.url}`}
                  variant="inline"
                  sx={{ mt: 0, mb: 1 }}
                >
                  <StyledSpan
                    searchWords={['P ', 'E ', 'F ']}
                    fontStyle="secondary"
                  >
                    {link.label}
                  </StyledSpan>
                </Link>
              );
            } else if (link.type === 'EMAIL') {
              return (
                <Link
                  key={link.label}
                  href={`mailto:${link.url}?subject=Voom™ forward with us`}
                  variant="inline"
                  sx={{ mt: 0, mb: 1 }}
                >
                  <StyledSpan
                    searchWords={['P ', 'E ', 'F ']}
                    fontStyle="secondary"
                  >
                    {link.label}
                  </StyledSpan>
                </Link>
              );
            } else {
              return (
                <Link
                  key={link.label}
                  href={link.url}
                  variant="inline"
                  sx={{ mt: 0, mb: 1 }}
                >
                  <StyledSpan
                    searchWords={['P ', 'E ', 'F ']}
                    fontStyle="secondary"
                  >
                    {link.label}
                  </StyledSpan>
                </Link>
              );
            }
          })}
          <Spacer space={5} />
          {callouts.map((callout) => {
            return (
              <>
                <Divider
                  sx={{
                    borderBottomWidth: '1px',
                    borderColor: 'darkGrey',
                    opacity: 0.3,
                  }}
                />
                <Box sx={{ py: 4 }}>
                  <Heading variant="h5" sx={{ mb: 3 }}>
                    {callout.title}
                  </Heading>
                  <Text as="p">{callout.caption}</Text>
                </Box>
              </>
            );
          })}
        </Box>
      </Flex>
    </Section>
  );
};
