export { h1, h2, h3, h4, h5, h6 } from '../styles';

export default {
  color: 'text',
  fontFamily: 'body',
  lineHeight: 'body',
  fontWeight: 'body',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
};

export const heading = {
  color: 'text',
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontWeight: 'heading',
};

export const body = {
  variant: 'text.default',
};

export const largeP = {
  variant: 'text.body',
  fontSize: 'lg',
  lineHeight: 'bodyNarrow',
  mb: 3,
};

export const mediumP = {
  variant: 'text.body',
  fontSize: 'md',
  mb: 3,
};

export const smallP = {
  variant: 'text.body',
  fontSize: 'md',
  letterSpacing: 4,
  lineHeight: 'bodyNarrow',
  mb: 3,
};

export const copyright = {
  fontSize: 'copyright',
};

export const disclaimer = {
  variant: 'text.copyright',
  color: 'grey500',
};

export const eyeBrow = {
  color: 'pink',
  fontWeight: 'bold',
  letterSpacing: 'snug',
  textTransform: 'uppercase',
  fontSize: 'xs',
  mb: 3,
};

export const contentTitle = {
  variant: 'text.heading',
  fontWeight: 'medium',
  fontSize: ['2xl', null, '4xl', null, '5xl'],
  letterSpacing: 'tightest',
  lineHeight: 'heading',
};

// Used In IconCallout
export const contentTitleLg = {
  variant: 'text.contentTitle',
  fontSize: ['4xl', null, '5xl', null, '6xl'],
};

// Used in Home Hero
export const contentTitleXl = {
  variant: 'text.contentTitle',
  fontSize: ['5xl', null, '6xl', null, '7xl'],
};

export const contentCaptionSm = {
  variant: 'text.body',
  fontSize: ['sm', null, 'md', null, 'lg'],
  lineHeight: 'body',
};

export const contentCaptionMd = {
  variant: 'text.body',
  fontSize: ['md', null, 'md', null, 'xl'],
  lineHeight: 'body',
};

// Used In IconCallout
export const contentCaptionLg = {
  variant: 'text.contentCaption',
  fontSize: ['lg', null, 'xl', null, '2xl'],
};

export const contentTitleEm = {
  fontFamily: 'cursive',
  fontSize: '128%',
  lineHeight: 0, // dont have it affect the line height of the title
};

export const contentCaptionUl = {
  listStyle: 'none',
  ml: 5,
  my: 3,
  p: 0,
};

export const contentCaptionLi = {
  variant: 'text.contentCaption',
  mb: 1,
  position: 'relative',
  lineHeight: 'body',
  '&::before': {
    content: '""',
    bg: 'primary',
    fontWeight: 'bold',
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: 'circle',
    position: 'absolute',
    left: -4,
    top: 2,
  },
};

// Used in Home Hero
export const contentCaptionLiLg = {
  variant: 'text.contentCaption',
  fontWeight: 'medium',
  fontSize: ['md', null, 'md'],
  position: 'relative',
  mb: 1,
  '&::before': {
    content: '""',
    bg: 'primary',
    fontWeight: 'bold',
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: 'circle',
    position: 'absolute',
    left: -4,
    top: 2,
  },
};

export const iconBlockText = {
  variant: 'text.contentCaption',
  fontSize: ['xs', 'md'],
  fontFamily: 'body',
  lineHeight: 'heading',
};

export const severityText = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const sup = {
  font: 'inherit',
  color: 'inherit',
  verticalAlign: 'super',
  fontSize: '65%',
};

export const bunionplastyRow = {
  variant: 'text.contentCaption',
  fontSize: ['xs', null, 'sm'],
  lineHeight: 'heading',
};

export const legalEm = {
  fontFamily: 'body',
  fontStyle: 'italic',
};

export const legalH2 = {
  variant: 'text.heading',
  fontSize: ['xl', null, '3xl'],
};
export const legalH3 = {
  variant: 'text.heading',
  fontSize: ['lg', null, 'xl'],
};
export const legalH4 = {
  variant: 'text.heading',
  fontSize: ['md', null, 'lg'],
};

export const faq = {
  variant: 'text.default',
  fontSize: ['xs', null, 'md'],
};

export const doctorDetails = {
  variant: 'text.faq',
  fontWeight: 'light',
  mb: 2,
};

export const doctorSectionHeading = {
  variant: 'styles.h4',
  fontSize: ['xl', null, '2xl', null, '3xl'],
};

export const doctorDetailsHeading = {
  variant: 'text.doctorDetails',
  fontWeight: 'medium',
  mb: 1,
};

export const doctorCallout = {
  variant: 'text.default',
  fontSize: ['xs', null, 'xl'],
};

export const footerHeading = {
  variant: 'text.default',
  fontSize: 'sm',
  fontWeight: 'semibold',
};
