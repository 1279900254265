export { h1, h2, h3, h4, h5, h6 } from '../styles';

export default {
  color: 'text',
  fontFamily: 'body',
  lineHeight: 'body',
  fontWeight: 'body',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
};

export const heading = {
  color: 'text',
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontWeight: 'heading',
};

export const body = {
  variant: 'text.default',
};

export const largeP = {
  variant: 'text.body',
  fontSize: 'md',
  letterSpacing: 4,
  lineHeight: 'bodyNarrow',
  mb: 3,
};

export const mediumP = {
  variant: 'text.body',
  fontSize: 'sm',
  letterSpacing: 4,
  mb: 3,
};

export const smallP = {
  variant: 'text.body',
  fontSize: 'xs',
  letterSpacing: 4,
  lineHeight: 'bodyNarrow',
  mb: 3,
};

export const copyright = {
  fontSize: 'copyright',
};
export const eyeBrow = {
  variant: 'text.body',
  fontWeight: 'semiBold',
  fontSize: 'copyright',
  letterSpacing: 7,
  textTransform: 'uppercase',
};

export const newsPrestListCardTitle = {
  variant: 'text.h4',
  fontSize: ['2xl', '2xl'],
  sup: {
    fontWeight: 'semiBold',
  },
};

export const homeHeroCaption = {
  variant: 'text.body',
  fontSize: ['sm', 'lg'],
  letterSpacing: 4,
  mb: 3,
};

export const sup = {
  font: 'inherit',
  color: 'inherit',
  verticalAlign: 'super',
  fontSize: '50%',
};

export const legalHeading = {
  variant: 'text.h5',
  mt: [5, null, 7],
  mb: [2, null, 3],
};

export const legalCaption = {
  variant: 'text.mediumP',
  mb: 3,
};

export const legalEm = {
  fontFamily: 'body',
  fontStyle: 'italic',
};

export const legalUl = {
  ml: 4,
  listStyle: 'disc',

  li: {
    mb: 2,
    fontSize: 'xs',
    lineHeight: 'body',
  },
};

export const revConEyebrow = {
  color: 'blue2',
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '14px' /* 116.667% */,
  letterSpacing: '5px',
  textTransform: 'uppercase',
};

export const revConCaption = {
  variant: 'text.body',
  fontSize: ['xs', 'md'],
  letterSpacing: 4,
  lineHeight: 'bodyNarrow',
  mb: 3,
};

export const trainSideBySideBullet = {
  variant: 'text.h6',
  fontSize: ['xs', 'lg'],
};
