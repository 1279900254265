interface FontFaceOptions {
  fontFamily: string;
  fontWeight: string | number;
  fontStyle: string;
  fallback: string;
  fontUrls: {
    src: string;
    format: string;
  }[];
  preload?: boolean;
}

/**
 * Describes a @font-face web font.
 *
 * Stringified, it can be used as a `font-family` value,
 * i.e.,
 *
 *     css`font-family: ${String(fontface)}`
 */
export interface FontFace extends FontFaceOptions {
  toString(): string;
}

export function createFontFace(options: FontFaceOptions): FontFace {
  return Object.assign({}, options, {
    toString() {
      return `${options.fontFamily}, ${options.fallback}`;
    },
  });
}
