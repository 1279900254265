import { PortableTextProps } from '@portabletext/react';

import { Box, Container, Section, Text } from 'voom-gatsby';

import { ContentBlock, TrailingIcon } from '~components';

import { ReactComponent as PeriodIcon } from '~svg/icons/period.svg';

interface LegalTemplateProps {
  title: string;
  effectiveDate: string;
  _rawFirstSection: PortableTextProps['value'];
  _rawContent: PortableTextProps['value'];
}

export const LegalTemplate = ({
  title,
  effectiveDate,
  _rawFirstSection,
  _rawContent,
}: LegalTemplateProps) => {
  return (
    <>
      <Section sx={{ bg: 'background', pt: 6, pb: 8 }}>
        <Container variant="narrowContainer" sx={{ mb: [6, null, 9] }}>
          <Box sx={{ maxWidth: 750 }}>
            <Text
              as="p"
              variant="legalCaption"
              sx={{
                mb: 3,
                fontSize: 'copyright',
                color: 'darkGrey',
                textTransform: 'uppercase',
                letterSpacing: 6,
              }}
            >
              EFFECTIVE {effectiveDate}
            </Text>
            <TrailingIcon
              as="h1"
              Icon={PeriodIcon}
              sx={{
                mb: 4,
                span: {
                  variant: 'styles.h1',
                  fontWeight: 'semiBold',
                  mr: 3,
                  svg: {
                    width: [10, 12],
                    height: [10, 12],
                    bottom: '20%',
                    color: 'secondary',
                    transform: 'translateX(-12px)',
                  },
                },
              }}
            >
              {title}
            </TrailingIcon>
            <ContentBlock content={_rawFirstSection} />
          </Box>
        </Container>
        <Box
          sx={{
            background: (theme) => `${theme.colors?.tealGradient}`,
            pt: 1,
          }}
        >
          <Container variant="narrowContainer">
            <Box sx={{ maxWidth: 750, mt: [6, null, 9] }}>
              <ContentBlock content={_rawContent} />
            </Box>
          </Container>
        </Box>
      </Section>
    </>
  );
};
