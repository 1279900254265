import { graphql } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import {
  Container,
  Flex,
  Grid,
  Heading,
  Paragraph,
  Section,
  Spacer,
  Text,
} from 'voom-gatsby';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';
import { ReactComponent as RevconAnchorIcon } from '~svg/logo/revcon-anchor.svg';
import { ReactComponent as RevconNeutraIcon } from '~svg/logo/revcon-neutra.svg';

export interface RevconTwoScrewsProps {
  title: string;
  cards: {
    eyebrow: string;
    icon: 'NEUTRA' | 'ANCHOR';
    title: string;
    caption: string;
    image: {
      src: ImageDataLike;
      alt: string;
    };
  }[];
}

export const revconTwoScrewsFields = graphql`
  fragment revconTwoScrewsFields on ContentJsonSections {
    revconTwoScrews {
      title
      cards {
        eyebrow
        icon
        title
        caption
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            }
          }
          alt
        }
      }
    }
  }
`;

export const RevconTwoScrews = ({ title, cards }: RevconTwoScrewsProps) => {
  return (
    <Section
      sx={{
        bg: 'white',
        py: 7,
      }}
    >
      <Container
        variant="container"
        sx={{
          background: (theme) => theme.colors?.tealGradientDiagonal,
          boxShadow: 'twoScrewsShadow',
          textAlign: 'center',
          px: [4, 5],
          pt: [7, null, 10],
          pb: 7,
        }}
      >
        <CrossIcon sx={{ width: 35, height: 35 }} />
        <Spacer space={3} />
        <Heading variant="h2" sx={{ maxWidth: 730, mx: 'auto' }}>
          {title}
        </Heading>
        <Spacer space={7} />
        <Grid columns={[1, null, null, 2]} gap={4}>
          {cards.map((card, index) => {
            const firstCard = index === 0;
            const imageData = getImage(card.image.src);
            return (
              <Flex
                key={card.title}
                sx={{
                  bg: 'white',
                  minHeight: [300, null, 400],
                  ...(firstCard
                    ? { pr: 5, flexDirection: 'row-reverse' }
                    : { pl: 5 }),
                }}
              >
                <Flex
                  sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text variant="revConEyebrow">{card.eyebrow}</Text>
                  <Spacer space={2} />
                  {card.icon === 'ANCHOR' ? (
                    <RevconAnchorIcon sx={{ maxWidth: 250 }} />
                  ) : (
                    <RevconNeutraIcon sx={{ maxWidth: 250 }} />
                  )}
                  <Spacer space={[3, 4]} />
                  <Heading variant="h4">{card.title}</Heading>
                  <Spacer space={[3, 4]} />
                  <Paragraph variant="revConCaption">{card.caption}</Paragraph>
                </Flex>
                {imageData && (
                  <GatsbyImage
                    image={imageData}
                    alt={card.image.alt}
                    sx={{ flex: '1 1 auto' }}
                  />
                )}
              </Flex>
            );
          })}
        </Grid>
      </Container>
    </Section>
  );
};
