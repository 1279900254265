import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
  Box,
  Container,
  Heading,
  IntrinsicBox,
  Link,
  Paragraph,
  StyledSpan,
} from 'voom-gatsby';

const NotFoundPage = () => {
  const { image404 } = useStaticQuery(IMAGE_404_QUERY);
  const imageData = getImage(image404);

  return (
    <Box>
      <Container variant="narrowContainer" sx={{ my: [5, null, 8] }}>
        <Heading as="h1" variant="h1" sx={{ mb: [3, null, 5] }}>
          <StyledSpan searchWords={['found.']} fontStyle="secondaryH1">
            Page not found.
          </StyledSpan>
        </Heading>
        <Paragraph sx={{ maxWidth: 860 }}>
          The page you were looking for has changed location or is not
          available. Please visit our{' '}
          <Link to="/" sx={{ color: 'secondary' }}>
            home page
          </Link>{' '}
          to find what you were looking for, and keep Vooming forward.
        </Paragraph>
      </Container>
      <IntrinsicBox
        ratio={[700 / 400, null, 1441 / 600]}
        sx={{
          position: 'relative',
          overflow: 'hidden',
          width: 'full',
          height: 'full',
          mt: [6, null, 10],
        }}
      >
        {imageData && (
          <GatsbyImage
            image={imageData}
            loading="eager"
            alt=""
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: 'full',
              height: 'full',
            }}
          />
        )}
      </IntrinsicBox>
    </Box>
  );
};

export const IMAGE_404_QUERY = graphql`
  query Image404Query {
    image404: file(relativePath: { eq: "404-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`;

export default NotFoundPage;
