import { useState } from 'react';

export type ReturnedHoverProps = {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

export type Props = {
  mouseEnterDelayMS?: number;
  mouseLeaveDelayMS?: number;
};

export const useHover = ({
  mouseEnterDelayMS = 0,
  mouseLeaveDelayMS = 0,
}: Props = {}): [boolean, ReturnedHoverProps] => {
  const [isHovering, setIsHovering] = useState(false);

  let mouseEnterTimer: NodeJS.Timeout;
  let mouseOutTimer: NodeJS.Timeout;

  const hoverProps = {
    onMouseEnter: () => {
      clearTimeout(mouseOutTimer);
      mouseEnterTimer = setTimeout(
        () => setIsHovering(true),
        mouseEnterDelayMS,
      );
    },
    onMouseLeave: () => {
      clearTimeout(mouseEnterTimer);
      mouseOutTimer = setTimeout(() => setIsHovering(false), mouseLeaveDelayMS);
    },
  };

  return [isHovering, hoverProps];
};
