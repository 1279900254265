import { useLocation } from '@reach/router';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Link,
  Spacer,
  Text,
} from '../../components';
import { useIsTouchDevice } from '../../hooks/use-is-touch-device';
import { navStateAtom } from '../../hooks/use-sticky-header';
import { ChevronSvg } from '../../svg/icons';

interface NavDropdownProps {
  link: {
    url: string;
    label: string;
  };
  subLinks: {
    url: string;
    label: string;
    description?: string;
  }[];
}

export const NavDropdown = ({ link, subLinks }: NavDropdownProps) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const isTouchDevice = useIsTouchDevice();
  const [{ isSticky, preventPeekaBoo }, setNavState] = useAtom(navStateAtom);
  /**
   * Handle mouse leave. when mouse leaves the dropdown, set timeout for 250ms
   * If mouse has not re-entered the dropdown, close it. this solves issue
   * with mouse leaving the dropdown when users try to navigate to a category
   * across the page.
   */
  let timer: NodeJS.Timeout | undefined;
  const handleMouseEnter = () => {
    timer && clearTimeout(timer);
    setIsOpen(true);
  };
  const handleMouseLeave = () => {
    timer = setTimeout(() => {
      setIsOpen(false);
    }, 250);
  };

  useEffect(() => {
    setIsOpen(false);
    timer && clearTimeout(timer);
  }, [pathname, timer]);

  useEffect(() => {
    setNavState((state) => ({
      ...state,
      preventPeekaBoo: isOpen,
    }));
  }, [isOpen, setNavState]);

  const subLinkLength = subLinks.length;

  return (
    <Box
      sx={{
        display: ['block', null, null, 'inline-flex'],
        height: [null, null, null, 'full'],
      }}
      onMouseEnter={isTouchDevice ? undefined : () => handleMouseEnter()}
      onMouseLeave={isTouchDevice ? undefined : () => handleMouseLeave()}
    >
      <Button
        variant="navButton"
        className={
          subLinks.some((link) => pathname.includes(link.url)) ? 'active' : ''
        }
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        sx={{
          display: 'inline-flex',
          span: {
            color: isOpen
              ? 'primary'
              : isSticky || preventPeekaBoo
                ? 'navSticky'
                : 'nav',
          },
          ...(isOpen
            ? {
                '&:hover span::after, &.active span::after': {
                  bg: 'primary',
                },
              }
            : {}),
        }}
      >
        <Flex as="span" sx={{ alignItems: 'center' }}>
          {link.label}
          <Spacer direction="horizontal" space={2} />
          <ChevronSvg
            sx={{
              width: [16, null, null, 12],
              height: [16, null, null, 12],
              transform: isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)',
              transition: 'transform 0.2s ease-in',
            }}
          />
        </Flex>
      </Button>
      <AnimatePresence>
        {isOpen && (
          <>
            {/* DESKTOP */}
            <motion.div
              initial="hide"
              animate={isOpen ? 'show' : 'hide'}
              exit="hide"
              variants={{
                hide: {
                  transform: 'scaleY(0)',
                  opacity: 0,
                },
                show: {
                  transform: 'scaleY(1)',
                  opacity: 1,
                },
              }}
              sx={{
                transformOrigin: 'top center',
                zIndex: -1,
                display: ['none', null, null, 'block'],
                height: 'fit-content',
                py: 7,
                position: 'fixed',
                inset: 0,
                top: '100%',
                bg: 'white',
                boxShadow: 'navShadow',
              }}
            >
              <Container>
                <Grid columns={subLinkLength === 4 ? 4 : 3} gap={'40px'}>
                  {subLinks.map(({ url, label, description }) => {
                    return (
                      <Link
                        key={url}
                        href={url}
                        onClick={() => setIsOpen(false)}
                        sx={{
                          textDecoration: 'none',
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative',
                          pt: 140,
                          pr: 40,
                          borderRight: (theme) =>
                            `1px solid ${theme.colors?.grey}`,
                          '> span': { color: 'black' },
                          '&:hover > span:first-of-type': {
                            color: 'primary',
                          },
                        }}
                      >
                        <Text sx={{ display: 'block', fontWeight: 'semibold' }}>
                          {label}
                        </Text>
                        {description && (
                          <Text sx={{ display: 'block' }}>{description}</Text>
                        )}
                      </Link>
                    );
                  })}
                </Grid>
              </Container>
            </motion.div>

            {/* Mobile */}
            <Flex
              sx={{
                pt: 3,
                display: ['flex', null, null, 'none'],
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {subLinks.map(({ url, label }) => {
                return (
                  <Link
                    key={url}
                    variant="nav"
                    className={pathname === url ? 'active' : ''}
                    href={url}
                    sx={{ color: 'black', display: 'block', py: 2 }}
                  >
                    {label}
                  </Link>
                );
              })}
              <Divider
                sx={{
                  borderBottomWidth: '2px',
                  borderColor: 'lightGrey',
                  mt: 3,
                  opacity: 0.8,
                  width: 'full',
                }}
              />
            </Flex>
          </>
        )}
      </AnimatePresence>
    </Box>
  );
};
