import { keyframes } from '@emotion/react';

import { underlineStyling } from './links';

export default {
  fontFamily: 'button',
  cursor: 'pointer',
};

export const primary = {
  variant: 'buttons.default',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: 'sm',
  fontWeight: 'semiBold',
  borderRadius: 'rounded',
  px: 5,
  lineHeight: 'none',
  height: 50,
  cursor: 'pointer',
  bg: 'buttonBg',
  color: 'buttonColor',
  span: {
    color: 'buttonColor',
  },
  boxShadow: 'buttonShadow',
  transition:
    'transform 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '@media (hover: hover)': {
    ':hover': {
      bg: 'buttonHoverBG',
      transform: 'scale(1.02) translateY(-2px)',
      boxShadow: 'buttonShadowHover',
    },
  },
  '&.active': {
    bg: 'buttonHoverBG',
    transform: 'scale(1.02) translateY(-2px)',
    boxShadow: 'buttonShadowHover',
  },
  '&[disabled]': {
    bg: 'grey300',
    cursor: 'not-allowed',
  },
};

export const icon = {
  variant: 'buttons.default',
  bg: 'transparent',
  cursor: 'pointer',
  fontSize: '0px',
  appearance: 'none',
  boxShadow: 'iconShadow',
  borderRadius: 'circle',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 50,
  svg: {
    color: 'primary',
    transition: 'color 0.3s ease-in-out',
    width: '40%',
    height: '40%',
  },
  '@media (hover: hover)': {
    ':hover': {
      svg: {
        color: 'buttonHoverBG',
      },
    },
  },
};

export const iconMenu = {
  variant: 'buttons.icon',
  flex: '0 0 40px',
  width: 40,
  height: 40,
  p: 0,
};

export const iconGhost = {
  variant: 'buttons.icon',
  boxShadow: 'none',
  borderRadius: 'square',
  svg: {
    color: 'primary',
    transition: 'color 0.3s ease-in-out',
    width: 'full',
    height: 'full',
  },
};

export const iconOutline = {
  variant: 'buttons.icon',
  borderRadius: 'rounded',
  bg: 'transparent',
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'red',
  color: 'red',
  svg: {
    color: 'red',
    transition: 'color 0.3s ease-in-out',
    width: 18,
    height: 18,
  },
  transition:
    'transform 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '@media (hover: hover)': {
    ':hover': {
      bg: 'red',
      color: 'white',
      svg: {
        color: 'white',
      },
    },
  },
  '&.active': {
    bg: 'pink',
    color: 'white',
    borderColor: 'pink',
  },
};

export const iconPrimary = {
  variant: 'buttons.icon',
  bg: 'primary',
  svg: {
    color: 'white',
    transition: 'color 0.3s ease-in-out',
    width: 20,
    height: 20,
  },
  '@media (hover: hover)': {
    transition: 'background-color 0.3s ease-in-out',

    ':hover': {
      bg: 'buttonHoverBG',
    },
  },
  '&[disabled]': {
    bg: 'grey300',
    cursor: 'not-allowed',
  },
};

export const slickArrow = {
  variant: 'buttons.icon',
  position: 'absolute',
  width: [40, 65],
  height: [40, 65],
  top: '50%',
  bg: 'white',
  transform: 'translateY(-50%)',
  zIndex: 'overlay',
  svg: {
    color: 'primary',
    transition: 'color 0.3s ease-in-out',
    width: [16, 25],
    height: [16, 25],
  },
};

export const slickArrowSm = {
  variant: 'buttons.icon',
  position: 'absolute',
  width: 30,
  height: 30,
  top: '50%',
  bg: 'white',
  transform: 'translateY(-50%)',
  zIndex: 'overlay',
  p: 0,
  svg: {
    color: 'primary',
    transition: 'color 0.3s ease-in-out',
    width: 13,
    height: 13,
  },
  '&.slick-disabled': {
    svg: {
      color: 'grey300',
    },
  },
};

export const ghost = {
  bg: 'transparent',
  p: 0,
  cursor: 'pointer',
  border: 'none',
};

export const navButton = {
  variant: 'links.nav',
  bg: 'transparent',
  p: 0,
  borderRadius: 0,
  '> span': {
    color: 'text',
    whiteSpace: 'nowrap',
    fontWeight: 'semiBold',
    fontSize: 'sm',
    position: 'relative',
    lineHeight: 1.15, // to match the underline on nav link
    pb: 1,
  },
  '&:hover > span::after, &.active  > span::after': {
    ...underlineStyling,
    content: ['none', null, null, '""'],
    // Underline hover animation
    animation: `${keyframes`
      0% {
        transform-origin: left;
        transform: scaleX(0);
      }

      100% {
        transform-origin: left;
        transform: scaleX(1);
      }
    `} 0.3s ease-in-out`,
  },
  '&.active::after': {
    animation: 'none',
  },
};

export const underline = {
  variant: 'links.underline',
  bg: 'transparent',
  p: 0,
};

export const outline = {
  variant: 'buttons.default',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: 'sm',
  fontWeight: 'semiBold',
  borderRadius: 'rounded',
  px: 5,
  height: 50,
  cursor: 'pointer',
  bg: 'transparent',
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'red',
  color: 'red',
  span: {
    font: 'inherit',
    color: 'red',
  },
  transition:
    'transform 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '@media (hover: hover)': {
    ':hover': {
      bg: 'red',
      color: 'white',
      span: {
        font: 'inherit',
        color: 'white',
      },
    },
  },
  '&.active': {
    bg: 'pink',
    color: 'white',
    borderColor: 'pink',
  },
};

export const activeFilter = {
  variant: 'buttons.outline',
  display: 'grid',
  gridTemplateColumns: '1fr 25px',
  textAlign: 'left',
  alignItems: 'center',
  borderColor: 'black',
  color: 'black',
  height: 45,
  px: 3,
  span: {
    font: 'inherit',
    color: 'black',
  },
  svg: {
    justifySelf: 'end',
    width: 12,
    height: 12,
    g: { stroke: 'black' },
  },
  transition:
    'transform 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '@media (hover: hover)': {
    ':hover': {
      bg: 'white',
      color: 'black',
      span: {
        font: 'inherit',
        color: 'black',
      },
    },
  },
  '&.active': {
    bg: 'pink',
    color: 'white',
    borderColor: 'pink',
  },
};

export const filterItem = {
  variant: 'buttons.ghost',
  my: 2,
  display: 'flex',
  color: 'black',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 'full',
  whiteSpace: 'nowrap',
  svg: {
    width: 12,
    height: 12,
    color: 'pink',
  },
};

/** Not Used on Bunionplasty */
export const inverted = {
  variant: 'buttons.primary',
};
