import {
  IGatsbyImageData,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image';
import invariant from 'invariant';

import { useThemeUI } from '../components';

interface GetImageFragmentProps {
  image?: IGatsbyImageData | null;
  breakpoint: string;
}

const getImageFragment = ({ image, breakpoint }: GetImageFragmentProps) => {
  const gatsbyImage = image && getImage(image);
  return gatsbyImage
    ? [
        {
          image: gatsbyImage,
          media: `(min-width: ${breakpoint})`,
        },
      ]
    : undefined;
};

/**
 * Use Image Sources
 *
 * This helper function hook will return an array of Gatsby Fluid objects
 * at our various theme ui breakpoints.
 *
 * first image in the array is requires, rest can be null if no image is needed
 * for a certain theme-ui breakpoint
 *
 */
export const useImageSources = (
  images: (IGatsbyImageData | null | undefined)[],
) => {
  const { theme } = useThemeUI();
  invariant(
    images[0],
    'useImageSources requires first item in images array to be not null',
  );
  const smImage = getImage(images[0]);
  invariant(smImage, 'Valid smImage sm image required');

  const xxlImage = getImageFragment({
    image: images[4],
    breakpoint: String(theme.breakpoints?.[3]),
  });
  const xlImage = getImageFragment({
    image: images[3],
    breakpoint: String(theme.breakpoints?.[2]),
  });
  const lgImage = getImageFragment({
    image: images[2],
    breakpoint: String(theme.breakpoints?.[1]),
  });
  const mdImage = getImageFragment({
    image: images[1],
    breakpoint: String(theme.breakpoints?.[0]),
  });
  return withArtDirection(smImage, [
    ...(xxlImage ? xxlImage : []),
    ...(xlImage ? xlImage : []),
    ...(lgImage ? lgImage : []),
    ...(mdImage ? mdImage : []),
  ]);
};
