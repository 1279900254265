import type { GatsbyBrowser } from 'gatsby';

import { PageLayout } from '~layouts';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  return <PageLayout {...props}>{element}</PageLayout>;
};
