import { graphql } from 'gatsby';

import {
  Container,
  Flex,
  Link,
  Section,
  StyledSpan,
  StyledSup,
  Text,
} from 'voom-gatsby';

import { ReactComponent as PatternSvgBackground } from '~images/pattern.svg';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';
import { ReactComponent as BunionplastyLogo } from '~svg/logo/bunionplasty.svg';
import { ReactComponent as RevconLogo } from '~svg/logo/revcon.svg';

interface PatternCalloutProps {
  caption: string;
  links: {
    label: string;
    url: string;
  }[];
}

export const patternCalloutFields = graphql`
  fragment patternCalloutFields on ContentJsonSections {
    patternCallout {
      caption
      links {
        label
        url
      }
    }
  }
`;
export const PatternCallout = ({ caption, links }: PatternCalloutProps) => {
  return (
    <Section
      theme="blue"
      sx={{
        position: 'relative',
        width: 'full',
        backgroundImage: `url("${PatternSvgBackground}")`,
        overflow: 'hidden',
      }}
    >
      <Container
        sx={{
          maxWidth: 715,
          textAlign: 'center',
          py: 8,
          zIndex: 'content',
          position: 'relative',
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <RevconLogo
            sx={{
              width: [144, 162],
              height: 'auto',
              path: { fill: 'white' },
            }}
          />
          <CrossIcon sx={{ width: 20, height: 20, m: 4, mb: [2, 4] }} />
          <BunionplastyLogo
            sx={{
              width: [180, 230],
              height: 'auto',
              color: 'bunionplasty',
            }}
          />
        </Flex>
        <Text
          as="p"
          variant="h6"
          sx={{
            fontSize: ['sm', 'lg'],
            letterSpacing: 4,
            lineHeight: 'body',
            fontWeight: 'regular',
            my: 4,
          }}
        >
          <StyledSpan
            searchWords={['Revcon™ screw system', 'Bunionplasty® procedure']}
          >
            {caption}
          </StyledSpan>
        </Text>
        <Flex sx={{ mt: 5, justifyContent: 'center' }}>
          {links.map(({ url, label }) => {
            return (
              <Link
                variant="button"
                key={label}
                href={url}
                sx={{ width: ['full', 'auto'] }}
              >
                <StyledSup sx={{ sup: { color: 'inherit' } }}>
                  {label}
                </StyledSup>
              </Link>
            );
          })}
        </Flex>
      </Container>
    </Section>
  );
};
