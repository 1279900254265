import { graphql, useStaticQuery } from 'gatsby';

import { SEO } from 'voom-gatsby';

import { LegalTemplate } from '~sections/LegalTemplate';

export const TERMS_OF_USE_QUERY = graphql`
  query TermsOfUse {
    sanityVoomLegal(title: { eq: "Terms of Use" }) {
      id
      title
      effectiveDate(formatString: "MMMM DD, YYYY")
      _rawFirstSection
      _rawContent
    }
  }
`;

const TermsOfUse = () => {
  const data = useStaticQuery(TERMS_OF_USE_QUERY);

  return <LegalTemplate {...data.sanityVoomLegal} />;
};

export const Head = () => {
  return (
    <SEO
      title="Terms of Use"
      description="Dr. Neal Blitz is the surgeon innovator behind Voom, a medical device company revolutionizing surgical products for Bunionplasty™, bunion repair surgery, and foot and ankle surgery."
    />
  );
};

export default TermsOfUse;
