import { graphql } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Section,
  Spacer,
  Text,
} from 'voom-gatsby';

import { ReactComponent as RevconAnchorIcon } from '~svg/logo/revcon-anchor.svg';
import { ReactComponent as RevconNeutraIcon } from '~svg/logo/revcon-neutra.svg';

interface RevconTableProps {
  title: string;
  toolTrayImage: {
    src: ImageDataLike;
    alt: string;
  };
  table: {
    title: string;
    caption: string;
    count: number;
  }[];
  cards: {
    icon: 'NEUTRA' | 'ANCHOR';
    mobileImage: {
      src: ImageDataLike;
      alt: string;
    };
    desktopImage: {
      src: ImageDataLike;
      alt: string;
    };
    callouts: string[];
  }[];
}

export const revconTableFields = graphql`
  fragment revconTableFields on ContentJsonSections {
    revconTable {
      title
      toolTrayImage {
        alt
        src {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
      table {
        title
        caption
        count
      }
      cards {
        icon
        mobileImage {
          alt
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
        desktopImage {
          alt
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
        callouts
      }
    }
  }
`;

export const RevconTable = ({
  title,
  toolTrayImage,
  table,
  cards,
}: RevconTableProps) => {
  const toolTrayImageData = getImage(toolTrayImage.src);

  return (
    <Section
      theme="base"
      backgroundColor="tealGradient"
      sx={{
        py: 10,
        background: (theme) => `${theme.colors?.tealGradient}`,
      }}
    >
      <Container>
        <Heading
          variant="h5"
          sx={{
            textAlign: 'left',
            fontWeight: 'medium',
            mb: [5, 6],
          }}
        >
          {title}
        </Heading>
        <Grid
          sx={{
            gridTemplateColumns: ['1fr', null, null, '460px 1fr', '560px 1fr'],
            bg: 'white',
            p: [4, null, 6],
            boxShadow: 'tableShadow',
          }}
        >
          <Box>
            {toolTrayImageData && (
              <GatsbyImage
                image={toolTrayImageData}
                alt={toolTrayImage.alt}
                sx={{ mb: [4, null, 0], maxWidth: 600, mx: 'auto' }}
              />
            )}
          </Box>
          <Box>
            {table.map((row, index) => {
              return (
                <Flex
                  key={`${row.title}-${index}`}
                  sx={{
                    alignItems: 'flex-start',
                    py: 2,
                    px: 3,
                    borderBottom: (theme) =>
                      `1px solid ${theme?.colors?.lightGrey}`,
                    ':first-of-type': {
                      borderTop: (theme) =>
                        `1px solid ${theme?.colors?.lightGrey}`,
                    },
                  }}
                >
                  <Text
                    variant="smallP"
                    sx={{
                      m: 0,
                      color: 'darkGrey',
                      flex: [
                        '0 0 100px',
                        '0 0 120px',
                        null,
                        null,
                        null,
                        '0 0 180px',
                      ],
                    }}
                  >
                    {row.title}
                  </Text>
                  <Text
                    variant="smallP"
                    sx={{
                      m: 0,
                      mr: 2,
                      flex: '1 1 auto',
                    }}
                  >
                    {row.caption}
                  </Text>
                  <Text
                    variant="smallP"
                    sx={{
                      m: 0,
                      ml: 'auto',
                      flex: '1 1 30px',
                      textAlign: 'right',
                    }}
                  >
                    {row.count}
                  </Text>
                </Flex>
              );
            })}
          </Box>
        </Grid>
        <Spacer space={3} />
        <Grid sx={{ gridTemplateColumns: ['1fr', null, '75fr 100fr'] }} gap={3}>
          {cards.map((card) => {
            const imageData = getImage(card.desktopImage.src);
            return (
              <Box
                key={card.icon}
                sx={{
                  bg: 'white',
                  p: [4, null, 6],
                  boxShadow: 'tableShadow',
                }}
              >
                {card.icon === 'ANCHOR' ? (
                  <RevconAnchorIcon sx={{ maxWidth: 250 }} />
                ) : (
                  <RevconNeutraIcon sx={{ maxWidth: 250 }} />
                )}
                {imageData && (
                  <GatsbyImage image={imageData} alt={card.desktopImage.alt} />
                )}
                {/* TODO: Re-add if content is provided */}
                {/* {card.callouts.map((callout, index) => {
                  const lastCallout = index === card.callouts.length - 1;
                  return (
                    <>
                      <Paragraph
                        key={callout}
                        variant="smallP"
                        sx={{ m: 0, color: 'darkGrey', textAlign: 'center' }}
                      >
                        {callout}
                      </Paragraph>
                      {!lastCallout && <Divider />}
                    </>
                  );
                })} */}
              </Box>
            );
          })}
        </Grid>
      </Container>
    </Section>
  );
};
