import { graphql, useStaticQuery } from 'gatsby';

import {
  Container,
  Flex,
  Heading,
  Link,
  Section,
  Slider,
  Spacer,
  ThemeUIStyleObject,
  useThemeUI,
} from 'voom-gatsby';

import { NewsPressListCard, NewsPressListCardProps } from '~components';

export const ALL_VOOM_ARTICLES_SECTION_QUERY = graphql`
  query AllVoomArticles {
    allSanityVoomArticle(sort: { Date: DESC }) {
      nodes {
        Date(formatString: "MMMM DD, YYYY")
        publication
        featured
        title
        slug {
          current
        }
        externalLink
        id
        description
        _rawContent
        heroImage {
          asset {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
          }
        }
      }
    }
  }
`;

interface NewsPressListProps {
  /** used to render a callout section of featured events and link to new-events page */
  featured?: boolean;
  sx?: ThemeUIStyleObject;
  className?: string;
}
export const NewsPressList = ({
  featured = false,
  sx,
  className,
}: NewsPressListProps) => {
  const data = useStaticQuery(ALL_VOOM_ARTICLES_SECTION_QUERY);
  const articles: NewsPressListCardProps[] = data.allSanityVoomArticle.nodes;
  const { theme } = useThemeUI();

  const featuredArticles = articles.filter(
    (article) => article.featured === true && article.heroImage !== null,
  );
  const otherArticles = articles.filter(
    (article) => article.featured !== true || article.heroImage === null,
  );
  return (
    <Section
      as={featured ? 'section' : 'div'}
      theme="base"
      className={className}
      sx={{ py: [5, 6], ...sx }}
    >
      <Container>
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Heading
            variant="h5"
            sx={{
              textAlign: 'left',
              fontWeight: 'medium',
              mb: 3,
            }}
          >
            News + Press Releases
          </Heading>
          {featured && (
            <Link variant="underline" to="/about-us/news-events" sx={{ mb: 3 }}>
              View All News and Events
            </Link>
          )}
        </Flex>
        <Spacer space={[4, 5]} />
        <Slider
          settings={{
            infinite: false,
            slidesToShow: 3,
            responsive: [
              {
                breakpoint: parseInt(theme.breakpoints?.[2] ?? ''),
                settings: { slidesToShow: 2 },
              },
              {
                breakpoint: parseInt(theme.breakpoints?.[1] ?? ''),
                settings: { slidesToShow: 2 },
              },
              {
                breakpoint: parseInt(theme.breakpoints?.[0] ?? ''),
                settings: {
                  slidesToShow: 1,
                  centerMode: true,
                  centerPadding: `${theme.space?.[3] ?? 16}px`,
                },
              },
            ],
          }}
          mobileGradient={false}
        >
          {featuredArticles.map((card, index) => {
            return (
              <NewsPressListCard key={card.title} {...card} index={index} />
            );
          })}
        </Slider>
      </Container>
      {!featured && otherArticles.length > 0 && (
        <>
          <Spacer space={10} />
          <Container>
            {otherArticles.map((card, index) => {
              return (
                <NewsPressListCard
                  listLayout={true}
                  key={card.title}
                  {...card}
                  index={index}
                />
              );
            })}
          </Container>
        </>
      )}
    </Section>
  );
};
