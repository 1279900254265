import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import {
  Divider,
  Flex,
  Heading,
  Link,
  StyledSup,
  Text,
  TextSpacer,
} from 'voom-gatsby';

import { TrailingIcon } from '~components';

import { ReactComponent as ArrowIcon } from '~svg/icons/arrow.svg';
import { ReactComponent as ExternalLinkIcon } from '~svg/icons/external-link.svg';

export interface NewsPressListCardProps {
  Date: string;
  publication: string;
  title: string;
  description: string;
  externalLink?: string;
  slug: {
    current: string;
  };
  index: number;
  heroImage: {
    asset: ImageDataLike;
  } | null;
  listLayout?: boolean;
  featured: boolean | null;
}

export const NewsPressListCard = ({
  Date,
  publication,
  title,
  description,
  slug,
  externalLink,
  heroImage,
  index,
  listLayout = false,
}: NewsPressListCardProps) => {
  const articleImage = getImage(heroImage?.asset ?? null);
  const dividerPadding = 8;
  if (listLayout) {
    return (
      <>
        {index === 0 && <Divider sx={{ color: 'muted', opacity: 1, my: 6 }} />}
        <Link
          to={externalLink ?? `/about-us/news-events/${slug.current}`}
          sx={{
            textDecoration: 'none',
            '&:hover h2': { textDecoration: 'underline' },
          }}
        >
          <Flex
            as="p"
            sx={{
              alignItems: 'center',
              mb: 3,
              flexWrap: 'wrap',
              span: { color: 'secondary' },
            }}
          >
            <Text variant="eyeBrow">{Date}</Text>
            <TextSpacer sx={{ ':not(:last-child)::after': { mx: 3 } }} />
            <Text variant="eyeBrow">{publication}</Text>
          </Flex>
          <Heading variant="h5" sx={{ mb: 0 }}>
            <StyledSup>{title}</StyledSup>
          </Heading>
        </Link>
        <Divider sx={{ color: 'muted', opacity: 1, my: 6 }} />
      </>
    );
  }
  return (
    <Link
      to={externalLink ?? `/about-us/news-events/${slug.current}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: 'full',
        textDecoration: 'none',
        '&:hover h2': { textDecoration: 'underline' },
        pr: dividerPadding,
        position: 'relative',
        ':after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: (theme) =>
            `${(theme.space?.[dividerPadding] as number) / 2 - 1}px`,
          width: 2,
          height: 'full',
          bg: 'muted',
        },
      }}
    >
      {articleImage && (
        <GatsbyImage
          image={articleImage}
          alt={title}
          sx={{
            mb: 4,
            borderRadius: 'circle',
            img: { borderRadius: 'circle' },
          }}
        />
      )}
      <Flex
        as="p"
        sx={{
          alignItems: 'center',
          mb: 3,
          flexWrap: 'wrap',
          span: { color: 'secondary' },
        }}
      >
        <Text variant="eyeBrow">{Date}</Text>
        <TextSpacer sx={{ ':not(:last-child)::after': { mx: 3 } }} />
        <Text variant="eyeBrow">{publication}</Text>
      </Flex>
      <Heading variant="h4" sx={{ mb: 4 }}>
        <StyledSup>{title}</StyledSup>
      </Heading>
      <TrailingIcon
        Icon={externalLink ? ExternalLinkIcon : ArrowIcon}
        sx={{
          variant: 'text.largeP',
          svg: { width: externalLink ? 18 : 22, ml: 2 },
        }}
      >
        <StyledSup>{description}</StyledSup>
      </TrailingIcon>
    </Link>
  );
};
