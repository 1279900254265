import { forwardRef } from 'react';
import * as ThemeUI from 'theme-ui';

import * as bunionplastyButtonVariants from '../themes/bunionplasty/variants/buttons';
import * as bunionplastyLayoutVariants from '../themes/bunionplasty/variants/layouts';
import * as bunionplastyTextVariants from '../themes/bunionplasty/variants/text';
import * as voomLayoutVariants from '../themes/voom/variants/layouts';
import * as voomTextVariants from '../themes/voom/variants/text';
import * as voomButtonVariants from '../themes/voom/variants/variants';

export type ThemeStyles = ThemeUI.ThemeStyles;
export type ThemeUITheme = ThemeUI.Theme;
export type ThemeUIStyleObject = ThemeUI.ThemeUIStyleObject;

export const jsx = ThemeUI.jsx;
export const ThemeUIProvider = ThemeUI.ThemeUIProvider;
export const useThemeUI = ThemeUI.useThemeUI;
export type BoxProps = ThemeUI.BoxProps;
export type ResponsiveStyleValue<T> = ThemeUI.ResponsiveStyleValue<T>;
export const Box = ThemeUI.Box;
export const Grid = ThemeUI.Grid;
export const Flex = ThemeUI.Flex;

export const Close = ThemeUI.Close;
export const Divider = ThemeUI.Divider;
export const Donut = ThemeUI.Donut;
// export const Alert = ThemeUI.Alert;
export const Spinner = ThemeUI.Spinner;

export const Image = ThemeUI.Image;
export const AspectRatio = ThemeUI.AspectRatio;
export const AspectImage = ThemeUI.AspectImage;

export const Input = ThemeUI.Input;
export const Label = ThemeUI.Label;
export const Checkbox = ThemeUI.Checkbox;
export type CheckboxProps = ThemeUI.CheckboxProps;
export const Radio = ThemeUI.Radio;
export const Textarea = ThemeUI.Textarea;
export const Select = ThemeUI.Select;

/**
 * Theme Ui button component.
 *
 * Button style variants can be defined in the theme/variants/buttons.ts.
 * @see https://theme-ui.com/components/button
 */
export interface ButtonProps extends Omit<ThemeUI.ButtonProps, 'variant'> {
  /** Variants pulled directly from the button variants in our theme */
  variant?:
    | keyof typeof voomButtonVariants
    | keyof typeof bunionplastyButtonVariants;
}
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return <ThemeUI.Button {...props} ref={ref} />;
  },
);

Button.displayName = 'Button';
/**
 * Theme Ui  icon button component.
 *
 * Button style variants can be defined in the theme/variants/buttons.ts.
 * @see https://theme-ui.com/components/icon-button
 */
export interface IconButtonProps
  extends Omit<ThemeUI.IconButtonProps, 'variant'> {
  /** Variants pulled directly from the button variants in our theme */
  variant?:
    | keyof typeof voomButtonVariants
    | keyof typeof bunionplastyButtonVariants;
}
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    return <ThemeUI.IconButton {...props} ref={ref} />;
  },
);

IconButton.displayName = 'IconButton';

/**
 * Theme Ui  layout component.
 *
 * Layout style variants can be defined in the theme/variants/layout.ts.
 * @see https://theme-ui.com/components/container
 */
export interface ContainerProps
  extends Omit<ThemeUI.ContainerProps, 'variant'> {
  /** Variants pulled directly from the layout variants in our theme */
  variant?:
    | keyof typeof voomLayoutVariants
    | keyof typeof bunionplastyLayoutVariants;
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (props, ref) => {
    return <ThemeUI.Container {...props} ref={ref} />;
  },
);

Container.displayName = 'Container';

/**
 * Theme Ui  typographic component.
 *
 * Heading style variants can be defined in the theme/variants/text.ts.
 * @see https://theme-ui.com/components/heading
 */
export interface HeadingProps extends Omit<ThemeUI.HeadingProps, 'variant'> {
  /** Variants pulled directly from the text variants in our theme */
  variant?:
    | keyof typeof voomTextVariants
    | keyof typeof bunionplastyTextVariants;
}

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  (props, ref) => {
    return <ThemeUI.Heading {...props} ref={ref} />;
  },
);

Heading.displayName = 'Heading';

/**
 * Theme Ui  typographic component.
 *
 * Text style variants can be defined in the theme/variants/text.ts.
 * @see https://theme-ui.com/components/text
 */
export interface TextProps extends Omit<ThemeUI.TextProps, 'variant'> {
  /** Variants pulled directly from the text variants in our theme */
  variant?:
    | keyof typeof voomTextVariants
    | keyof typeof bunionplastyTextVariants;
}

export const Text = forwardRef<HTMLDivElement, TextProps>((props, ref) => {
  return <ThemeUI.Text as={props.as ?? 'span'} {...props} ref={ref} />;
});

Text.displayName = 'Text';

/**
 * Theme Ui  typographic component.
 *
 * Text style variants can be defined in the theme/variants/text.ts.
 * @see https://theme-ui.com/components/text
 */
export interface ParagraphProps
  extends Omit<ThemeUI.ParagraphProps, 'variant'> {
  /** Variants pulled directly from the text variants in our theme */
  variant?:
    | keyof typeof voomTextVariants
    | keyof typeof bunionplastyTextVariants;
}

export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
  (props, ref) => {
    return <ThemeUI.Paragraph {...props} ref={ref} />;
  },
);

Paragraph.displayName = 'Paragraph';
