import { graphql } from 'gatsby';
import { InlineWidget } from 'react-calendly';

import {
  Container,
  Flex,
  Heading,
  Paragraph,
  Section,
  Spacer,
} from 'voom-gatsby';

import { TrainLocation } from '~templates/train-template';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';

export interface TrainCalendarProps {
  title: string;
  caption: string;
  location?: TrainLocation;
  calendlyUrl: Record<TrainLocation | 'DEFAULT', string>;
}

export const TrainCalendar = ({
  title,
  caption,
  location,
  calendlyUrl,
}: TrainCalendarProps): JSX.Element => {
  // Currently we are using the same url for all locations in JSON, but keeping the ability to change in case we need to change it in the future
  const url = calendlyUrl[location ?? 'DEFAULT'];
  return (
    <Section
      id="calendar"
      theme="base"
      sx={{
        py: [4, null, null, 8],
        bg: 'transparent',
      }}
    >
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: 600,
          mx: 'auto',
          mb: 7,
          px: [3, 4],
        }}
      >
        <CrossIcon sx={{ width: 32, height: 32 }} />
        <Spacer space={3} />
        <Heading
          variant="h2"
          sx={{
            textAlign: 'center',
            my: 3,
          }}
        >
          {title}
        </Heading>
        <Spacer space={3} />
        <Paragraph variant="largeP" sx={{ my: 3, textAlign: 'center' }}>
          {caption}
        </Paragraph>
      </Flex>
      <Container
        sx={{
          maxWidth: 1200,
          width: ['auto', null, 'full'],
          bg: 'white',
          mx: [3, null, 'auto'],
          px: [0, null, 4],
          boxShadow: 'calendlyShadow',
          '> div:first-of-type': {
            // Attempt to match sizing of calendly widget to match design
            minHeight: [1300, 1300, 1300, 850].map((n) => `${n}px !important`),
            height: [1300, 1300, 1300, 850].map((n) => `${n}px !important`),
          },
        }}
      >
        <InlineWidget url={url} />
      </Container>
    </Section>
  );
};

export const trainCalendarFields = graphql`
  fragment trainCalendarFields on ContentJsonSections {
    trainCalendar {
      title
      caption
      calendlyUrl {
        DEFAULT
        BEVERLY_HILLS
        NYC_METRO_AREA
        YOUNGSTOWN
      }
    }
  }
`;
