import { captureException, withScope } from '@sentry/gatsby';
import type { PropsWithoutRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ParallaxProvider } from 'react-scroll-parallax';
import { QueryParamProvider } from 'use-query-params';
import { ReachAdapter } from 'use-query-params/adapters/reach';

import { ResetCss } from '../ResetCss';
import { BoxProps } from '../components';
import { FontFacesLoader } from '../fonts/FontFacesLoader';
import { useIpGeo } from '../hooks/use-ip-geo';

export const logErrorMessage = (error: Error) => {
  const extras = {
    error: error.message,
    location: window.location.href,
    component: 'ErrorBoundary',
  };
  withScope((scope) => {
    scope.setTag('ErrorBoundary', true);
    scope.setExtras(extras);
    captureException(error);
  });
};

const Fallback = () => {
  return (
    <>
      <p>Unknown Error. Please refresh and retry!</p>
      <p>
        If this problem persists, please reach out for support. We apologize for
        any inconvenience.
      </p>
    </>
  );
};

export const RootLayout = ({
  children,
}: PropsWithoutRef<BoxProps>): JSX.Element => {
  useIpGeo();

  return (
    <ErrorBoundary FallbackComponent={Fallback} onError={logErrorMessage}>
      <ResetCss />
      <FontFacesLoader />
      <QueryParamProvider adapter={ReachAdapter}>
        <ParallaxProvider>{children}</ParallaxProvider>
      </QueryParamProvider>
    </ErrorBoundary>
  );
};
