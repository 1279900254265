import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';

import { PreloadFontLinks } from '../fonts/FontFacesLoader';

export interface Meta {
  name: string;
  content: string;
}

export interface SeoProps {
  title?: string;
  description?: string;
  useSiteTitle?: boolean;
  lang?: string;
  ogImage?: string;
  meta?: Meta[];
}

const DEFAULT_META_QUERY = graphql`
  query DefaultMeta {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    voomSiteSettings: sanitySiteSettings(
      _id: { eq: "c4b03ae9-68ee-4852-9ee5-010c514d00ba" }
    ) {
      openGraph {
        image {
          asset {
            url
          }
        }
      }
    }
    bunionplastySiteSettings: sanitySiteSettings(
      _id: { eq: "e983f7e8-8b28-486d-a3d0-8e3d066bd206" }
    ) {
      openGraph {
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

export const SEO = ({
  title,
  description,
  ogImage,
  useSiteTitle,
  lang = `en`,
  meta = [],
}: SeoProps): JSX.Element => {
  const { site, voomSiteSettings, bunionplastySiteSettings } =
    useStaticQuery(DEFAULT_META_QUERY);

  const metaDescription = description ?? site?.siteMetadata?.description ?? '';

  const { pathname } = useLocation();

  const url = `${site?.siteMetadata?.siteUrl}${pathname}`;

  const ogImageUrl =
    ogImage ?? process.env.THEME === 'voom'
      ? voomSiteSettings.openGraph.image.asset.url
      : bunionplastySiteSettings.openGraph.image.asset.url;

  const getMetaTitle = (title: string) => {
    return useSiteTitle
      ? title
      : `${title} ${
          site?.siteMetadata?.title ? `| ${site.siteMetadata.title}` : ''
        }`;
  };
  return (
    <>
      <html lang={lang} />
      <title>{getMetaTitle(title ?? site?.siteMetadata?.title)}</title>
      <PreloadFontLinks />
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content={url} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site?.siteMetadata?.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="og:image" content={ogImageUrl} />
      <meta property="twitter:image" content={ogImageUrl} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="canonical" href={url} />
      {meta.map((metaItem) => (
        <meta key={metaItem.name} {...metaItem} />
      ))}
    </>
  );
};
