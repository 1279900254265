import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import { Container, Heading, Link, Section, Slider } from 'voom-gatsby';

import { TrailingIcon } from '~components';

import { ReactComponent as ExternalLink } from '~svg/icons/external-link.svg';

interface RevconDownloadableResourcesCardProps {
  title: string;
  file: {
    asset: {
      url: string;
      originalFilename: string;
    };
  };
  image: {
    asset: {
      gatsbyImageData: ImageDataLike;
    };
  };
}

export const VOOM_ALL_FILE_QUERY = graphql`
  query VoomAllFile {
    allSanityVoomFile(sort: { title: ASC }) {
      nodes {
        title
        file {
          asset {
            url
            originalFilename
          }
        }
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
    }
  }
`;

export const RevconDownloadableResourcesCard = ({
  image,
  title,
  file,
}: RevconDownloadableResourcesCardProps) => {
  const imageData = getImage(image.asset.gatsbyImageData);
  const dividerPadding = 8;

  return (
    <Link
      // /Files makes these open in a new tab
      to={`/files/${file.asset.originalFilename}`}
      sx={{
        pr: dividerPadding,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: 'full',
        textDecoration: 'none',
        ':after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: (theme) =>
            `${(theme.space?.[dividerPadding] as number) / 2 - 1}px`,
          width: 2,
          height: 'full',
          bg: 'muted',
        },
      }}
    >
      <TrailingIcon
        Icon={ExternalLink}
        sx={{
          variant: 'text.body',
          mb: 3,
          maxWidth: '80%',
          span: {
            variant: 'text.body',
            fontSize: 'xl',
            fontWeight: 'semiBold',
            letterSpacing: 1,
            lineHeight: 'heading',
          },
          svg: {
            width: 16,
            height: 16,
            mb: 2,
            ml: 2,
          },
        }}
      >
        {title}
      </TrailingIcon>

      {imageData && (
        <GatsbyImage
          image={imageData}
          alt=""
          sx={{
            mt: 'auto',
            border: (theme) => `0.5px solid ${theme.colors?.grey}`,
          }}
        />
      )}
    </Link>
  );
};

export const RevconDownloadableResources = () => {
  const { allSanityVoomFile } = useStaticQuery(VOOM_ALL_FILE_QUERY);

  return (
    <Section theme="base" sx={{ py: [5, 8] }}>
      {/* have carousel go off page on mobile */}
      <Container pr={0}>
        <Heading variant="h5" sx={{ fontWeight: 'medium', mb: [5, 6] }}>
          Downloadable Resources
        </Heading>
        <Slider
          settings={{
            infinite: false,
          }}
        >
          {allSanityVoomFile.nodes.map(
            (card: RevconDownloadableResourcesCardProps) => {
              return (
                <RevconDownloadableResourcesCard key={card.title} {...card} />
              );
            },
          )}
        </Slider>
      </Container>
    </Section>
  );
};
