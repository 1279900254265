import { graphql } from 'gatsby';

import { SideBySide, SideBySideProps } from '~components';

export const bunionplastySideBySideBottomFields = graphql`
  fragment bunionplastySideBySideBottomFields on ContentJsonSections {
    bunionplastySideBySideBottom {
      title
      caption
      list
      image {
        src {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
        alt
      }
      circle {
        width
        top
        right
      }
    }
  }
`;

export const BunionplastySideBySideBottom = (props: SideBySideProps) => {
  return (
    <SideBySide
      {...props}
      sx={{ background: (theme) => `${theme.colors?.tealGradient}` }}
    />
  );
};
