import { Box, Link } from 'voom-gatsby';

import { ReactComponent as ExternalLinkIcon } from '~svg/icons/external-link.svg';

export const FooterLinksColumn = ({
  links,
}: {
  links: { url: string; label: string }[];
}): JSX.Element => {
  return (
    <Box sx={{ gridRow: 1 }}>
      {links.map(({ url, label }) => {
        const isExternal = url.startsWith('http');
        return (
          <Link
            key={label}
            href={url}
            variant="inline"
            sx={{
              fontSize: 'md',
              mb: 3,
              fontWeight: isExternal ? 'regular' : 'semiBold',
              display: 'flex',
              whiteSpace: 'nowrap',
            }}
          >
            {label}
            {isExternal && (
              <ExternalLinkIcon sx={{ width: 16, height: 16, ml: 2 }} />
            )}
          </Link>
        );
      })}
    </Box>
  );
};
