import { graphql } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';

import {
  Container,
  Flex,
  Heading,
  Section,
  StyledSup,
  Text,
} from 'voom-gatsby';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';

interface BeforeAfterCardProps {
  label: string;
  image: {
    src: ImageDataLike;
    alt: string;
  };
}

interface BeforeAfterProps {
  title: string;
  caption: string;
  cards: BeforeAfterCardProps[];
}

export const beforeAfterFields = graphql`
  fragment beforeAfterFields on ContentJsonSections {
    beforeAfter {
      title
      caption
      cards {
        label
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            }
          }
          alt
        }
      }
    }
  }
`;

export const BeforeAfter = ({ title, caption, cards }: BeforeAfterProps) => {
  return (
    <Section theme="base" sx={{ position: 'relative', mt: 7 }}>
      <Container>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 1000,
            mx: 'auto',
          }}
        >
          <CrossIcon sx={{ width: 32, height: 32, mb: 3 }} />
          <Heading
            variant="h4"
            sx={{
              fontSize: ['2xl', null, '4xl'],
              textAlign: 'center',
              mb: [5, null, 6],
            }}
          >
            <StyledSup>{title}</StyledSup>
          </Heading>
          <Text
            as="p"
            variant="mediumP"
            sx={{
              fontSize: ['sm', 'md'],
              textAlign: 'center',
              mb: [6, null, 7],
              maxWidth: 700,
              mx: 'auto',
            }}
          >
            <StyledSup>{caption}</StyledSup>
          </Text>
        </Flex>
        <Flex
          sx={{
            flexDirection: ['column', null, 'row'],
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {cards.map((card) => {
            const imageData = getImage(card.image.src);

            const lineStyles = {
              content: "''",
              height: 3,
              width: 'full',
              borderRadius: 'rounded',
              bg: 'secondary',
              position: 'absolute',
              bottom: '110%',
            } as const;

            return (
              <Flex
                key={card.label}
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  '&:first-of-type': {
                    transform: [
                      'none',
                      null,
                      'translateX(60px)',
                      'translateX(80px)',
                    ],
                  },
                  '&:last-of-type': {
                    flexDirection: ['column-reverse', null, 'column'],
                    mt: ['-33vw', null, 0],

                    transform: [
                      'none',
                      null,
                      'translateX(-60px)',
                      'translateX(-80px)',
                    ],
                    p: {
                      transform: ['translateY(-45px)', null, 'none'],
                      '&:before': {
                        ...lineStyles,
                        bottom: ['unset', null, '110%'],
                        top: ['110%', null, 'unset'],
                      },
                    },
                  },
                }}
              >
                <Text
                  as="p"
                  variant="largeP"
                  sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'medium',
                    position: 'relative',
                    letterSpacing: 8,
                    mb: 0,
                    '&:before': {
                      ...lineStyles,
                    },
                  }}
                >
                  {card.label}
                </Text>
                {imageData && (
                  <GatsbyImage
                    image={imageData}
                    alt={card.image.alt}
                    sx={{ mb: [3, 4] }}
                  />
                )}
              </Flex>
            );
          })}
        </Flex>
      </Container>
    </Section>
  );
};
