import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Settings } from 'react-slick';

import {
  Box,
  Container,
  Flex,
  Paragraph,
  Section,
  Slider,
  SliderNextArrow,
  SliderPrevArrow,
  Spacer,
  StyledSup,
  useThemeUI,
} from 'voom-gatsby';

export interface TrainCarouselProps {
  cards: {
    eyebrow: string;
    quote: string;
    author: {
      name: string;
      title: string;
      location: string;
      disclaimer: string | null;
    };

    image: {
      src: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
      alt: string;
    };
  }[];
}

export const TrainCarousel = ({ cards }: TrainCarouselProps): JSX.Element => {
  const { theme } = useThemeUI();
  const settings: Settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    useTransform: false,
    nextArrow: (
      <SliderNextArrow sx={{ left: 'unset', right: [25, null, null, 85] }} />
    ),
    prevArrow: (
      <SliderPrevArrow sx={{ left: 'unset', right: [70, null, null, 130] }} />
    ),
    responsive: [],
  };
  return (
    <Section
      theme="base"
      sx={{
        py: [5, 8],
        bg: 'transparent',
      }}
    >
      <Container
        variant="wideContainer"
        sx={{
          overflow: 'hidden',
          px: [4, null, null, 8],
          mr: 0,
        }}
      >
        <Slider
          settings={settings}
          appendDots={true}
          mobileGradient={false}
          sx={{
            mb: 7,
            '.slick-list': {
              // this allows drop shadow to be visible
              overflow: 'visible',
              transform: [
                'none',
                null,
                null,
                `translateX(${theme.space?.[8]}px)`,
              ],
            },
            '.slick-slide': {
              transform: [
                'none',
                null,
                null,
                `translateX(-${theme.space?.[8]}px)`,
              ],
            },
          }}
        >
          {cards.map((card) => {
            return (
              <Box
                key={card.quote}
                sx={{
                  pr: [4, null, null, 8],
                }}
              >
                <Flex
                  sx={{
                    position: 'relative',
                    height: [null, null, null, 700],
                    flexDirection: ['column', null, null, 'row'],
                    '.slick-active &': {
                      transition: 'box-shadow 0.8s ease-in-out',
                      boxShadow: 'dropShadow',
                    },
                    ':after': {
                      content: '""',
                      position: 'absolute',
                      inset: 0,
                      background: (theme) => [
                        theme.colors?.trainCarouselGradientMobile,
                        null,
                        null,
                        theme.colors?.trainCarouselGradient,
                      ],
                      zIndex: -1,
                    },
                  }}
                >
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      p: [4, null, 7],
                      maxWidth: 600,
                      color: 'white',
                      height: 'full',
                      alignSelf: [null, null, null, 'flex-end'],
                    }}
                  >
                    <Paragraph
                      sx={{
                        p: 2,
                        px: '12px',
                        bg: 'purple',
                        textTransform: 'uppercase',
                        color: 'white',
                        display: 'inline-block',
                        fontWeight: 'semibold',
                        fontSize: 'xs',
                        mt: 'auto',
                      }}
                    >
                      {card.eyebrow}
                    </Paragraph>
                    <Spacer />
                    <Paragraph
                      as="q"
                      variant="h4"
                      sx={{ color: 'white', fontSize: ['xl', null, '2xl'] }}
                    >
                      <StyledSup>{card.quote}</StyledSup>
                    </Paragraph>
                    <Spacer />
                    <Paragraph
                      sx={{ fontWeight: 'semibold', fontSize: ['md', 'lg'] }}
                    >
                      {card.author.name}
                    </Paragraph>
                    <Paragraph sx={{ fontSize: ['xs', 'md'] }}>
                      {card.author.title}
                    </Paragraph>
                    <Paragraph sx={{ fontSize: ['xs', 'md'] }}>
                      {card.author.location}
                    </Paragraph>
                    {card.author.disclaimer && (
                      <Paragraph variant="copyright" sx={{ color: 'grey' }}>
                        {card.author.disclaimer}
                      </Paragraph>
                    )}
                  </Flex>
                  <GatsbyImage
                    image={card.image.src.childImageSharp.gatsbyImageData}
                    alt={card.image.alt}
                    sx={{
                      position: ['relative', null, null, 'absolute'],
                      mt: [-9, null, null, 0],
                      inset: [null, null, null, 0],
                      left: [null, null, null, 'unset'],
                      width: ['full', null, null, '62%'],
                      zIndex: -1,
                    }}
                  />
                </Flex>
              </Box>
            );
          })}
        </Slider>
      </Container>
    </Section>
  );
};

export const trainCarouselFields = graphql`
  fragment trainCarouselFields on ContentJsonSections {
    trainCarousel {
      cards {
        eyebrow
        quote
        author {
          name
          title
          location
          disclaimer
        }
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
          alt
        }
      }
    }
  }
`;
