import type { ReactNode } from 'react';

import { ThemeUIStyleObject } from '../components';

interface TextSpacerProps {
  children?: ReactNode;
  sx?: ThemeUIStyleObject;
  className?: string;
}

export const TextSpacer = ({
  children,
  sx,
  className,
}: TextSpacerProps): JSX.Element => {
  return (
    <span
      className={className}
      sx={{ ':not(:last-child)::after': { content: "'·'", mx: 4, ...sx } }}
    >
      {children}
    </span>
  );
};
