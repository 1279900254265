import { graphql, useStaticQuery } from 'gatsby';

import {
  Box,
  Container,
  Divider,
  SEO,
  ThemeUIProvider,
  getThemeByName,
} from 'voom-gatsby';

import { HeroSideBySide } from '~sections/HeroSideBySide';
import { HotSpots } from '~sections/HotSpots';
import { NewsPressList } from '~sections/NewsPressList';
import { PreFooterSideBySide } from '~sections/PreFooterSideBySide';
import { RevconDownloadableResources } from '~sections/RevconDownloadableResources';
import { RevconSpotlight } from '~sections/RevconSpotlight';
import { RevconSystemFeatures } from '~sections/RevconSystemFeatures';
import { RevconTable } from '~sections/RevconTable';
import { RevconTwoScrews } from '~sections/RevconTwoScrews';
import { TopFeature } from '~sections/TopFeature';

const RevconScrewPage = () => {
  const {
    contentJson: { sections },
  } = useStaticQuery(REVCON_SCREW_PAGE_QUERY);

  return (
    <Box>
      {sections.hero && <HeroSideBySide {...sections.hero} />}
      {sections.topFeature && <TopFeature {...sections.topFeature} />}
      {sections.hotSpots && <HotSpots {...sections.hotSpots} />}
      {sections.revconTwoScrews && (
        <RevconTwoScrews {...sections.revconTwoScrews} />
      )}
      {sections.revconSpotlight && (
        <RevconSpotlight {...sections.revconSpotlight} />
      )}
      {sections.revconSystemFeatures && (
        <RevconSystemFeatures {...sections.revconSystemFeatures} />
      )}
      {sections.revconTable && <RevconTable {...sections.revconTable} />}
      <Container sx={{ px: [0, 4] }}>
        <Divider sx={{ borderBottom: '3px solid' }} />
      </Container>
      <RevconDownloadableResources />
      <Container sx={{ px: [0, 4] }}>
        <Divider sx={{ borderBottom: '3px solid' }} />
      </Container>

      <NewsPressList featured={true} />
      {/* force white background behind PreFooterSideBySide */}
      <ThemeUIProvider theme={getThemeByName('base')}>
        {sections.preFooterSideBySide && (
          <PreFooterSideBySide {...sections.preFooterSideBySide} />
        )}
      </ThemeUIProvider>
    </Box>
  );
};

export const Head = () => {
  return (
    <SEO
      title="Revcon™ Screw System | Voom™ Medical Devices | Minimally Invasive Bunion Single Screw™"
      description="The Revcon™ Screw System features the next-generation patented dual-zone Revcon™ Anchor Single Screw™, which is used in our revolutionary No-Fusion Bunion Solution™."
    />
  );
};

export const REVCON_SCREW_PAGE_QUERY = graphql`
  query RevconScrewPage {
    contentJson(jsonId: { eq: "revcon-screw" }) {
      sections {
        ...heroFields
        ...topFeatureFields
        ...revconTwoScrewsFields
        ...hotSpotsFields
        ...revconSpotlightFields
        ...revconTableFields
        ...revconSystemFeaturesFields
        ...preFooterSideBySideFields
      }
    }
  }
`;

export default RevconScrewPage;
