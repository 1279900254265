import { keyframes } from '@emotion/react';

export const underlineStyling = {
  content: '""',
  position: 'absolute',
  height: 2,
  left: 0,
  top: 'calc(100% + 2px)',
  right: 0,
  display: 'block',
  borderRadius: 'radius',
};

export default {
  fontFamily: 'link',
  color: 'text',
  fontSize: 'sm',
  cursor: 'pointer',
};

export const inline = {
  variant: 'links.default',
  fontSize: 'sm',
  display: 'block',
  color: 'text',
  textDecoration: 'none',
  py: 1,
  my: 1,
  '@media (hover: hover)': {
    ':hover': {
      textDecoration: 'underline',
    },
  },
};

export const ghost = {
  variant: 'buttons.ghost',
  textDecoration: 'none',
};

export const button = {
  variant: 'buttons.primary',
  textDecoration: 'none',
  fontFamily: 'link',
};

export const buttonOutline = {
  variant: 'buttons.outline',
  textDecoration: 'none',
  fontFamily: 'link',
};

/** Not used on bunionplasty */
export const buttonInverted = {
  variant: 'buttons.inverted',
  textDecoration: 'none',
  fontFamily: 'link',
};

export const underline = {
  variant: 'links.default',
  color: 'link',
  width: 'fit-content',
  textDecoration: 'none',
  fontWeight: 'semiBold',
  fontSize: 'sm',
  position: 'relative',
  pb: 1,
  '::after': {
    ...underlineStyling,
    backgroundColor: 'currentColor',
  },
  '@media (hover: hover)': {
    '&:hover::after': {
      // Underline hover animation
      animation: `${keyframes`
      0% {
        transform-origin: right;
        transform: scaleX(1);
      }
      40% {
        transform-origin: right;
        transform: scaleX(0);
      }
      60% {
        transform-origin: left;
        transform: scaleX(0);
      }

      100% {
        transform-origin: left;
        transform: scaleX(1);
      }
    `} 0.6s ease-in-out`,
    },
  },
};

// styling token pulled from sanity
export const link = {
  variant: 'links.underline',
};

// styling token pulled from sanity
export const bold = {
  variant: 'links.underline',
  fontWeight: 'semibold',
};

// Underline only on hover
export const nav = {
  variant: 'links.default',
  color: 'black',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  height: [null, null, 'full'],
  display: ['block', null, 'inline-flex'],
  alignItems: 'center',
  '> span': {
    fontSize: 'sm',
    letterSpacing: 'normal',
    lineHeight: 'none',
    fontWeight: 'semiBold',
    position: 'relative',
    pb: 1,
  },
  '@media (hover: hover)': {
    '&:hover > span::after, &.active  > span::after': {
      ...underlineStyling,
      backgroundColor: 'pink',
      // Underline hover animation
      animation: `${keyframes`
      0% {
        transform-origin: left;
        transform: scaleX(0);
      }

      100% {
        transform-origin: left;
        transform: scaleX(1);
      }
    `} 0.3s ease-in-out`,
    },
  },
  '&.active > span::after': {
    ...underlineStyling,
    backgroundColor: 'pink',
  },
};

export const footer = {
  variant: 'links.nav',
  span: {
    fontWeight: 'regular',
  },
};

export const legal = {
  variant: 'links.default',
  textDecoration: 'none',
  fontSize: 'xs',
  color: 'grey400',
  '@media (hover: hover)': {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

export const footerBold = {
  variant: 'links.nav',
};

export const icon = {
  variant: 'buttons.icon',
};

export const iconGhost = {
  variant: 'buttons.iconGhost',
};

export const iconNav = {
  variant: 'buttons.iconGhost',
};

export const iconMenu = {
  variant: 'buttons.iconMenu',
};

export const iconNavWithIcon = {
  variant: 'buttons.primary',
  p: 2,
  ml: 2,
  textDecoration: 'none',
  height: 40,
  span: {
    color: 'white',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: 'copyright',
  },
  svg: {
    width: 16,
    height: 16,
  },
  '@media (hover: hover)': {
    ':hover': {
      bg: 'buttonHoverBG',
      boxShadow: 'buttonShadow',
    },
  },
};

export const breadCrumb = {
  variant: 'links.inline',
  color: 'pink',
  textDecoration: 'underline',
  fontSize: 'copyright',
  fontWeight: 'semibold',
  textTransform: 'capitalize',
};
