import { graphql } from 'gatsby';

import {
  Container,
  Flex,
  Heading,
  Section,
  Slider,
  StyledSup,
  Text,
} from 'voom-gatsby';

import {
  CircleImageCarouselCard,
  CircleImageCarouselCardProps,
} from '~components';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';

interface BunionplastyTrioProps {
  title: string;
  caption: string;
  cards: CircleImageCarouselCardProps[];
}

export const BunionplastyTrioFields = graphql`
  fragment bunionplastyTrioFields on ContentJsonSections {
    bunionplastyTrio {
      title
      caption
      cards {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: FIXED, placeholder: NONE, width: 175)
            }
          }
          alt
        }
        title
        caption
      }
    }
  }
`;

export const BunionplastyTrio = ({
  title,
  caption,
  cards,
}: BunionplastyTrioProps) => {
  return (
    <Section
      theme="base"
      sx={{
        pt: 8,
        pb: 2,
        background: (theme) => `${theme.colors?.tealGradient}`,
      }}
    >
      <Container pr={0}>
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: 600,
            mx: 'auto',
            mb: 7,
            px: [3, 4],
          }}
        >
          <CrossIcon sx={{ width: 32, height: 32, mb: 3 }} />
          <Heading
            variant="h2"
            sx={{
              textAlign: 'center',
              my: 3,
            }}
          >
            {title}
          </Heading>
          <Text variant="largeP" sx={{ my: 3, textAlign: 'center' }}>
            <StyledSup>{caption}</StyledSup>
          </Text>
        </Flex>
        <Slider
          settings={{ slidesToShow: 3 }}
          sx={{ maxWidth: 1000, mx: 'auto' }}
        >
          {cards.map((card) => {
            return <CircleImageCarouselCard key={card.title} {...card} />;
          })}
        </Slider>
      </Container>
    </Section>
  );
};
