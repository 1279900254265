import { graphql } from 'gatsby';

import {
  Container,
  Flex,
  Grid,
  Heading,
  Paragraph,
  Section,
  Spacer,
  Text,
} from 'voom-gatsby';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';

export interface TrainThreeUpProps {
  title: string;
  cards: {
    title: string;
    caption: string;
  }[];
}

export const trainThreeUpFields = graphql`
  fragment trainThreeUpFields on ContentJsonSections {
    trainThreeUp {
      title
      cards {
        title
        caption
      }
    }
  }
`;

export const TrainThreeUp = ({ title, cards }: TrainThreeUpProps) => {
  return (
    <Section
      theme="base"
      sx={{
        py: [6, 8],
        bg: 'transparent',
      }}
    >
      <Container variant="narrowContainer" sx={{ maxWidth: 1100 }}>
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            mx: 'auto',
          }}
        >
          <CrossIcon sx={{ width: 32, height: 32, mb: 3 }} />
          <Heading
            variant="h2"
            sx={{
              textAlign: 'center',
            }}
          >
            {title}
          </Heading>
        </Flex>
        <Spacer space={7} />
        <Grid columns={[1, null, 3]} gap={[4, null, 40]}>
          {cards.map((card) => {
            return (
              <Paragraph
                key={card.title}
                sx={{
                  borderBottom: (theme) => [
                    `1px solid ${theme.colors?.lightGrey}`,
                    null,
                    'none',
                  ],
                  pb: [4, null, 0],
                  borderRight: (theme) => [
                    null,
                    null,
                    `1px solid ${theme.colors?.lightGrey}`,
                  ],
                  pr: [null, null, 40],
                }}
              >
                <Text sx={{ fontWeight: 'semibold' }}>{card.title}</Text>{' '}
                <Text>{card.caption}</Text>
              </Paragraph>
            );
          })}
        </Grid>
      </Container>
    </Section>
  );
};
