import {
  Box,
  Flex,
  Grid,
  SectionProps,
  Text,
  ThemeUIStyleObject,
} from 'voom-gatsby';

export interface TopFeaturesProps {
  title: string;
  caption: string[];
  sx?: ThemeUIStyleObject;
  className?: string;
  showBoxShadow?: boolean;
  theme?: SectionProps['theme'];
}

const GridBackground = () => (
  <Grid
    columns={3}
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: 'full',
      height: 'full',
      gridGap: 0,
    }}
  >
    {[1, 2, 3, 4, 5, 6].map((i) => (
      <Box
        key={i}
        sx={{
          width: 'full',
          height: [50, null, 'full'],
          bg: [1, 2, 4].includes(i) ? 'primary' : 'secondary',
          opacity: 0.25,
        }}
      />
    ))}
  </Grid>
);
export const TopFeatureRow = ({
  title,
  caption,
  sx,
  className,
  showBoxShadow = true,
  theme,
}: TopFeaturesProps) => {
  const isBlueTheme = theme === 'blue';

  const gridLength = caption.length + 1; // + 1 for title
  return (
    <Grid
      className={className}
      sx={{
        bg: 'blue3',
        gridTemplateColumns: ['1fr', null, null, `repeat(${gridLength}, 1fr)`],
        gridTemplateRows: [
          `60px repeat(${gridLength - 1}, 55px)`,
          null,
          `repeat(${gridLength}, 60px)`,
          140,
        ],
        gridGap: 0,
        boxShadow: showBoxShadow ? `featureRowShadow` : 'none',
        ...sx,
      }}
    >
      <Flex
        sx={{
          justifyItems: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <GridBackground />
        <Text
          variant="largeP"
          sx={{
            width: ['full', null, null, '50%'],
            fontSize: ['xs', null, 'md'],
            mx: 'auto',
            fontWeight: 'semiBold',
            zIndex: 'sticky',
            textAlign: ['center', null, null, 'left'],
            letterSpacing: 8,
            my: 0,
          }}
        >
          {title}
        </Text>
      </Flex>
      {caption.map((text) => {
        return (
          <Flex
            key={text}
            sx={{
              justifyItems: 'center',
              alignItems: 'center',
              bg: isBlueTheme ? 'white' : 'blue3',
              position: 'relative',
              ':not(:last-child)::after': {
                content: "''",
                height: [1, null, null, '60%'],
                width: [75, null, null, 1],
                bg: 'muted',
                zIndex: 'sticky',
                opacity: 0.3,
                position: 'absolute',
                right: ['50%', null, null, 0],
                top: ['100%', null, null, '50%'],
                transform: ['translateX(50%)', null, null, 'translateY(-50%)'],
              },
            }}
          >
            <Text
              variant="largeP"
              sx={{
                maxWidth: ['full', null, null, 160],
                mx: 'auto',
                textAlign: 'center',
                fontWeight: 'semiBold',
                fontSize: ['xs', null, 'md'],
                color: isBlueTheme ? 'black' : 'white',
                my: 0,
              }}
            >
              {text}
            </Text>
          </Flex>
        );
      })}
    </Grid>
  );
};
