import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import { forwardRef } from 'react';
import type { ComponentProps } from 'react';

import { IntrinsicBox, IntrinsicBoxProps } from '../components';

export type IntrinsicImageProps = IntrinsicBoxProps & GatsbyImageProps;
const extractGatsbyImageProps = (
  props: IntrinsicImageProps,
): [GatsbyImageProps, IntrinsicBoxProps] => {
  const {
    image,
    title,
    alt,
    crossOrigin,
    style,
    imgStyle,
    backgroundColor,
    onLoad,
    onError,
    onStartLoad,
    itemProp,
    loading,
    draggable,
    ...boxProps
  } = props;
  return [
    {
      image,
      title,
      alt,
      crossOrigin,
      style,
      imgStyle,
      backgroundColor,
      onLoad,
      onError,
      onStartLoad,
      itemProp,
      loading,
      draggable,
    },
    boxProps,
  ];
};

/**
 * Use the `IntrinsicImage` component like `GatsbyImage`, but
 * with a `ratio` prop like ThemeUI `AspectRatio`.
 *
 * @example
 *   <IntrinsicImage
 *     ratio={278 / 178}
 *     image={data.image}
 *   />
 */
export const IntrinsicImage = forwardRef(function IntrinsicImage(
  props: IntrinsicImageProps,
  ref: ComponentProps<typeof IntrinsicBox>['ref'],
) {
  const [imageProps, { ...boxProps }] = extractGatsbyImageProps(props);
  return (
    <IntrinsicBox
      className={props.className}
      {...boxProps}
      sx={{ position: 'relative' }}
      ref={ref}
    >
      <GatsbyImage
        {...imageProps}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      />
    </IntrinsicBox>
  );
});
