import { graphql } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';
import { useState } from 'react';

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Paragraph,
  Section,
  Spacer,
  Text,
} from 'voom-gatsby';

import { ReactComponent as CrossIcon } from '~svg/icons/cross.svg';

export interface RevconSpotlightProps {
  title: string;
  caption: string;
  spotlights: {
    image: {
      src: ImageDataLike;
      alt: string;
    };
    title: string;
    caption: string;
  }[];
}

export const revconSpotlightFields = graphql`
  fragment revconSpotlightFields on ContentJsonSections {
    revconSpotlight {
      title
      caption
      spotlights {
        image {
          src {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            }
          }
          alt
        }
        title
        caption
      }
    }
  }
`;
export const RevconSpotlight = ({
  title,
  caption,
  spotlights,
}: RevconSpotlightProps) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const imageData = getImage(spotlights[selectedItem].image.src);

  return (
    <Section theme="base">
      <Container>
        <Container
          sx={{
            textAlign: 'center',
            maxWidth: 910,
            px: [0, 0],
            mb: [5, 6, 8, 165],
          }}
        >
          <CrossIcon sx={{ width: 32, height: 32, mb: 3 }} />
          <Heading variant="h2">{title}</Heading>
          <Spacer />
          <Paragraph>{caption}</Paragraph>
        </Container>
        <Grid
          sx={{
            gridTemplateColumns: ['1fr', null, null, '6fr 5fr'],
            gridGap: 6,
          }}
        >
          {imageData && (
            <GatsbyImage
              sx={{
                display: ['none', null, null, 'inline-block'].map((value) =>
                  value ? `${value} !important` : null,
                ),
                boxShadow: 'dropShadow',
              }}
              image={imageData}
              alt={spotlights[selectedItem].image.alt}
            />
          )}

          <Flex
            as="ul"
            sx={{ flexDirection: 'column', justifyContent: 'space-between' }}
          >
            {spotlights.map(({ title, caption, image }, index) => {
              const spotlightImage = getImage(image.src);
              return (
                <Box
                  as="li"
                  key={title}
                  sx={{
                    maxWidth: [400, null, 500, 'none'],
                    mx: ['auto', null, null, 0],
                  }}
                >
                  <Button
                    variant="default"
                    onClick={() => setSelectedItem(index)}
                    onMouseEnter={() => setSelectedItem(index)}
                    sx={{
                      bg: 'transparent',
                      borderRadius: 'square',
                      textAlign: 'left',
                      width: 'full',
                      py: [4, null, null, 5],
                      px: 0,
                      position: 'relative',
                      cursor: ['default', null, null, 'pointer'],
                      '::before': {
                        content: '""',
                        display: ['none', null, null, 'block'],
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: 'full',
                        height: selectedItem === index ? 4 : 1,
                        bg: selectedItem === index ? 'primary' : 'grey',
                      },
                    }}
                  >
                    {spotlightImage && (
                      <GatsbyImage
                        sx={{
                          display: ['inline-block', null, null, 'none'].map(
                            (value) => (value ? `${value} !important` : null),
                          ),
                          mb: 4,
                        }}
                        image={spotlightImage}
                        alt={image.alt}
                      />
                    )}

                    <Flex>
                      <Box sx={{ flex: '0 0 70px' }}>
                        <Box
                          sx={{
                            display: 'inline-block',
                            width: 42,
                            height: 42,
                            position: 'relative',
                            border: (theme) => [
                              '`2px solid ${theme.colors?.primary}`',
                              null,
                              null,
                              selectedItem === index
                                ? `2px solid ${theme.colors?.primary}`
                                : `2px solid ${theme.colors?.grey}`,
                            ],
                            borderRadius: 'circle',
                            bg: [
                              'primary',
                              null,
                              null,
                              selectedItem === index
                                ? 'primary'
                                : 'transparent',
                            ],
                          }}
                        >
                          <Text
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              fontSize: 'md',
                              fontWeight: 'semiBold',
                              color: [
                                'white',
                                null,
                                null,
                                selectedItem === index ? 'white' : 'grey',
                              ],
                            }}
                          >
                            {index + 1}
                          </Text>
                        </Box>
                      </Box>
                      <Box>
                        <Heading variant="h5" sx={{ mb: 2 }}>
                          {title}
                        </Heading>
                        <Text as="p" variant="largeP" sx={{ maxWidth: 450 }}>
                          {caption}
                        </Text>
                      </Box>
                    </Flex>
                  </Button>
                </Box>
              );
            })}
          </Flex>
        </Grid>
      </Container>
    </Section>
  );
};
